import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessEnforcedRedirects = (store, enforcedRedirectData) => {
  let reduced = reduce(enforcedRedirectData, (sum, countries) => {
    sum.enforcedRedirects.push(countries)
    return sum
  }, { enforcedRedirects: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_ENFORCED_REDIRECTS, reduced.enforcedRedirects)
}

// ============================== ADMIN  ACTIONS========================================
export const destroyEnforcedRedirect = (store, { id }) => {
  return axios.delete(`/api/admin/enforced-redirects/${id}`).then(res => {

    store.commit(mutations.DELETE_ENFORCED_REDIRECT, { id })
    return res
  })
}

export const fetchEnforcedRedirects = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/enforced-redirects?${query}`).then(res => {
    batchProcessEnforcedRedirects(store, res.data.data)
    return res
  })
}

export const fetchEnforcedRedirectById = (store, id) => {
  return axios.get(`/api/admin/enforced-redirects/${id}`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_INTEGRATIONS, [res.data])
    return res
  })
}

export const updateEnforcedRedirectById = (store, { id, data }) => {
  return axios.put(`/api/admin/enforced-redirects/${id}`, data).then(res => {
    batchProcessEnforcedRedirects(store, [res.data])
    return res
  })
}

export const createEnforcedRedirect = (store, data) => {
  return axios.post(`/api/admin/enforced-redirects`, data).then(res => {
    batchProcessEnforcedRedirects(store, [res.data])
    return res
  })
}
