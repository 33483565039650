import axios from "axios";

// Execute product profile prompt
export const executeProductProfilePrompt = (store, { product_id, data }) => {
  return axios.post(`/api/products/${product_id}/profile-ai/generate`, data);
};

export const saveGeneratedProductProfilePrompt = (store, { product_id, data }) => {
  return axios.post(`/api/products/${product_id}/profile-ai/save`, data);
};

export const executeHotelValidatorPrompt = (store, { hotel_id, data }) => {
  return axios.post(`/api/hotels/${hotel_id}/open-ai-sandbox`, data);
};
export const fetchBigramGroups = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/bigram-groups?${query}`)
}

export const createBigramGroup = (store, request) => {
  return axios.post(`/api/admin/bigram-groups`, request).then(response => {
    return response.data
  })
}

export const updateBigramGroup = (store, { id, request }) => {
  return axios.put(`/api/admin/bigram-groups/${id}`, request).then(response => {
    return response.data
  })
}

// Execute product profile prompt
export const regenerateProductDescription = (store, { product_id, data }) => {
  return axios.post(`/api/products/${product_id}/profile-ai/re-generate`, data);
};
