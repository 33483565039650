import axios from 'axios'
import { map, reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessHotelPropertyAmenities = (store, hotelPropertyAmenities) => {
  let reduced = reduce(hotelPropertyAmenities, (sum, hotelPropertyAmenity) => {
    sum.hotelPropertyAmenities.push(hotelPropertyAmenity)
    return sum
  }, { hotelPropertyAmenities: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_HOTEL_PROPERTY_AMENITIES, reduced.hotelPropertyAmenities)
}

export const fetchHotelPropertyAmenities = (store, options) => {
  return axios.get(`/api/admin/hotel-property-amenities`, { params: options }).then(res => {
    batchProcessHotelPropertyAmenities(store, res.data.data)
    return res
  })
}

export const createHotelPropertyAmenities = (store, data) => {
  return axios.post(`/api/admin/hotel-property-amenities`, data).then(res => {
    batchProcessHotelPropertyAmenities(store, [res.data])
    return res
  })
}

export const fetchVisibleAmenities = (store, options) => {
  axios.get(`/api/admin/hotels/property-amenities/visible`).then(({ data }) => {
    store.commit(mutations.SET_VISIBLE_AMENITIES, data)
  })
}