import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

let state = {
  items: [],
  tabs: {
    'from': 0,
    'last_page': 0,
    'to': 0,
    'total': 0
  },
  ppcCategoryBranding:[],
  ppcLedgerAccount:{},
}

export default { state, actions, getters, mutations }
