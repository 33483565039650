export const BATCH_ADD_OR_UPDATE_USERS = 'users.batchAddOrUpdate'
export const SET_USERS = 'users.set'
export const DELETE_USER = 'users.delete'

export const BATCH_ADD_OR_UPDATE_OAUTH_CLIENTS = 'oauthClients.batchAddOrUpdate'
export const DELETE_OAUTH_CLIENT = 'oauthClients.delete'

export const BATCH_ADD_OR_UPDATE_OAUTH_ACCESS_GRANTS = 'oauthAccessGrants.batchAddOrUpdate'
export const DELETE_OAUTH_ACCESS_GRANT = 'oauthAccessGrants.delete'

export const BATCH_ADD_OR_UPDATE_OAUTH_AUTH_GRANTS = 'oauthAuthGrants.batchAddOrUpdate'
export const DELETE_OAUTH_AUTH_GRANT = 'oauthAuthGrants.delete'

export const BATCH_ADD_OR_UPDATE_OAUTH_REFRESH_GRANTS = 'oauthRefreshGrants.batchAddOrUpdate'
export const DELETE_OAUTH_REFRESH_GRANT = 'oauthRefreshGrants.delete'

export const BATCH_ADD_OR_UPDATE_ENFORCED_REDIRECTS = 'enforcedRedirects.batchAddOrUpdate'
export const DELETE_ENFORCED_REDIRECT = 'enforcedRedirects.delete'

export const BATCH_ADD_OR_UPDATE_POST_COMPONENT_AUTHORS = 'postComponentAuthors.batchAddOrUpdate'
export const DELETE_POST_COMPONENT_AUTHOR = 'postComponentAuthors.delete'

export const BATCH_ADD_OR_UPDATE_POST_COMPONENT_COLLECTIBLE_ITEMS = 'postComponentCollectibleItems.batchAddOrUpdate'
export const DELETE_POST_COMPONENT_COLLECTIBLE_ITEM = 'postComponentCollectibleItems.delete'

export const BATCH_ADD_OR_UPDATE_POST_COMPONENT_COLLECTIONS = 'postComponentCollections.batchAddOrUpdate'
export const DELETE_POST_COMPONENT_COLLECTION = 'postComponentCollections.delete'

export const BATCH_ADD_OR_UPDATE_POST_COMPONENT_QUICKTAKES = 'postComponentQuicktakes.batchAddOrUpdate'
export const DELETE_POST_COMPONENT_QUICKTAKE = 'postComponentQuicktakes.delete'

export const BATCH_ADD_OR_UPDATE_POST_COMPONENT_TEXTS = 'postComponentTexts.batchAddOrUpdate'
export const DELETE_POST_COMPONENT_TEXT = 'postComponentTexts.delete'

export const BATCH_ADD_OR_UPDATE_POST_COMPONENT_SECTIONS = 'postComponentSections.batchAddOrUpdate'
export const DELETE_POST_COMPONENT_SECTION = 'postComponentSections.delete'

export const BATCH_ADD_OR_UPDATE_POST_COMPONENT_PPC_TOP_LISTS = 'postComponentPpcTopLists.batchAddOrUpdate'
export const DELETE_POST_COMPONENT_PPC_TOP_LIST = 'postComponentPpcTopLists.delete'

export const BATCH_ADD_OR_UPDATE_POSTS = 'posts.batchAddOrUpdate'
export const DELETE_POST = 'posts.delete'

export const BATCH_ADD_OR_UPDATE_POST_CLAIMS = 'postClaims.batchAddOrUpdate'
export const ADD_OR_UPDATE_POST_CLAIM = 'postClaims.AddOrUpdate'

export const BATCH_ADD_OR_UPDATE_COMPANIES = 'companies.batchAddOrUpdate'
export const DELETE_COMPANY = 'companies.delete'
export const SET_PATH_COMPANY = 'companies.setPath'
export const DELETE_USER_FROM_COMPANY = 'companies.deleteUserFromCompany'
export const BATCH_ADD_OR_UPDATE_CARDS = 'companies.cards.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_REFERRALS = 'referrals.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_JOBS = 'jobs.batchAddOrUpdate'
export const DELETE_JOBS = 'jobs.delete'

export const BATCH_ADD_OR_UPDATE_COMPANY_CLAIMS = 'companyClaims.batchAddOrUpdate'
export const ADD_OR_UPDATE_COMPANY_CLAIM = 'companyClaims.AddOrUpdate'

export const BATCH_ADD_OR_UPDATE_PRODUCT = 'products.batchAddOrUpdate'
export const DELETE_PRODUCT = 'products.delete'
export const SET_IS_FETCHING_PRODUCT_DETAILS_DATA = 'products.isFetchinProductDetailsData'

export const BATCH_ADD_OR_UPDATE_CATEGORIES = 'categories.batchAddOrUpdate'
export const DELETE_CATEGORY = 'categories.delete'

export const BATCH_ADD_OR_UPDATE_DEPARTMENTS = 'departments.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_EMAIL_TEMPLATES = 'emailTemplates.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_REVIEWS = 'reviews.batchAddOrUpdate'
export const DELETE_REVIEW = 'reviews.delete'
export const DELETE_REVIEWS_BY_PRODUCT_ID = 'reviews.deleteByProductId'
export const BATCH_ADD_OR_UPDATE_MIN_REVIEW_COUNT_BEFORE_PURCHASES = 'reviews.batchAddOrUpdateMinReviewCountBeforePurchases'
export const BATCH_ADD_OR_UPDATE_INTEGRATIONS = 'integrations.batchAddOrUpdate'
export const ADD_SINGLE_INTEGRATION = 'integrations.add'
export const SET_INTEGRATIONS = 'integrations.set'
export const UPDATE_SINGLE_INTEGRATIONS = 'integrations.update'
export const DELETE_INTEGRATION = 'integrations.delete'
export const UPDATE_TABS_REVIEWS_INFO = 'reviews.updateTabsReviewsInfo'

export const BATCH_ADD_OR_UPDATE_FEATURES = 'features.batchAddOrUpdate'
export const DELETE_FEATURE = 'features.delete'
export const GET_FEATURES_GROUP_SEARCH = 'features.featuresGroupSearch'

export const BATCH_ADD_OR_UPDATE_FEATURES_GROUP = 'featuresGroup.batchAddOrUpdate'
export const DELETE_FEATURE_GROUP = 'featuresGroup.delete'
export const GET_FEATURE_GROUP_FEATURES = 'featuresGroup.getFeatures'
export const GET_FEATURES_SEARCH = 'featuresGroup.featuresSearch'
export const SYNC_FEATURES_GROUP_FEATURES = 'featuresGroup.syncFeatures'
export const UPDATE_SORT_FEATURE_GROUPS = 'featuresGroup.updateSort'
export const MASS_DELETE_FEATURE_GROUP = 'featuresGroup.updateSort'

export const BATCH_ADD_OR_UPDATE_INTEGRATION_INVITATIONS = 'integrationInvitations.batchAddOrUpdate'
export const DELETE_INTEGRATION_INVITATION = 'integrationInvitations.delete'

export const BATCH_ADD_OR_UPDATE_INVITATIONS = 'invitations.batchAddOrUpdate'
export const DELETE_INVITATION = 'invitations.delete'

export const BATCH_ADD_OR_UPDATE_REVIEW_REQUESTS = 'reviewRequests.batchAddOrUpdate'
export const DELETE_REVIEW_REQUEST = 'reviewRequests.delete'

export const BATCH_ADD_OR_UPDATE_REVIEW_RESPONSES = 'reviewResponses.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_TESTIMONIAL = 'testimonial.batchAddOrUpdate'
export const DELETE_TESTIMONIAL = 'testimonial.delete'

export const BATCH_ADD_OR_UPDATE_STUDY = 'study.batchAddOrUpdate'
export const DELETE_STUDY = 'study.delete'

export const BATCH_ADD_OR_UPDATE_TECH_AWARD = 'techAwards.batchAddOrUpdate'
export const DELETE_TECH_AWARD = 'techAwards.delete'

export const BATCH_ADD_OR_UPDATE_QUESTION = 'question.batchAddOrUpdate'
export const DELETE_QUESTION = 'question.delete'

export const BATCH_ADD_OR_UPDATE_SCREENSHOTS = 'screenshots.batchAddOrUpdate'
export const DELETE_SCREENSHOT = 'screenshots.delete'

export const BATCH_ADD_OR_UPDATE_FEATURED_BRANDS = 'featuredBrands.batchAddOrUpdate'
export const DELETE_FEATURED_BRANDS = 'featuredBrands.delete'

export const BATCH_ADD_OR_UPDATE_CUSTOMERS = 'customers.batchAddOrUpdate'

export const ADD_TOTALS_CUSTOMERS = 'customers.customersTotalTaps'


export const BATCH_ADD_OR_UPDATE_NPS_REQUESTS = 'npsRequests.batchAddOrUpdate'
export const DELETE_NPS_REQUEST = 'npsRequests.delete'

export const BATCH_ADD_OR_UPDATE_EVENTS = 'events.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_INTERNAL_NOTES = 'internalNotes.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_HOTELS = 'hotels.batchAddOrUpdate'
export const SET_API_STATUS_COUNTS = 'hotels.setApiStatusCounts'

export const DELETE_HOTEL = 'hotels.delete'

export const BATCH_ADD_OR_UPDATE_HOTEL_PROPERTY_AMENITIES = 'hotelPropertyAmenities.batchAddOrUpdate'

export const SET_VISIBLE_AMENITIES = 'hotelPropertyAmenities.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_HOTEL_ROOM_FEATURES = 'hotelRoomFeatures.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_HOTEL_ROOM_TYPES = 'hotelRoomTypes.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_HOTEL_STYLES = 'hotelStyles.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_HOTEL_TYPES = 'hotelTypes.batchAddOrUpdate'
export const DELETE_HOTEL_TYPE = 'hotelTypes.delete'

export const LOADING_CUSTOMERS = 'customers.isLoading'
export const INTERVAL_TABS_CUSTOMERS = 'customers.intervalTabsCustomers'

export const ADD_OR_UPDATE_CUSTOMER_NOTES = 'customers.addOrUpdateNotes'
export const ADDING_CUSTOMER_INTERNAL_NOTE = 'customers.addingInternalNote'

export const DELETE_CUSTOMERS = 'customers.bulkDeleteCustomers'

export const BATCH_ADD_OR_UPDATE_NPS_RESPONSES = 'npsResponses.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_LEADS = 'leads.batchAddOrUpdate'
export const ADD_OR_UPDATE_LEAD = 'leads.AddOrUpdate'
export const UPDATE_LEAD_ATTRIBUTES = 'lead.updateAttributes'
export const DELETE_LEAD = 'lead.delete'

export const BATCH_ADD_OR_UPDATE_NEWS = 'news.batchAddOrUpdate'

export const SET_FILTERED_ROOMS = 'chatRooms.batchAddOrUpdateFilteredRooms'
export const BATCH_ADD_OR_UPDATE_CHAT_ROOMS = 'chatRooms.batchAddOrUpdate'
export const BATCH_SET_ACTIVE_CHAT_ROOM = 'chatRooms.batchSetActiveChatRoom'
export const UPDATE_CHAT_ROOM_ATTRIBUTES = 'chatRooms.updateChatRoomAttributes'

export const BATCH_ADD_OR_UPDATE_CHAT_MESSAGE = 'chatMessages.batchAddOrUpdate'
export const DELETE_CHAT_MESSAGE = 'chatMessages.delete'
export const READ_CHAT_MESSAGES = 'chatMessages.read'

export const BATCH_ADD_OR_UPDATE_QUIZZES = 'quizzes.batchAddOrUpdate'
export const DELETE_QUIZ = 'quizzes.delete'

export const BATCH_ADD_OR_UPDATE_REPORTS = 'reports.batchAddOrUpdate'
export const DELETE_REPORT = 'reports.delete'

export const BATCH_ADD_OR_UPDATE_DOWNLOAD = 'downloads.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_BUYERS_GUIDES = 'buyersGuides.batchOrUpdate'

export const BATCH_ADD_OR_UPDATE_TRANSACTIONS = 'transactions.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_CAMPAIGN = 'campaign.batchAddOrUpdate'
export const DELETE_CAMPAIGN = 'campaign.delete'

export const BATCH_ADD_OR_UPDATE_RECOMMENDATIONS = 'recommendations.batchAddOrUpdate'
export const DELETE_RECOMMENDATION = 'recommendations.delete'

export const BATCH_ADD_OR_UPDATE_PLAN_BENEFITS = 'membershipPlanBenefits.batchAddOrUpdate'
export const BATCH_ADD_OR_UPDATE_PLAN_BENEFITS_ICONS = 'membershipPlanBenefitIcons.batchAddOrUpdate'
export const BATCH_ADD_OR_UPDATE_PLAN_CATEGORY = 'membershipPlanCategories.batchAddOrUpdate'
export const BATCH_ADD_OR_UPDATE_BUSINESS_RULES = 'membershipPlanRules.batchAddOrUpdate'
export const BATCH_ADD_OR_UPDATE_PLAN_PRICES = 'membershipPlanPrices.batchAddOrUpdate'
export const BATCH_ADD_OR_UPDATE_BUSINESS_PLANS = 'membershipPlans.batchAddOrUpdate'
export const BATCH_ADD_OR_UPDATE_PLAN_PATHS = 'membershipPlan.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_LEDGER_ACCOUNTS = 'ledger.batchAddOrUpdateAccounts'
export const BATCH_ADD_OR_UPDATE_LEDGER_PRODUCTS = 'ledger.batchAddOrUpdateProducts'

export const BATCH_ADD_OR_UPDATE_PRODUCT_MODULE = 'productModule.batchAddOrUpdate'
export const DELETE_PRODUCT_MODULE = 'productModule.delete'

export const BATCH_ADD_OR_UPDATE_COUNTRIES = 'countries.batchProcessCountries'

export const BATCH_ADD_OR_UPDATE_REGIONS = 'regions.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_PROPERTY_SIZES = 'propertySizes.batchAddOrUpdate'
export const DELETE_PROPERTY_SIZE = 'propertySizes.delete'

export const SET_LEADS_TIMELINE_RESUME = 'leads.set_timeline_resume'

export const BATCH_ADD_OR_UPDATE_AWARD = 'awards.batchAddOrUpdate'
export const DELETE_AWARDS = 'awards.delete'

export const BATCH_ADD_OR_UPDATE_LEADERBOARD = 'leaderboards.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_RESOURCES = 'resources.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_PRODUCT_BENEFIT = 'productBenefit.batchAddOrUpdate'
export const DELETE_PRODUCT_BENEFIT = 'productBenefit.delete'

export const ADD_SEARCH_PRODUCTS = 'search.searchProduct'
export const ADD_SEARCH_ARTICLES = 'search.searchArticle'
export const ADD_SEARCH_CATEGORIES = 'search.searchCategory'
export const ADD_SEARCH_RESOURCES = 'search.searchResource'

export const DELETE_CONTENT_DOWNLOAD = 'content-downloads.delete'
export const BATCH_ADD_OR_UPDATE_CONTENT_DOWNLOAD = 'content-downloads.batchAddOrUpdate'

export const BATCH_ADD_OR_UPDATE_MANAGE_REGISTRATION = 'search.batchAddOrUpdateManageRegistration'
export const DELETE_MANAGE_REGISTRATION = 'search.deleteManageRegistration'

export const FIT_SCORE_UPDATE_GOOGLE_PLACES_LOOKUP_LIST = 'fitScore.updateGooglePlacesLookup'
export const FIT_SCORE_UPDATE_HOTEL_SIZE_LIST = 'fitScore.updateHotelSize'
export const FIT_SCORE_UPDATE_PROPERTY_SIZE_LIST = 'fitScore.updatePropertySize'
export const FIT_SCORE_UPDATE_COUNTRY_LIST = 'fitScore.updateCountry'
export const FIT_SCORE_UPDATE_REGION_LIST = 'fitScore.updateRegion'
export const FIT_SCORE_UPDATE_PMS_OR_HMS_LIST = 'fitScore.updatePmsOrHms'
export const FIT_SCORE_UPDATE_CATEGORY_LIST = 'fitScore.updateCategory'
export const FIT_SCORE_UPDATE_RESULTS_LIST = 'fitScore.updateResults'

export const TRIPADVISOR_UPDATE_HOTELS_LIST = 'tripadvisor.updateHotels'
export const BOOKING_UPDATE_HOTELS_LIST = 'booking.updateHotels'
export const BATCH_ADD_OR_UPDATE_PPC_CONTAINERS = 'ppc.batchAddOrUpdateContainers'
export const BATCH_ADD_OR_UPDATE_PPC_COMPANY_CONTAINERS = 'ppc.batchProcessCompanyContainers'

export const BATCH_ADD_OR_UPDATE_LANGUAGES = 'languages.batchAddOrUpdate'

export const SET_PENDING_LEADS = 'leads.set_pending_leads'
export const SET_INSTANT_CONNECT_STATUS = 'leads.set_instant_connect_status'
export const SET_MANUAL_CRM_FORWARDING_STATUS = 'leads.set_manual_crm_forwarding_status'
export const SET_AUTOMATIC_CRM_FORWARDING_STATUS = 'leads.set_automatic_crm_forwarding_status'

export const BATCH_ADD_OR_UPDATE_REBVBOX_CAMPAIGN = 'revboxCampaigns.batchAddOrUpdate'
export const SET_PPC_LEDGER_ACCOUNT = 'ppc.setPPCLedgerAccount'
export const SET_PPC_CATEGORY_BRANDING = 'ppc.categoryBranding'

export const SET_CAMPAIGN_EMAIL_FOOTER = 'campaignEmailFooter.set'

export const BATCH_ADD_OR_UPDATE_USER_NYLAS_SETTINGS = 'nylasSettings.batchAddOrUpdate'
export const DELETE_USER_NYLAS_SETTINGS = 'nylasSettings.delete'

export const SET_PPC_ACTIVITY_LOG = 'ppc.setPPCActivityLog'
