import axios from 'axios'
import * as mutations from '../../mutation-types'

export const batchProcessBusinessRules = (store, rules) => {
  store.commit(mutations.BATCH_ADD_OR_UPDATE_BUSINESS_RULES, rules)
}

export const fetchBusinessRules = (store) => {
  return axios.get(`/api/membership-plan-business-rules`).then(res => {
    batchProcessBusinessRules(store, res.data)
    return res
  })
}

export const fetchDashboardBusinessRules = (store, company_id) => {
  return axios.get(`/api/dashboard/membership-plan-business-rules?company_id=${company_id}`).then(res => {
    batchProcessBusinessRules(store, res.data)
    return res
  })
}

