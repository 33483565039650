import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

let state = {
  hotels: {
    selected: null,
    items: [],
    loading: true,
  },
}

export default { state, actions, getters, mutations }
