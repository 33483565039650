
import axios from 'axios'
import * as mutations from '../../mutation-types'
import {batchProcessUsers} from '../users/actions';
import {batchProcessCompanies} from '../companies/actions'
import {reduce, isEmpty} from 'lodash';


export const batchProcessTransactions = (store, transactions) => {
  let reduced = reduce(transactions, (sum, transaction) => {

    transaction.amount = +transaction.amount

    if (transaction.company) sum.companies[transaction.company.id] = transaction.company
    delete transaction.company

    if (transaction.author) sum.users[transaction.author.id] = transaction.author
    delete transaction.author

    sum.transactions.push(transaction)
    return sum
  }, { companies: {}, users: {}, transactions: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_TRANSACTIONS, reduced.transactions)
}

export const purchaseCredit = (store, amount) => {
  let companyId = window.Laravel.companyId;
  return axios.post(`/api/companies/${companyId}/credit`, {amount}).then(res => {
    batchProcessTransactions(store, res.data)
    return res;
  })
}

export const fetchTransactionsByCompanyId = (store, id) => {
  return axios.get(`/api/companies/${id}/transactions`).then(res => {
    batchProcessTransactions(store, res.data)
    return res;
  })
}

