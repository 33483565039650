import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPostComponentQuicktakes = (store, postComponentQuicktakes) => {
  let reduced = reduce(postComponentQuicktakes, (sum, postComponentQuicktake) => {
    sum.postComponentQuicktakes.push(postComponentQuicktake)
    return sum
  }, { postComponentQuicktakes: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_POST_COMPONENT_QUICKTAKES, reduced.postComponentQuicktakes)
}

export const destroyPostComponentQuicktake = (store, { id }) => {
  return axios.delete(`/api/admin/posts/quicktakes/${id}`).then(res => {
    store.commit(mutations.DELETE_POST_COMPONENT_QUICKTAKE, { id })
    return res
  })
}

export const createPostComponentQuicktake = (store, data) => {
  return axios.post(`/api/admin/posts/quicktakes`, data).then(res => {
    batchProcessPostComponentQuicktakes(store, [res.data])
    return res
  })
}

export const updatePostComponentQuicktakeById = (store, data) => {
  return axios.put(`/api/admin/posts/quicktakes/${data.id}`, data).then(res => {
    batchProcessPostComponentQuicktakes(store, [res.data])
    return res
  })
}
