import axios from "axios";
import * as types from '../../mutation-types'

export const fetchCompanyEmailTemplates = (store, { id, options }) => {
  let query = Object.entries(options)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  return axios.get(`/api/companies/${id}/company-email-templates?${query}`);
};

export const getCompanyEmailTemplateById = (store, { id, request }) => {
  return axios.get(`/api/companies/${id}/company-email-templates/${request.id}`);
};

export const createCompanyEmailTemplates = (store, { id, request }) => {
  return axios.post(`/api/companies/${id}/company-email-templates`, request);
};

export const updateCompanyEmailTemplates = (store, { id, request }) => {
  return axios.put(`/api/companies/${id}/company-email-templates/${request.id}`, request);
};

export const deleteCompanyEmailTemplates = (store, { id, request }) => {
  return axios.delete(
    `/api/companies/${id}/company-email-templates/${request.id}`
  );
};

export const fetchCompanyEmailTemplatesUsers = (store, { id }) => {
  return axios.get(`/api/companies/${id}/company-email-templates-users`);
};

export const getCampaignEmailFooter = ({commit}, { id }) => {
  return axios.get(`/api/companies/${id}/campaign-email-footer`).then(res => {
    commit(types.SET_CAMPAIGN_EMAIL_FOOTER, res.data.data);
    return res.data.data;
  });
}

export const updateCampaignEmailFooter = ({commit}, { id, data }) => {
  return axios.post(`/api/companies/${id}/campaign-email-footer`, data).then(res => {
    commit(types.SET_CAMPAIGN_EMAIL_FOOTER, res.data.data);
    return res.data.data;
  });
}


export const fetchCompanyEmailTemplatesDefaults = (store, { id }) => {
  return axios.get(`/api/companies/${id}/company-email-templates-defaults`);
};

export const fetchAllCompanyEmailTemplates = (store, { id, request }) => {
  return axios.get(`/api/companies/${id}/company-email-templates-list-all`);
};

export const getCampaignTemplateById = (store, { id, request }) => {
  return axios.get(`/api/companies/${id}/campaign-templates/${request.id}`);
};

