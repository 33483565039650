import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'
import axios from 'axios'

export const batchProcessAwards = (store, awards) => {
  let reduced = reduce(awards, (sum, award) => {
    sum.awards.push(award)
    return sum
  }, { awards: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_AWARD, reduced.awards)
}

export const fetchAwards = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/awards-years?${query}`).then(res => {
    batchProcessAwards(store, res.data.data)
    return res
  })
}

export const fetchAwardById = (store, id) => {
  return axios.get(`/api/admin/awards-years/${id}`).then(res => {
    batchProcessAwards(store, [res.data])
    return res
  })
}

export const fetchPeopleChoiceByAwardId = (store, { id, options }) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/awards-years/${id}/people-choice?${query}`).then(res => {
    batchProcessAwards(store, res.data.data)
    return res
  })
}

export const fetchAwardsAnalyzeData = (store, { id, options }) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/awards-years/${id}/analyze-data?${query}`).then(res => {
    batchProcessAwards(store, res.data.data)
    return res
  })
}

export const createAward = (store, data) => {
  return axios.post(`/api/admin/awards-years`, data).then(res => {
    batchProcessAwards(store, [res.data])
    return res
  })
}

export const updateAward = (store, { id, data }) => {
  return axios.put(`/api/admin/awards-years/${id}`, data).then(res => {
    batchProcessAwards(store, [res.data])
    return res
  })
}

export const stepOneEndAward = (store, { id, company }) => {
  return axios.post(`/api/admin/awards-years/${id}/end-awards/step-one`, company).then(res => {
    return res
  })
}
export const stepOneAutocomplete = (store, { id, options }) => {
  return axios.get(`/api/admin/awards-years/${id}/end-awards/step-one/autocomplete?search=${options}`).then(res => {
    return res
  })
}

export const stepOneGetCompanies = (store, id) => {
  return axios.get(`/api/admin/awards-years/${id}/end-awards/step-one/get-companies`).then(res => {
    return res
  })
}

export const stepOneSetCompanies = (store, { id, company }) => {
  return axios.post(`/api/admin/awards-years/${id}/end-awards/step-one/set-company/${company}`).then(res => {
    return res
  })
}

export const stepOneRemoveCompanies = (store, { id, company }) => {
  return axios.post(`/api/admin/awards-years/${id}/end-awards/step-one/remove-company/${company}`).then(res => {
    return res
  })
}

export const stepRunHTScores = (store, id) => {
  return axios.get(`/api/admin/awards-years/${id}/end-awards/step-run-htscores`).then(res => {
    return res
  })
}

export const stepTwoEndAward = (store, id) => {
  return axios.get(`/api/admin/awards-years/${id}/end-awards/step-two`).then(res => {
    return res
  })
}

export const stepThreeEndAward = (store, id) => {
  return axios.get(`/api/admin/awards-years/${id}/end-awards/step-three`).then(res => {
    return res
  })
}

export const stepFourEndAward = (store, id) => {
  return axios.get(`/api/admin/awards-years/${id}/end-awards/step-four`).then(res => {
    return res
  })
}

export const stepFourEndAwardData = (store, id) => {
  return axios.get(`/api/admin/awards-years/${id}/end-awards/step-four/data`).then(res => {
    return res
  })
}

export const stepFiveEndAward = (store, id) => {
  return axios.get(`/api/admin/awards-years/${id}/end-awards/step-five`).then(res => {
    return res
  })
}

export const stepSixEndAward = (store, id) => {
  return axios.get(`/api/admin/awards-years/${id}/end-awards/step-six`).then(res => {
    return res
  })
}

export const stepSevenEndAward = (store, id) => {
  return axios.get(`/api/admin/awards-years/${id}/end-awards/step-seven`).then(res => {
    return res
  })
}

export const getToggleNotifications = (store) => {
  return axios.get(`/api/admin/settings-awards-notifications`)
}

export const saveNotificationSettings = (store, value) => {
  return axios.put(`/api/admin/settings-awards-notifications`, { awards_product_rank_disable_notifications: value })
}