import axios from 'axios'
import { reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

import { batchProcessProducts } from '../products/actions'
import { batchProcessCategories } from '../categories/actions'
import { batchProcessJobs } from '../jobs/actions'
import { batchProcessCompanies } from '../companies/actions'
import { batchProcessReviews } from '../reviews/actions'

export const batchProcessLeads = (store, leads) => {
  let reduced = reduce(leads, (sum, lead) => {
    if (lead.product) sum.products[lead.product.id] = lead.product
    if (lead.category) sum.categories[lead.category.id] = lead.category
    if (lead.review) sum.reviews[lead.review.id] = lead.review

    if (lead.buyers_guide_companies) sum.companies = [...sum.companies, ...lead.buyers_guide_companies]

    sum.leads.push(lead)

    return sum
  }, { leads: [], products: {}, categories: {}, companies: [], reviews: {} })

  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))
  if (!isEmpty(reduced.categories)) batchProcessCategories(store, Object.values(reduced.categories))
  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))
  if (!isEmpty(reduced.reviews)) batchProcessReviews(store, Object.values(reduced.reviews))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_LEADS, reduced.leads)
}

export const fetchLeads = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/leads?${query}`).then(res => {
    batchProcessLeads(store, res.data.data)
    return res
  })
}

export const fetchLeadsv2 = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/leadsv2?${query}`).then(res => {
    batchProcessLeads(store, res.data.data)
    return res
  })
}

export const updateLeadRequestById = (store, { id, data }) => {
  return axios.put(`/api/lead-requests/${id}`, data).then(res => {
    batchProcessLeads(store, [res.data])
    return res
  })
}

export const updateLeadById = (store, { id, data }) => {
  return axios.put(`/api/leads/${id}`, data).then(res => {
    batchProcessLeads(store, [res.data])
    return res
  })
}

export const updateLeadByIdv2 = (store, { id, data }) => {
  return axios.put(`/api/leadsv2/${id}`, data).then(res => {
    batchProcessLeads(store, [res.data])
    return res
  })
}

export const updateLeadsUserStatusById = (store, { id, data }) => {
  return axios.post(`/api/leads/${id}/user-status`, data)
}

export const createLeads = (store, data) => {
  return axios.post(`/api/leads`, data).then(res => {
    batchProcessJobs(store, [res.data])
    return res
  })
}

export const createLeadsv2 = (store, data) => {
  return axios.post(`/api/leadsv2`, data).then(res => {
    batchProcessJobs(store, [res.data])
    return res
  })
}

export const destroyLeadsv2 = (store, { id }) => {
  return axios.delete(`/api/leadsv2/${id}`).then(res => {
    store.commit(mutations.DELETE_LEAD, { id })
    return res
  })
}

export const fetchLeadById = (store, id) => {
  return axios.get(`/api/leads/${id}`).then(res => {
    batchProcessLeads(store, [res.data])
    return res
  })
}
// THIS NEW ACTION DOESN'T CALL BATCHPROCESSLEADS BUT UPDATES
// A SINGLE LEAD IN THE LIST OF LEADS
export const fetchLeadByIdv2 = async (store, id) => {
  let res = await axios.get(`/api/leadsv2/${id}`)
  store.commit(mutations.ADD_OR_UPDATE_LEAD, res.data)
}

export const fetchLeadRequestById = (store, id) => {
  return axios.get(`/api/lead-requests/${id}`).then(res => {
    batchProcessLeads(store, [res.data])
    return res
  })
}
export const fetchCompanyCategoriesInstaConnect = (store, id) => {
  return axios.get(`/api/company/category-leads-instaconnect/${id}`).then(res => {
    const enabledCount = [].concat(...res.data.categories).filter(item => item.is_autoconnect_on).length
    store.commit(mutations.SET_INSTANT_CONNECT_STATUS, enabledCount > 0)
    return res
  })
}

export const fetchCompanyLeadsSavingsInstaConnect = (store, id) => {
  return axios.get(`/api/company/leads-instaconnect-stats/annual-savings?company_id=${id}`)
}

export const createCompanyCategoriesInstaConnect = (store, data) => {
  return axios.post(`/api/company/category-leads-instaconnect`, data)
}
export const updateCompanyCategoriesInstaConnect = (store, data) => {
  return axios.put(`/api/company/category-leads-instaconnect/${data.cat_autoaccept_company_id}`, data)
}

export const fetchCompanyInstaConnectStats = (store, id) => {
  return axios.get(`/api/company/leads-instaconnect-stats?company_id=${id}`)
}

export const fetchCompanyInstantConnectCost = (store, id) => {
  return axios.get(`/api/company/lead-instant-connect/${id}`)
}

export const toggleCompanyInstantConnect = (store, data) => {
  return axios.post(`/api/company/toggle-instant?company_id=${data.company_id}`, data)
}

export const fetchLeadsTimelineResume = (store, data = {}) => {
  if (Object.keys(data).length === 0) {
    data.fromDate = "";
    data.toDate = "";
  }
  axios.get(`/api/lead-timeline/get-resume?fromDate=${data.fromDate}&toDate=${data.toDate}`).then(res => {
    store.commit(mutations.SET_LEADS_TIMELINE_RESUME, res.data)
  })
}

export const fetchLeadsTimelineResumeByLead = (store, lead) => {
  axios.get(`/api/lead-timeline/get-resume/${lead}`).then(res => {
    store.commit(mutations.SET_LEADS_TIMELINE_RESUME, res.data)
  })
}
export const fetchProfilesOverviewStats = (store, id) => {
  return axios.get(`/api/company/profiles-overview-stats/${id}`)
}

export const fetchLeadPriceList = (store, {companyId, category}) => {
  return axios.get(`/api/company/price-lists/${companyId}?category_id=${category}`).then(res => {
    return res
  })
}

export const fetchPendingLeads = (store, companyId) => {
  return axios.get(`/api/dashboard/lead-product/get-lead-product-pending?company_id=${companyId}`).then(res => {
    store.commit(mutations.SET_PENDING_LEADS, res.data)
    return res;
  })
}

export const setInstantConnectStatus = (store, status) => {
  store.commit(mutations.SET_INSTANT_CONNECT_STATUS, status)
}

export const setManualCrmForwardingStatus = (store, status) => {
  store.commit(mutations.SET_MANUAL_CRM_FORWARDING_STATUS, status)
}

export const setAutomaticCrmForwardingStatus = (store, status) => {
  store.commit(mutations.SET_AUTOMATIC_CRM_FORWARDING_STATUS, status)
}
