import axios from 'axios'
import { reduce } from 'lodash'
import { batchProcessCustomers } from '../customers/actions'
import * as mutations from '../../mutation-types'

export const batchProcessEvents = (store, events) => {
  let reviewsCompleted = {}
  let reduced = reduce(events, (sum, event) => {
    if (event.description === 'ReviewCompleted') {
      reviewsCompleted[event.subject_id] = event
    } else {
      sum.events.push(event)
    }
    return sum
  }, { events: [] })

  let proccessedEvents = reduced.events.concat(Object.values(reviewsCompleted))
  proccessedEvents = proccessedEvents.sort((a, b) => {
    let dateA = a.created_at.replace(/ /g, 'T')
    let dateB = b.created_at.replace(/ /g, 'T')

    if (new Date(dateA).getTime() > new Date(dateB).getTime()) {
      return 1
    }
    if (new Date(dateA).getTime() < new Date(dateB).getTime()) {
      return -1
    }
    return 0
  })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_EVENTS, proccessedEvents)
}

export const fetchEventsByCustomerId = (store, id) => {
  batchProcessCustomers(store, [{ id, loadingEvents: true }])
  return axios.get(`/api/customers/${id}/events`).then(res => {
    batchProcessEvents(store, res.data)
    batchProcessCustomers(store, [{ id, loadingEvents: false }])
    return res
  }).catch(err => {
    batchProcessCustomers(store, [{ id, loadingEvents: false }])
    throw err
  })
}
