import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPostComponentCollectibleItems = (store, postComponentCollectibleItems) => {
  let reduced = reduce(postComponentCollectibleItems, (sum, postComponentCollectibleItem) => {

    sum.postComponentCollectibleItems.push(postComponentCollectibleItem)
    return sum
  }, { postComponentCollectibleItems: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_POST_COMPONENT_COLLECTIBLE_ITEMS, reduced.postComponentCollectibleItems)
}

export const fetchAllPostComponentCollectibleItems = (store, object = {}) => {
  return axios.get(`/api/admin/posts/get-all-collectibles`).then(res => {
    batchProcessPostComponentCollectibleItems(store, res.data.data)
    return res
  })
}

export const fetchPostComponentCollectibleItems = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/posts/collectibles?${query}`).then(res => {
    batchProcessPostComponentCollectibleItems(store, res.data.data)
    return res
  })
}

export const destroyPostComponentCollectibleItem = (store, { id }) => {
  return axios.delete(`/api/admin/posts/remove-collectible?collectible_id=${id}`).then(res => {
    store.commit(mutations.DELETE_POST_COMPONENT_COLLECTIBLE_ITEM, { id })
    return res
  })
}

export const createPostComponentCollectibleItem = (store, data) => {
  return axios.post(`/api/admin/posts/save-collectible`, data).then(res => {
    batchProcessPostComponentCollectibleItems(store, [res.data])
    return res
  })
}

export const updatePostComponentCollectibleItemById = (store, data) => {
  return axios.put(`/api/admin/posts/update-collectible`, data).then(res => {
    batchProcessPostComponentCollectibleItems(store, [res.data])
    return res
  })
}
