import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'
/* We need this init function because there is no DOM while running tests,
   so jest is not able to define global */
function initMinReviewCountBeforePurchases() {
  if ('Laravel' in window &&
      'settings' in window.Laravel &&
      'minimum_review_count_for_lead_purchase' in window.Laravel.settings)
  {
    return window.Laravel.settings.minimum_review_count_for_lead_purchase
  } else {
    return 25
  }

}

let state = {
  items: [],
  tabs: {
    'All': 0,
    'Organic': 0,
    'Review Manager': 0,
    'Verifications': 0,
  },
  minReviewCountBeforePurchases:  initMinReviewCountBeforePurchases()
}

export default { state, actions, getters, mutations }
