import axios from 'axios'
import { reduce, isEmpty, map } from 'lodash'
import { batchProcessCategories } from '../categories/actions'
import * as mutations from '../../mutation-types'

export const batchProcessFeatures = (store, features) => {
  let reduced = reduce(features, (sum, features) => {
    if (features.categories) {
      features.categories = map(features.categories, category => {
        sum.categories[category.id] = category
        return category.id
      })
    }

    sum.features.push(features)
    return sum
  }, { categories: {}, features: [] })

  if (!isEmpty(reduced.categories)) batchProcessCategories(store, Object.values(reduced.categories))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_FEATURES, reduced.features)
}

export const fetchFeatures = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/features?${query}`).then(res => {
    batchProcessFeatures(store, res.data.data)
    return res
  })
}

export const destroyFeature = (store, { id }) => {
  return axios.delete(`/api/features/${id}`).then(res => {
    store.commit(mutations.DELETE_POST, { id })
    return res
  })
}

export const createFeature = (store, data) => {
  return axios.post(`/api/features`, data).then(res => {
    batchProcessFeatures(store, [res.data])
    return res
  })
}

export const updateFeatureById = (store, { id, data }) => {
  return axios.put(`/api/features/${id}`, data).then(res => {
    batchProcessFeatures(store, [res.data])
    return res
  })
}

export const fetchFeatureById = (store, id) => {
  return axios.get(`/api/features/${id}`).then(res => {
    batchProcessFeatures(store, [res.data])
    return res
  })
}

export const updateFeaturesOnFeatureGroupById = (store, { id, data }) => {
  return axios.put(`/api/features/${id}/syncFeaturesGroup?`, data).then(res => {
    return res
  })
}

export const fetchFeaturesGroupSearch = (store, { id, term }) => {
  let termEncoded = `search=${encodeURI(term)}`
  return axios.get(`/api/features/${id}/search?${termEncoded}`).then(res => {
    return res
  })
}

export const updateFeatureProductsById = (store, { id, data }) => {
  return axios.put(`/api/features/${id}/syncProducts?`, data).then(res => {
    return res
  })
}
