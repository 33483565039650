import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'
import axios from 'axios'

export const batchProcessLeaderboard = (store, leaderboards) => {
  let reduced = reduce(leaderboards, (sum, leaderboard) => {
    sum.leaderboards.push(leaderboard)
    return sum
  }, { leaderboards: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_LEADERBOARD, reduced.leaderboards)
}

export const fetchLeaderboardByCompanyId = (store, id) => {
  return axios.get(`/api/awards/leaderboard/${id}/end-awards`).then(res => {
    return res
  })
}
