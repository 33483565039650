import { find, filter } from 'lodash'

export const allReviews = state => state.items
export const reviewById = state => id => find(state.items, review => review.id === id)
export const reviewsByIds = state => ids => filter(state.items, item => ids.indexOf(item.id) > -1)
export const reviewsByUserId = state => id => filter(state.items, item => item.user_id === id)
export const unrespondedReviews = state => filter(state.items, item => !item.has_response)
export const unrespondedReviewsByUserIds = state => ids => filter(unrespondedReviews(state), item => ids.indexOf(item.user_id) > -1)
export const minReviewCountBeforePurchases = state => state.minReviewCountBeforePurchases
export const meetsMinReviewsCriteria = state => state.items.length > state.minReviewCountBeforePurchases
export const lastReviewByCustomerId = state => id => state.items.filter(item => item.user_id === id).reduce(
  (lastReview, review) => {
    if (lastReview === undefined || lastReview.date <= review.date) {
      lastReview = review
    }
    return lastReview
  }
  ,
  undefined)
export const getAllTabsAdminReviews = state => state.tabs
