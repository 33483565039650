import * as mutations from '../../mutation-types'
import axios from 'axios'


export const fetchPpcLedgerAccount = (store) => {
  return axios.get(`/api/ppc-ledger-account`).then(res => {
      store.commit(mutations.SET_PPC_LEDGER_ACCOUNT, res.data)
      return res
  })
}

export const logPpcActivity = (store, payload) => {
  return axios.post(`/api/admin/ppc-activity-log`, payload).then(res => {
      store.commit(mutations.SET_PPC_ACTIVITY_LOG, res.data)
      return res
  })
}

export const fetchPpcSpendStats = (store, { companyId }) => {
  return axios.get(`/api/admin/ppc-spend-stats/${companyId}/admin-spend-stats`);
}

export const fetchPpcBranding = (store, { companyId }) => {
  return axios.get(`/api/companies/${companyId}/ppc-branding`).then(res => {
    store.commit(mutations.SET_PPC_CATEGORY_BRANDING, res.data.data)
    return res
  });
}

export const savePpcBranding = (store, { companyId, request }) => {
  return axios.post(`/api/companies/${companyId}/ppc-branding`, request);
}
