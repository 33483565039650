import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

let state = {
  articles: [],
  categories: [],
  products: [],
  resources: [],
}

export default { state, actions, getters, mutations }
