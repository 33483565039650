import * as types from '../../mutation-types'
import { addOrUpdateItems, removeItem } from '../../helpers'

export default {
  [types.BATCH_ADD_OR_UPDATE_HOTELS]: (state, hotels) => addOrUpdateItems(state, hotels),
  [types.DELETE_HOTEL]: (state, hotel) => removeItem(state, hotel),
  [types.SET_API_STATUS_COUNTS]: (state, apiStatusCounts) => {
    state.apiStatusCounts = apiStatusCounts;
  }
}
