import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessRegions = (store, regionsdata) => {
  let reduced = reduce(regionsdata, (sum, regions) => {
    sum.regions.push(regions)
    return sum
  }, { regions: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_REGIONS, reduced.regions)
}

export const fetchRegions = (store) => {
  axios.get(`/api/regions`).then(res => {
    batchProcessRegions(store, res.data)
    return res
  })
}

export const fetchRegionWithIds = (store, options) => {
  return axios.get(`/api/admin/regions`, { params: options }).then(res => {
    batchProcessRegions(store, res.data.data)
    return res
  })
}
