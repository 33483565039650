import users from './users'
import posts from './posts'
import postClaims from './post-claims'
import companies from './companies'
import jobs from './jobs'
import referrals from './referrals'
import companyClaims from './company-claims'
import companyInstaconnectActivityLogByCompany from './company-instaconnect-activity-logs'
import products from './products'
import categories from './categories'
import departments from './departments'
import reviews from './reviews'
import recommendations from './recommendations'
import integrations from './integrations'
import features from './features'
import featuresGroup from './features-groups'
import integrationInvitations from './integration-invitations'
import reviewRequests from './review-requests'
import testimonials from './testimonials'
import studies from './studies'
import techAwards from './tech-awards'
import screenshots from './screenshots'
import featuredBrands from './featured-brands'
import questions from './questions'
import invitations from './invitations'
import customers from './customers'
import npsRequests from './nps-requests'
import npsResponses from './nps-responses'
import events from './events'
import internalNotes from './internal-notes'
import reviewResponses from './review-responses'
import hotels from './hotels'
import hotelTypes from './hotel-types'
import hotelActivityLogs from './hotel-activity-logs'
import hotelPropertyAmenities from './hotel-property-amenities'
import hotelRoomFeatures from './hotel-room-features'
import hotelRoomTypes from './hotel-room-types'
import hotelStyles from './hotel-styles'
import emailTemplates from './email-templates'
import leads from './leads'
import leadProduct from './lead-product'
import techStackAudits from './tech-stack-audits'
import leadAnalytics from './leadAnalytics'
import chatMessages from './chat-messages'
import chatRooms from './chat-rooms'
import quizzes from './quizzes'
import news from './news'
import reports from './reports'
import downloads from './downloads'
import buyersGuides from './buyers-guides'
import transactions from './transactions'
import campaigns from './campaigns'
import membershipPlanBenefits from './membership-plan-benefits'
import membershipPlanCategories from './membership-plan-categories'
import membershipPlanRules from './membership-plan-rules'
import membershipPlanPrices from './membership-plan-prices'
import membershipPlanPaths from './membership-plan-paths'
import membershipPlans from './membership-plans'
import ledger from './ledger'
import openai from './openai/index.js'
import postComponentCollectibleItems from './post-component-collectible-items'
import postComponentCollections from './post-component-collections'
import postComponentSections from './post-component-sections'
import postComponentQuicktakes from './post-component-quicktakes'
import postComponentTexts from './post-component-texts'
import postComponentAuthors from './post-component-authors'
import postComponentPpcTopLists from './post-component-ppc-top-lists'
import productModules from './product-modules'
import propertySizes from './property-sizes'
import regions from './regions'
import countries from './countries'
import awards from './awards'
import leaderboards from './leaderboards'
import resources from './resources'
import productBenefits from './product-benefits'
import search from './search'
import ContentDownloads from './content-download'
import manageRegistrations from './awards-manage-registrations'
import fitScore from "./fit-score/index.js";
import tripadvisor from "./tripadvisor/index.js";
import booking from "./booking/index.js";
import ppc from './ppc'
import Languages from './Languages'
import LeadSegments from './lead-segments'
import revBox from './revbox'
import HotelTechStackDetails from './hotel-tech-stack-details'
import oauthClients from './oauth-clients'
import oauthAccessGrants from './oauth-access-grants'
import oauthAuthGrants from './oauth-auth-grants'
import oauthRefreshGrants from './oauth-refresh-grants'
import enforcedRedirects from './enforced-redirects'
import nylas from './nylas'
import companyEmailTemplates from './company-email-templates'
import homeRedesign from './home-redesign'
import designSystemInventory from './design-system-inventory'

export default {
  companies,
  jobs,
  referrals,
  companyClaims,
  companyInstaconnectActivityLogByCompany,
  users,
  posts,
  postClaims,
  products,
  categories,
  departments,
  reviews,
  recommendations,
  hotels,
  hotelTypes,
  hotelActivityLogs,
  hotelPropertyAmenities,
  hotelRoomFeatures,
  hotelRoomTypes,
  hotelStyles,
  integrations,
  features,
  featuresGroup,
  integrationInvitations,
  reviewRequests,
  testimonials,
  screenshots,
  featuredBrands,
  studies,
  techAwards,
  questions,
  invitations,
  customers,
  events,
  internalNotes,
  reviewResponses,
  npsRequests,
  npsResponses,
  emailTemplates,
  leads,
  leadProduct,
  techStackAudits,
  leadAnalytics,
  news,
  chatMessages,
  chatRooms,
  quizzes,
  reports,
  downloads,
  buyersGuides,
  transactions,
  campaigns,
  membershipPlanBenefits,
  membershipPlanCategories,
  membershipPlanRules,
  membershipPlanPrices,
  membershipPlanPaths,
  membershipPlans,
  ledger,
  openai,
  postComponentCollectibleItems,
  postComponentCollections,
  postComponentQuicktakes,
  postComponentSections,
  postComponentTexts,
  postComponentAuthors,
  postComponentPpcTopLists,
  productModules,
  propertySizes,
  regions,
  countries,
  awards,
  leaderboards,
  resources,
  productBenefits,
  search,
  ContentDownloads,
  manageRegistrations,
  fitScore,
  tripadvisor,
  booking,
  ppc,
  Languages,
  LeadSegments,
  HotelTechStackDetails,
  revBox,
  oauthClients,
  oauthAccessGrants,
  oauthAuthGrants,
  oauthRefreshGrants,
  enforcedRedirects,
  nylas,
  companyEmailTemplates,
  homeRedesign,
  designSystemInventory
}
