import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPlanBenefits = (store, benefits) => {
  let reduced = reduce(benefits, (result, benefit) => {
    if (benefit.icon) {
      result.icons.add(benefit.icon)
    }
    if (benefit.disabled_at !== null) {
      benefit.enable = false
    } else {
      benefit.enable = true
    }
    bindBenefitFunctions(store, benefit)
    result.benefits.push(benefit)
    return result
  }, { benefits: [], icons: new Set() })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_PLAN_BENEFITS, reduced.benefits)
  addBenefitIcons(store, reduced.icons)
}
/* Please note that this is an admin only call */
export const fetchPlanBenefits = (store, condition = 'all') => {
  return axios.get(`/api/admin/membership-plan/benefits?condition=${condition}`).then(res => {
    batchProcessPlanBenefits(store, res.data)
    return res
  })
}
// BEWARE THIS FUNCTION IS USED OUT OF THIS MODULE
export const bindBenefitFunctions = (store, benefit) => {
  benefit.value = function () { 
    return this.quantity * this.price
  }.bind(benefit)
  benefit.valueToString = function () { 
    let prelude = this.quantity > 1 ? `${this.quantity} of` : ''

    return `${prelude} + ${this.unit} + (${String(this.value())})`
  }.bind(benefit)
}
/* Please note that icons must be a data structure that defines forEach interface, either an Array or preferably a Set */
export const addBenefitIcons = (store, icons) => {
  store.commit(mutations.BATCH_ADD_OR_UPDATE_PLAN_BENEFITS_ICONS, icons)
} 