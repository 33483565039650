import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types.js'


export const batchProcessUserNylasSettings = (store, userNylasSettings) => {
  let reduced = reduce(userNylasSettings, (sum, userNylasSetting) => {
    sum.userNylasSettings.push(userNylasSetting)
    return sum
  }, { userNylasSettings: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_USER_NYLAS_SETTINGS, reduced.userNylasSettings)
}

export const deleteUserNylasGrant = (store) => {
  return axios.delete(`/api/dashboard/nylas/disconnect-user`).then(res => {
    let removedId = res?.data?.id
    if (removedId) {
      store.commit(mutations.DELETE_USER_NYLAS_SETTINGS, removedId)
    }
    return res
  })
}

export const fetchNylasAuthUrlData = () => {
  return axios.get(`/api/dashboard/nylas/auth-url-request`).then(res => {
    return res
  })
}

export const updateIsEnabledNylasSetting = (store, data) => {
  return axios.patch(`/api/dashboard/nylas/update-is-enabled`, data).then(res => {
    batchProcessUserNylasSettings(store, [res.data])
    return res
  })
}
