import axios from 'axios'
// No store
export const fetchAllPageViews = async (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/leads-page-analytics?${query}`).then(res => {
    return res
  })
}

export const fetchMarketplaceData = async (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/marketplace-data?${query}`).then(res => {
    return res
  })
}

export const fetchFinancialData = async (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/financial-data?${query}`).then(res => {
    return res
  })
}

export const fetchGeneralData = async (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/general-data?${query}`).then(res => {
    return res
  })
}

export const fetchGeneralDataChart = async (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/general-data-chart?${query}`).then(res => {
    return res
  })
}
