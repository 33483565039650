import * as types from '../../mutation-types'
import { addOrUpdateItems, removeItem } from '../../helpers'

export default {
  [types.BATCH_ADD_OR_UPDATE_CUSTOMERS]: (state, customers) => addOrUpdateItems(state, customers),
  [types.ADD_TOTALS_CUSTOMERS]: (state, totals) => (state.customersTotalTaps = totals),
  [types.LOADING_CUSTOMERS]: (state, value) => (state.isLoading = value),
  [types.ADDING_CUSTOMER_INTERNAL_NOTE]: (state, value) => (state.addingInternalNote = value),
  [types.ADD_OR_UPDATE_CUSTOMER_NOTES]: (state, value) => addOrUpdateItems(state, value, 'id', 'notes'),
  [types.DELETE_CUSTOMERS]: (state, customers) => customers.map(customer => removeItem(state, { id: customer })),
  [types.INTERVAL_TABS_CUSTOMERS]: (state, interval) => (state.intervalTabsCustomers = interval)
}