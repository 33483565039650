import * as types from '../../mutation-types'
import { addOrUpdateItems, removeItem } from '../../helpers'

export default {
  [types.BATCH_ADD_OR_UPDATE_CHAT_MESSAGE]: (state, messages) => addOrUpdateItems(state, messages),
  [types.DELETE_CHAT_MESSAGE]: (state, message) => removeItem(state, message),

  [types.READ_CHAT_MESSAGES]: (state, { roomId, isVendor = false }) => {
    const date = (new Date()).toISOString().slice(0, 19).replace('T', ' ')
    let unreadMessages = state.items
      .filter(message => !message.read_at && message.chat_room_id === roomId && message.is_vendor === !isVendor)
      .map(message =>
        Object.assign({}, message, {
          read_at: date
        }))

    if (unreadMessages.length) addOrUpdateItems(state, unreadMessages)
  }
}