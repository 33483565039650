import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPlanCategories = (store, categories) => {
  let reduced = reduce(categories, (result, category) => {
    result.categories.push(category)
    return result
  }, { categories: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_PLAN_CATEGORY, reduced.categories)
}

export const fetchPlanCategories = (store, condition = 'all') => {
  return axios.get(`/api/membership-plan-categories`).then(res => {
    batchProcessPlanCategories(store, res.data)
    return res
  })
}