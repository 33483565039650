import * as types from '../../mutation-types'
import { addOrUpdateItems, addOrUpdateItem, updateItemAttribute, removeItem } from '../../helpers'

export default {
  [types.BATCH_ADD_OR_UPDATE_LEADS]: (state, leads) => addOrUpdateItems(state, leads),
  [types.ADD_OR_UPDATE_LEAD]: (state, lead) => addOrUpdateItem(state, lead),
  [types.UPDATE_LEAD_ATTRIBUTES]: (state, inObject) => updateItemAttribute(state, inObject),
  [types.DELETE_LEAD]: (state, lead) => removeItem(state, lead),
  [types.SET_LEADS_TIMELINE_RESUME]: (state, timeline_resume) => { state.timeline_resume = timeline_resume },
  [types.SET_PENDING_LEADS]: (state, pending_leads) => { state.pending_leads = pending_leads },
  [types.SET_INSTANT_CONNECT_STATUS]: (state, instant_connect_status) => { state.instant_connect_status = instant_connect_status },
  [types.SET_MANUAL_CRM_FORWARDING_STATUS]: (state, manual_crm_forwarding_status) => { state.manual_crm_forwarding_status = manual_crm_forwarding_status },
  [types.SET_AUTOMATIC_CRM_FORWARDING_STATUS]: (state, automatic_crm_forwarding_status) => { state.automatic_crm_forwarding_status = automatic_crm_forwarding_status }

}
