import axios from 'axios'
import { reduce, isEmpty } from 'lodash'
import { batchProcessCompanies } from '../companies/actions'
import { batchProcessUsers } from '../users/actions'
import { batchProcessProducts } from '../products/actions'
import * as mutations from '../../mutation-types'

export const processCompanyClaim = (store, claim) => {
  store.commit(mutations.ADD_OR_UPDATE_COMPANY_CLAIM, claim)
}

export const batchProcessCompanyClaims = (store, claims) => {
  let reduced = reduce(claims, (sum, claim) => {
    if (claim.company) sum.companies[claim.company.id] = claim.company
    delete claim.company

    if (claim.user) sum.users[claim.user.id] = claim.user
    delete claim.user

    if (claim.product) sum.products[claim.product.id] = claim.product
    delete claim.product

    sum.claims.push(claim)
    return sum
  }, { companies: {}, users: {}, products: {}, claims: [] })

  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))
  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))
  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_COMPANY_CLAIMS, reduced.claims)
}

export const acceptCompanyClaim = (store, id) => {
  return axios.post(`/api/accepted-company-claims`, { id }).then(res => {
    processCompanyClaim(store, res.data)
    return res
  })
}

export const rejectCompanyClaim = (store, id) => {
  return axios.delete(`/api/pending-company-claims/${id}`).then(res => {
    processCompanyClaim(store, res.data)
    return res
  })
}

export const fetchCompanyClaims = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/company-claims?${query}`).then(res => {
    batchProcessCompanyClaims(store, res.data.data)
    return res
  })
}

export const postCompanyClaim = (store, data) => {
  return axios.post(`/api/company-claims`, data).then(res => {
    processCompanyClaim(store, res.data)
    return res
  })
}


export const postUnauthenticatedCompanyClaim = (store, data) => {
  return axios.post(`/api/unauthenticated-company-claims`, data).then(res => {
    processCompanyClaim(store, res.data)
    return res
  })
}
