import { reduce, isEmpty } from 'lodash'
import { batchProcessProducts } from '../products/actions'
import * as mutations from '../../mutation-types'
import axios from 'axios'
import {batchProcessScreenshots} from "../screenshots/actions";

export const fetchSearchProducts = (store, query) => {
  axios.get('/api/search-products?query=' + query).then(response => {
    store.commit(mutations.ADD_SEARCH_PRODUCTS, response.data)
  })
}

export const fetchSearchResources = (store, query) => {
  axios.get('/api/search-resources?query=' + query).then(response => {
    store.commit(mutations.ADD_SEARCH_RESOURCES, response.data)
  })
}

export const fetchSearchArticles = (store, query) => {
  axios.get('/api/search-articles?query=' + query).then(response => {
    store.commit(mutations.ADD_SEARCH_ARTICLES, response.data)
  })
}

export const fetchSearchCategories = (store, query) => {
  axios.get('/api/search-categories?query=' + query).then(response => {
    store.commit(mutations.ADD_SEARCH_CATEGORIES, response.data)
  })
}
