import axios from 'axios'
import { map, reduce, isEmpty } from 'lodash'
import { batchProcessCompanies } from '../companies/actions'
import { batchProcessUsers } from '../users/actions'
import * as mutations from '../../mutation-types'
import { batchProcessCustomers } from '../customers/actions'

export const batchProcessHotels = (store, hotels) => {
  let reduced = reduce(hotels, (sum, hotel) => {
    if (hotel.customers) {
      hotel.customers = map(hotel.customers, customer => {
        sum.customers[customer.id] = customer
        return customer.id
      })
    }

    if (hotel.companies) {
      hotel.companies = map(hotel.companies, company => {
        sum.companies[company.id] = company
        return company.id
      })
    }

    if (hotel.users) {
      hotel.users = map(hotel.users, user => {
        sum.users[user.id] = user
        return user.id
      })
    }

    sum.hotels.push(hotel)
    return sum
  }, { users: {}, customers: {}, companies: {}, hotels: [] })

  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))
  if (!isEmpty(reduced.customers)) batchProcessCustomers(store, Object.values(reduced.customers))
  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_HOTELS, reduced.hotels)
}

export const fetchHotels = (store, options) => {
  return axios.get(`/api/hotels`, { params: options }).then(res => {
    batchProcessHotels(store, res.data.data)
    if (res.data.api_status_counts) {
      store.commit(mutations.SET_API_STATUS_COUNTS, res.data.api_status_counts);
    }
    return res
  })
}

export const fetchAllDistinctCitiesFromHotels = (store, options) => {
  return axios.get(`/api/admin/hotels/get-all/distinct-cities`).then(res => {
    return res
  })
}

export const fetchPropertyAmenitiesFromHotel = (store, id) => {
  return axios.get(`/api/admin/hotels/${id}/property-amenities`).then(res => {
    return res
  })
}

export const fetchRoomFeaturesFromHotel = (store, id) => {
  return axios.get(`/api/admin/hotels/${id}/room-features`).then(res => {
    return res
  })
}

export const fetchRoomTypesFromHotel = (store, id) => {
  return axios.get(`/api/admin/hotels/${id}/room-types`).then(res => {
    return res
  })
}

export const fetchStylesFromHotel = (store, id) => {
  return axios.get(`/api/admin/hotels/${id}/styles`).then(res => {
    return res
  })
}

export const fetchHotelById = (store, { id, tab }) => {
  return axios.get(`/api/hotels/${id}?tab=${tab}`).then(res => {
    batchProcessHotels(store, [res.data])
    return res
  })
}

export const updateHotelById = (store, { id, data }) => {
  return axios.put(`/api/hotels/${id}`, data).then(res => {
    batchProcessHotels(store, [res.data])
    return res
  })
}

export const updateHotelsByIds = (store, { hotels }) => {
  return axios.put(`/api/hotels/mass/update`, { hotels: hotels }).then(res => {
    batchProcessHotels(store, res.data)
    return res
  })
}

export const createHotel = (store, data) => {
  return axios.post(`/api/hotels`, data).then(res => {
    batchProcessHotels(store, [res.data])
    return res
  })
}

export const updateOrCreateHotelCompanies = (store, data) => {
  return axios.post(`/api/hotels-companies`, data).then(res => {
    batchProcessHotels(store, [res.data])
    return res
  })
}

export const addCompanyToHotel = (store, { hotelId, companyId }) => {
  return axios.post(`/api/hotels/${hotelId}/companies`, { id: companyId }).then(res => {
    batchProcessHotels(store, [res.data])
    return res
  })
}

export const addPropertyAmenityToHotel = (store, { hotelId, hotelItemId }) => {
  return axios.post(`/api/admin/hotels/${hotelId}/property-amenities`, { id: hotelItemId }).then(res => {
    return res
  })
}

export const addRoomFeatureToHotel = (store, { hotelId, hotelItemId }) => {
  return axios.post(`/api/admin/hotels/${hotelId}/room-features`, { id: hotelItemId }).then(res => {
    return res
  })
}

export const addRoomTypeToHotel = (store, { hotelId, hotelItemId }) => {
  return axios.post(`/api/admin/hotels/${hotelId}/room-types`, { id: hotelItemId }).then(res => {
    return res
  })
}

export const addStyleToHotel = (store, { hotelId, hotelItemId }) => {
  return axios.post(`/api/admin/hotels/${hotelId}/styles`, { id: hotelItemId }).then(res => {
    return res
  })
}

export const removeCompanyFromHotel = (store, { hotelId, companyId }) => {
  return axios.delete(`/api/hotels/${hotelId}/companies/${companyId}`).then(res => {
    batchProcessHotels(store, [res.data])
    return res
  })
}

export const removePropertyAmenityToHotel = (store, { hotelId, hotelItemId }) => {
  return axios.delete(`/api/admin/hotels/${hotelId}/property-amenities/${hotelItemId}`).then(res => {
    return res
  })
}

export const removeRoomFeatureToHotel = (store, { hotelId, hotelItemId }) => {
  return axios.delete(`/api/admin/hotels/${hotelId}/room-features/${hotelItemId}`).then(res => {
    return res
  })
}

export const removeRoomTypeToHotel = (store, { hotelId, hotelItemId }) => {
  return axios.delete(`/api/admin/hotels/${hotelId}/room-types/${hotelItemId}`).then(res => {
    return res
  })
}

export const removeStyleToHotel = (store, { hotelId, hotelItemId }) => {
  return axios.delete(`/api/admin/hotels/${hotelId}/styles/${hotelItemId}`).then(res => {
    return res
  })
}

export const destroyHotel = (store, { id }) => {
  return axios.delete(`/api/hotels/${id}`).then(res => {
    store.commit(mutations.DELETE_HOTEL, { id })
    return res
  })
}

export const findIfExistHotelInDb = (store, options) => {
  const filteredOptions = {
    location_id: options.location_id,
    address: options.address,
    city: options.city,
    country: options.country,
    name: options.name,
    state: options.state,
    region: options.region,
    postalcode: options.postalcode,
  };

  let query = Object.entries(filteredOptions)
                    .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
                    .join('&');

  return axios.get(`/api/hotels/find-if-exist?${query}`)
}

// No store
export const countHotelsWithIssues = (store) => {
  return axios.get(`/api/hotels/tripadvisor-booking-issues`)
}

// No store
export const fetchHotelsWithIssues = (store, options) => {
  const filteredOptions = {
    search_hotel: options.search_hotel? options.search_hotel : '',
    per_page: options.per_page? options.per_page : '',
    page: options.page? options.page : '',
    filter: options.filter? options.filter : '',
  };
  let query = Object.entries(filteredOptions)
                    .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
                    .join('&');
  return axios.get(`/api/hotels/get-all-tripadvisor-booking-issues?${query}`)
}

// No store
export const countHotelsOrphans = (store) => {
  return axios.get(`/api/hotels/orphans`)
}

export const fetchHotelsOrphans = (store, options) => {
  const query = new URLSearchParams(options).toString()
  return axios.get(`/api/hotels/get-all-orphan-hotels?${query}`)
}

export const createHotelNoTripAdvisor = (store, data) => {
  return axios.post(`/api/hotels/create-hotel-no-trip-advisor`, data).then(res => {
    batchProcessHotels(store, [res.data])
    return res
  })
}

// No store
export const missingStadisticsTripadvisorIntegrations = (store) => {
  return axios.get(`/api/hotels/missing-stadistics-tripadvisor-integrations`)
}
