import * as types from '../../mutation-types'

export default {
  [types.FIT_SCORE_UPDATE_GOOGLE_PLACES_LOOKUP_LIST]: (state, googlePlacesLookup) => {state.googlePlacesLookup.items  = googlePlacesLookup},
  [types.FIT_SCORE_UPDATE_HOTEL_SIZE_LIST]: (state, hotelSize) => {state.hotelSize.items  = hotelSize},
  [types.FIT_SCORE_UPDATE_PROPERTY_SIZE_LIST]: (state, propertySize) => {state.propertySize.items  = propertySize},
  [types.FIT_SCORE_UPDATE_COUNTRY_LIST]: (state, country) => {state.country.items  = country},
  [types.FIT_SCORE_UPDATE_REGION_LIST]: (state, region) => {state.region.items  = region},
  [types.FIT_SCORE_UPDATE_PMS_OR_HMS_LIST]: (state, region) => {state.pmsOrHms.items  = region},
  [types.FIT_SCORE_UPDATE_CATEGORY_LIST]: (state, category) => {state.category.items = category},
  [types.FIT_SCORE_UPDATE_RESULTS_LIST]: (state, results) => {state.result.items  = results}
}
