import axios from 'axios'
import { map, reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types.js'

export const batchProcessCompanyInstaconnectActivityLogs = (store, companyInstaconnectActivityLogs) => {
  let reduced = reduce(companyInstaconnectActivityLogs, (sum, companyInstaconnectActivityLog) => {
    sum.companyInstaconnectActivityLogs.push(companyInstaconnectActivityLog)
    return sum
  }, { companyInstaconnectActivityLogs: [] })
}

export const fetchCompanyInstaconnectActivityLogByCompany = (store, { id, options}) => {
  return axios.get(`/api/admin/company-instaconnect-activity-logs/${id}`, { params: options }).then(res => {
    batchProcessCompanyInstaconnectActivityLogs(store, res.data)
    return res
  })
}
