import axios from 'axios'
import * as mutations from '../../mutation-types'

export const fetchTripadvisorHotels = (store, options) => {
  if (!options.query) {
    options.query = 'hotel'
  }
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  const spaceCount = (options.query.match(/ /g) || []).length;
  const ignoreCoords = spaceCount > 0 ? 'true' : 'false';
  store.state.hotels.loading = true
  return axios.get(`/api/tripadvisor/hotels-autocomplete?${query}&ignoreCoords=${ignoreCoords}`).then(res => {
    let hotels = []
    if (res.data) {
      hotels = res.data
    }
    store.commit(mutations.TRIPADVISOR_UPDATE_HOTELS_LIST, hotels)
  }).finally(() => {
    store.state.hotels.loading = false
  })
}

export const fetchTripadvisorHotelInfo = (store, location_id) => {
  return axios.get(`/api/tripadvisor/get-hotel-information?location_id=${location_id}`)
}

export const fetchRoomsCount = (store, location_id) => {
  return axios.get(`/api/tripadvisor/get-hotel-rooms-count?location_id=${location_id}`)
}

export const fetchTripadvisorPhotos = (store, location_id) => {
  return axios.get(`/api/tripadvisor/get-hotel-photos?location_id=${location_id}`)
}

export const fetchValidCountryAndRegionInfo = (store, location_id) => {
  return axios.get(`/api/admin/tripadvisor/get-valid-country-and-region-info-from-location-id/${location_id}`)
}
