import axios from 'axios'
import * as mutations from '../../mutation-types'

export const batchProcessChatMessages = (store, chatMessages) => {
  store.commit(mutations.BATCH_ADD_OR_UPDATE_CHAT_MESSAGE, chatMessages)
}

export const batchProcessChatMessage = (store, { chatMessage, isOpenChat = true, companyId = null }) => {
  deleteTempMessages(store)
  batchProcessChatMessages(store, [chatMessage])

  if (store.getters.activeChatRoomId === chatMessage.chat_room_id && !companyId) {
    readMessagesInRoom(store, chatMessage.chat_room_id)
  }

  if (store.getters.activeChatRoomId === chatMessage.chat_room_id && companyId && isOpenChat) {
    readMessagesFromHoteilerInRoom(store, {
      roomId: chatMessage.chat_room_id,
      companyId: companyId
    })
  }
}

export const createChatMessage = (store, { roomId, data, tempMessage, companyId }) => {
  batchProcessChatMessages(store, tempMessage)

  if (companyId) {
    return axios.post(`/api/companies/${companyId}/rooms/${roomId}/messages`, data)
      .then(res => {
        batchProcessChatMessage(store, { chatMessage: res.data, companyId: companyId })
        return res
      })
  }

  return axios.post(`/api/rooms/${roomId}/messages`, data)
    .then(res => {
      batchProcessChatMessage(store, { chatMessage: res.data })
      return res
    })
}

export const deleteTempMessages = (store) => {
  store.commit(mutations.DELETE_CHAT_MESSAGE, {
    id: Number.MAX_SAFE_INTEGER
  })
}

export const fetchUserChatMessages = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/chat-messages?${query}`).then(res => {
    batchProcessChatMessages(store, res.data)
    return res
  })
}

export const fetchCompanyChatMessages = (store, { companyId, options }) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/companies/${companyId}/chat-messages?${query}`).then(res => {
    batchProcessChatMessages(store, res.data)
    return res
  })
}

export const fetchMessagesByRoomId = (store, roomId) => {
  return axios.get(`/api/rooms/${roomId}/messages`).then(res => {
    batchProcessChatMessages(store, res.data)
    return res
  })
}

export const fetchMessagesByRoomIdAndCompanyId = (store, { companyId, roomId }) => {
  return axios.get(`/api/companies/${companyId}/rooms/${roomId}/messages`).then(res => {
    batchProcessChatMessages(store, res.data)
    return res
  })
}

export const completedActionMessage = (store, { roomId, messageId }) => {
  axios.post(`/api/rooms/${roomId}/messages/${messageId}/completed-actions`)
    .then(res => {
      batchProcessChatMessages(store, [res.data])
      return res
    })
}

export const readMessagesInRoom = (store, roomId) => {
  axios.post(`/api/rooms/${roomId}/messages-read`)
  store.commit(mutations.READ_CHAT_MESSAGES, { roomId, isVendor: false })
}

export const readMessagesFromHoteilerInRoom = (store, { companyId, roomId }) => {
  axios.post(`/api/companies/${companyId}/rooms/${roomId}/messages-read`)
  store.commit(mutations.READ_CHAT_MESSAGES, { roomId, isVendor: true })
}