import axios from 'axios'
import * as mutations from '../../mutation-types'

import { reduce, isEmpty } from 'lodash'
import { batchProcessCustomers } from '../customers/actions'

export const batchProcessReviewRequests = (store, reviewRequests) => {
  let reduced = reduce(reviewRequests, (sum, reviewRequest) => {
    if (reviewRequest.customer) {
      sum.customers[reviewRequest.customer.id] = reviewRequest.customer
      delete reviewRequest.customer
    }

    sum.reviewRequests.push(reviewRequest)
    return sum
  }, { reviewRequests: [], customers: {} })

  if (!isEmpty(reduced.customers)) batchProcessCustomers(store, Object.values(reduced.customers))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_REVIEW_REQUESTS, reduced.reviewRequests)
}

export const sendCustomerReviewRequest = (store, { id, payload }) => {
  return axios.post(`/api/customers/${id}/review-requests`, payload).then(res => {
    batchProcessReviewRequests(store, [res.data])
    return res
  })
}

export const sendCustomerUpdateReviewRequest = (store, customerId) => {
  return axios.post(`/api/customers/${customerId}/review-update-requests`).then(res => {
    batchProcessReviewRequests(store, [res.data])
    return res
  })
}
export const sendCustomerVerificationResend = (store, customerId) => {
  return axios.get(`/api/resend-verification-link-customers/${customerId}`).then(res => {
    return res
  })
}

export const sendReviewRequestReminderByRequestId = (store, { id, payload }) => {
  return axios.post(`/api/review-requests/${id}/reminders`, payload).then(res => {
    batchProcessReviewRequests(store, [res.data])
    return res
  })
}

export const fetchReviewRequestsByCompanyId = (store, id) => {
  return axios.get(`/api/companies/${id}/review-requests`).then(res => {
    batchProcessReviewRequests(store, res.data)
    return res
  })
}

export const fetchReviewRequestById = (store, id) => {
  return axios.get(`/api/review-requests/${id}`).then(res => {
    batchProcessReviewRequests(store, [res.data])
    return res
  })
}

export const deleteReviewRequest = (store, request) => {
  return axios.delete(`/api/review-requests/${request.id}`).then(res => {
    store.commit(mutations.DELETE_REVIEW_REQUEST, request)
    return res
  })
}
