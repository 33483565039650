import { find } from 'lodash'

export const allLeads = state => state.items
export const leadById = state => id => find(state.items, lead => lead.id === id)
export const timelineResume = state => state.timeline_resume.data
export const timelineResumeDetail = state => state.timeline_resume.detail_data
export const timelineResumeMediumDetail = state => state.timeline_resume.medium_data
export const pendingLeadsInbox = state => state.pending_leads
export const instantConnectStatus = state => state.instant_connect_status
export const manualCrmForwardingStatus = state => state.manual_crm_forwarding_status
export const automaticCrmForwardingStatus = state => state.automatic_crm_forwarding_status
