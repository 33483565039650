import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'
import {setAutomaticCrmForwardingStatus} from "../leads/actions.js"

export const batchProcessAccessGrants = (store, oauthAccessGrantData) => {
  let reduced = reduce(oauthAccessGrantData, (sum, client) => {
    sum.oauthAccessGrants.push(client)
    return sum
  }, { oauthAccessGrants: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_OAUTH_ACCESS_GRANTS, reduced.oauthAccessGrants)
}

// ============================== USER  ACTIONS========================================
export const fetchUserAuthorizedAccountsData = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  let route = `/api/dashboard/passport/user-authorized-accounts`
  if (query) {
    route += `?${query}`
  }
  return axios.get(route).then(res => {
    return res
  })
}

export const fetchCompanyAuthorizedAccountsDataById = (store, id) => {
  return axios.get(`/api/dashboard/passport/company-authorized-accounts/${id}`).then(res => {
    let tokens = res?.data?.tokens || []
    setAutomaticCrmForwardingStatus(store, tokens.length > 0)
    return res
  })
}

// ============================== ADMIN  ACTIONS========================================
export const destroyOauthAccessGrant = (store, { id }) => {
  return axios.delete(`/api/admin/passport/access-tokens/${id}`).then(res => {

    store.commit(mutations.DELETE_OAUTH_ACCESS_GRANT, { id })
    return res
  })
}

export const fetchOauthAccessGrants = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/passport/access-tokens?${query}`).then(res => {
    batchProcessAccessGrants(store, res.data.data)
    return res
  })
}

export const fetchOauthAccessGrantById = (store, id) => {
  return axios.get(`/api/admin/passport/access-tokens/${id}`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_OAUTH_ACCESS_GRANTS, [res.data])
    return res
  })
}
