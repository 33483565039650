import axios from 'axios'
import { reduce, isEmpty, omit } from 'lodash'
import { batchProcessUsers } from '../users/actions'
import * as mutations from '../../mutation-types'

export const batchProcessQuizzes = (store, quizzes) => {
  let reduced = reduce(quizzes, (sum, quiz) => {
    if (quiz.user) sum.users[quiz.user.id] = quiz.user
    delete quiz.user

    sum.quizzes.push(quiz)
    return sum
  }, { users: {}, quizzes: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_QUIZZES, reduced.quizzes)
}

export const fetchQuizzes = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/quizzes?${query}`).then(res => {
    batchProcessQuizzes(store, res.data.data)
    return res
  })
}

export const destroyQuiz = (store, { id }) => {
  return axios.delete(`/api/quizzes/${id}`).then(res => {
    store.commit(mutations.DELETE_QUIZ, { id })
    return res
  })
}

export const updateQuiz = (store, quiz) => {
  //let payload = omit(quiz, ['id'])
  let payload = JSON.parse(JSON.stringify(quiz))
  delete payload.id
  return axios.put(`/quizzes/${quiz.id}`, payload)
    .then(res => {
      batchProcessQuizzes(store, [res.data])
      return res
    })
}
export const updateRFPQuiz = (store, quiz) => {
  let payload = omit(quiz, ['id'])
  return axios.put(`/api/rfp-quizzes/${quiz.id}`, payload)
    .then(res => {
      batchProcessQuizzes(store, [res.data])
      return res
    })
}

export const resendQuiz = (store, { id, email }) => {
  return axios.post(`/api/quizzes/${id}/resends`, { email })
}
export const resendRFPQuiz = (store, { id, email }) => {
  return axios.post(`/api/rfp-quizzes/${id}/resends`, { email })
}

export const validateEmail = (store, data) => {
  return axios.post(`/api/lead-requests/brite-verify-email`, {email: data})
}

export const validatePhone = (store, data) => {
  return axios.post(`/api/lead-requests/brite-verify-phone`, {phone: data})
}
