import * as types from '../../mutation-types'
import { addOrUpdateItems, removeItem } from '../../helpers'

export default {
  [types.BATCH_ADD_OR_UPDATE_FEATURES_GROUP]: (state, featuresGroup) => addOrUpdateItems(state, featuresGroup),
  [types.GET_FEATURE_GROUP_FEATURES]: (state, featuresGroupFeatures) => { state.features = featuresGroupFeatures },
  [types.GET_FEATURES_SEARCH]: (state, featuresSearch) => { state.featuresSearch = featuresSearch },
  [types.SYNC_FEATURES_GROUP_FEATURES]: (state) => { state = { ...state } },
  [types.UPDATE_SORT_FEATURE_GROUPS]: (state) => { state = { ...state } },
  [types.DELETE_FEATURE_GROUP]: (state, featureGroup) => removeItem(state, featureGroup),
  [types.MASS_DELETE_FEATURE_GROUP]: (state, featureGroups) => featureGroups.map(featureGroup => removeItem(state, featureGroup))
}
