import axios from 'axios'

export const searchTechStackOverviewDetail= (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')

  return axios.get(`/api/admin/tech-stack-overview/find-product-by-category?${query}`).then(res => {
    return res.data.data
  })
}

export const setTechStackOverviewDetail= (store, { hotelId, productId }) => {
  return axios.post(`/api/admin/tech-stack-overview/set-detail/${hotelId}?product_id=${productId}`).then(res => {
    return res.data.data
  })
}

export const removeTechStackOverviewDetail= (store, { hotelId, productId }) => {
  return axios.post(`/api/admin/tech-stack-overview/remove-detail/${hotelId}?product_id=${productId}`).then(res => {
    return res.data.data
  })
}

export const fetchTechStackOverviewDetail= (store, { hotelId }) => {
  return axios.get(`/api/admin/tech-stack-overview/get-detail/${hotelId}`).then(res => {
    return res.data
  })
}

export const fetchTechStackOverviewHotelAttributesApplyTo = () => {
  return axios.get(`/api/admin/tech-stack-overview/hotel-attributes-apply-to`).then(res => {
    return res.data
  })
}

export const saveTechStackOverviewHotelAttributesApplyTo = (store, options) => {
  return axios.post(`/api/admin/tech-stack-overview/hotel-attributes-apply-to`, options).then(res => {
    return res.data
  })
}

export const fetchTechStackOverviewHotelAttributesTagMapping = () => {
  return axios.get(`/api/admin/tech-stack-overview/hotel-attributes-tag-mapping`).then(res => {
    return res.data
  })
}

export const deleteTechStackOverviewHotelAttributesTagMapping = (store, id) => {
  return axios.delete(`/api/admin/tech-stack-overview/hotel-attributes-tag-mapping/${id}`).then(res => {
    return res.data
  })
}
