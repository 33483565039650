import axios from 'axios'
import * as mutations from '../../mutation-types'

// ============================== ADMIN  ACTIONS========================================
export const destroyIntegration = (store, { id }) => {
  return axios.delete(`/api/admin/company-integrations/${id}`).then(res => {
    store.commit(mutations.DELETE_INTEGRATION, { id })
    return res
  })
}

export const restoreIntegration = (store, { id }) => {
  return axios.post(`/api/admin/company-integrations/${id}/restore`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_INTEGRATIONS, { id })
    return res
  })
}

export const fetchIntegrations = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => {
    if (typeof val !== 'object') {
      return `${key}=${val}`
    }

    if (Array.isArray(val)) {
      return val.map(item => `${key}[]=${item}`).join('&')
    }

    return Object.entries(val).map(([k, v]) => `${key}[${k}]=${v}`).join('&')
  }).join('&')

  return axios.get(`/api/admin/company-integrations?${query}`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_INTEGRATIONS, res.data.data)
    return res
  })
}

export const fetchIntegrationById = (store, id) => {
  return axios.get(`/api/admin/company-integrations/${id}`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_INTEGRATIONS, [res.data])
    return res
  })
}

export const updateIntegrationById = (store, { id, data }) => {
  return axios.put(`/api/admin/company-integrations/${id}`, data).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_INTEGRATIONS, [res.data])
    return res
  })
}

export const createIntegration = (store, data) => {
  return axios.post(`/api/admin/company-integrations`, data).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_INTEGRATIONS, [res.data])
    return res
  })
}

// ============================== DASHBOARD ACTIONS========================================

export const createCompanyIntegration = (store, { originCompanyId, targetCompanyId }) => {
  return axios.post(`/api/companies/${originCompanyId}/integrations`, { company_id: targetCompanyId }).then(res => {
    store.commit(mutations.ADD_SINGLE_INTEGRATION, res.data)
    return res
  })
}

export const updateCompanyIntegration = (store, { originCompanyId, integrationId, data }) => {
  return axios.patch(`/api/companies/${originCompanyId}/integrations/${integrationId}`, data).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_INTEGRATIONS, [res.data])
    return res
  })
}

export const requestIntegrationVerification = (store, { originCompanyId, integrationId }) => {
  return axios.post(`/api/companies/${originCompanyId}/integrations/${integrationId}/verification-requests`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_INTEGRATIONS, [res.data])
    return res
  })
}

export const fetchCompanyIntegrationsById = (store, { originCompanyId, integrationId }) => {
  return axios.get(`/api/companies/${originCompanyId}/integrations/${integrationId}`).then(res => {
    return res
  })
}

export const fetchCompanyIntegrations = (store, { originCompanyId, options = {} }) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/companies/${originCompanyId}/integrations?${query}`).then(res => {
    store.commit(mutations.SET_INTEGRATIONS, res.data)
    return res
  })
}

export const deleteCompanyIntegration = (store, { originCompanyId, integrationId }) => {
  return axios.delete(`/api/companies/${originCompanyId}/integrations/${integrationId}`).then(res => {
    store.commit(mutations.DELETE_INTEGRATION, { id: integrationId })
    return res
  })
}
