import axios from 'axios'
import { reduce, isEmpty, map } from 'lodash'
import { batchProcessUsers } from '../users/actions'
import { batchProcessPostClaims } from '../post-claims/actions'
import { batchProcessCategories } from '../categories/actions'
import { batchProcessProducts } from '../products/actions'
import * as mutations from '../../mutation-types'

export const batchProcessPosts = (store, posts) => {
  let reduced = reduce(posts, (sum, post) => {
    if (post.user) sum.users[post.user.id] = post.user
    delete post.user

    if (post.claims) sum.claims = [...sum.claims, ...post.claims]
    delete post.claims

    if (post.categories) {
      post.categories = map(post.categories, category => {
        sum.categories[category.id] = category
        return category.id
      })
    }

    if (post.products_mentioned) {
      post.products_mentioned = map(post.products_mentioned, product => {
        sum.products[product.id] = product
        return product.id
      })
    }

    sum.posts.push(post)
    return sum
  }, { users: {}, categories: {}, products: {}, claims: [], posts: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.categories)) batchProcessCategories(store, Object.values(reduced.categories))
  if (!isEmpty(reduced.claims)) batchProcessPostClaims(store, Object.values(reduced.claims))
  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_POSTS, reduced.posts)
}

export const fetchCompanyPosts = (store, company) => {
  return axios.get(`/api/dashboard/guest-posts?company_id=${company.id}`).then(res => {
    batchProcessPosts(store, res.data)
    return res
  })
}

export const fetchPosts = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/posts?${query}`).then(res => {
    batchProcessPosts(store, res.data.data)
    return res
  })
}

export const destroyPost = (store, { id }) => {
  return axios.delete(`/api/posts/${id}`).then(res => {
    store.commit(mutations.DELETE_POST, { id })
    return res
  })
}

export const createPost = (store, data) => {
  return axios.post(`/api/posts`, data).then(res => {
    batchProcessPosts(store, [res.data])
    return res
  })
}

export const updatePostById = (store, { id, data }) => {
  return axios.put(`/api/posts/${id}`, data).then(res => {
    batchProcessPosts(store, [res.data])
    return res
  })
}

export const fetchPostById = (store, id) => {
  return axios.get(`/api/posts/${id}`).then(res => {
    batchProcessPosts(store, [res.data])
    return res
  })
}

export const fetchContentTypes = () => {
  return axios.get(`/api/posts/content-types`)
}

export const fetchPostsLight = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/posts/light?${query}`).then(res => {
    batchProcessPosts(store, res.data.data)
    return res
  })
}
