import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessRefreshGrants = (store, oauthRefreshGrantData) => {
  let reduced = reduce(oauthRefreshGrantData, (sum, client) => {
    sum.oauthRefreshGrants.push(client)
    return sum
  }, { oauthRefreshGrants: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_OAUTH_REFRESH_GRANTS, reduced.oauthRefreshGrants)
}

// ============================== ADMIN  ACTIONS========================================
export const destroyOauthRefreshGrant = (store, { id }) => {
  return axios.delete(`/api/admin/passport/refresh-tokens/${id}`).then(res => {

    store.commit(mutations.DELETE_OAUTH_REFRESH_GRANT, { id })
    return res
  })
}

export const fetchOauthRefreshGrants = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/passport/refresh-tokens?${query}`).then(res => {
    batchProcessRefreshGrants(store, res.data.data)
    return res
  })
}

export const fetchOauthRefreshGrantById = (store, id) => {
  return axios.get(`/api/admin/passport/refresh-tokens/${id}`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_OAUTH_REFRESH_GRANTS, [res.data])
    return res
  })
}
