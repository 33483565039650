import axios from 'axios'
import { isEmpty, map, reduce } from 'lodash'
import { batchProcessCompanies } from '../companies/actions'
import { batchProcessProducts } from '../products/actions'
import { batchProcessReviews } from '../reviews/actions'
import * as mutations from '../../mutation-types'

export const batchProcessUsers = (store, users) => {
  let reduced = reduce(users, (sum, user) => {
    if (user.companies) {
      user.companies = map(user.companies || [], company => {
        sum.companies[company.id] = company
        return company.id
      })
    }
    if (user.endorsed_companies) {
      user.endorsed_companies = map(user.endorsed_companies || [], company => {
        sum.companies[company.id] = company
        return company.id
      })
    }
    if (user.used_products) {
      user.used_products = map(user.used_products || [], product => {
        sum.products[product.id] = product
        return product.id
      })
    }

    if (user.reviews) {
      user.reviews = map(user.reviews || [], review => {
        sum.reviews[review.id] = review
        return review.id
      })
    }

    sum.users.push(user)
    return sum
  }, { users: [], companies: {}, products: {}, reviews: {} })

  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))
  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))
  if (!isEmpty(reduced.reviews)) batchProcessReviews(store, Object.values(reduced.reviews))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_USERS, reduced.users)
  return users
}

export const fetchMe = store => {
  return axios.get(`/api/user`).then(res => {
    batchProcessUsers(store, [res.data])
    return res
  })
}

export const resendRequestEmailNotificationToMe = (store, data) => {
  return axios.get(`/api/user/resend-request-email-change-notification`, data).then(res => {
    return res
  })
}

export const updateMe = (store, data) => {
  return axios.post(`/api/user`, data).then(res => {
    batchProcessUsers(store, [res.data])
    return res
  })
}

export const fetchUsers = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/users?${query}`).then(res => {
    batchProcessUsers(store, res.data.data)
    return res
  })
}

export const fetchUserById = (store, id) => {
  return axios.get(`/api/users/${id}`).then(res => {
    batchProcessUsers(store, [res.data])
    return res
  })
}

export const updateUserById = (store, { id, data }) => {
  return axios.patch(`/api/users/${id}`, data).then(res => {
    batchProcessUsers(store, [res.data])
    return res
  })
}

export const updateUsersByIds = (store, { users }) => {
  return axios.put(`/api/users/mass/update`, { users: users }).then(res => {
    batchProcessUsers(store, res.data)
    return res
  })
}

export const createUser = (store, data) => {
  return axios.post(`/api/users`, data).then(res => {
    batchProcessUsers(store, [res.data])
    return res
  })
}

export const destroyUser = (store, { id }) => {
  return axios.delete(`/api/users/${id}`).then(res => {
    store.commit(mutations.DELETE_USER, { id })
    return res
  })
}

export const removeUserFromCompany = (store, { companyId, userId }) => {
  return axios.delete(`/api/companies/${companyId}/users/${userId}`).then(res => {
    batchProcessCompanies(store, [res.data])
    return res
  })
}

export const promoteUserInCompany = (store, { companyId, userId }) => {
  return axios.post(`/api/companies/${companyId}/admins`, { user_id: userId }).then(res => {
    batchProcessCompanies(store, [res.data])
    return res
  })
}

export const demoteUserInCompany = (store, { companyId, userId }) => {
  return axios.delete(`/api/companies/${companyId}/admins/${userId}`).then(res => {
    batchProcessCompanies(store, [res.data])
    return res
  })
}
export const checkIpIsVendor = (store) => {
  return axios.post(`/api/users/is-vendor`).then(res => {
    return res.data
  })
}

export const userSyncCompanies = (store, { userId, data }) => {
  return axios.post(`/api/users/${userId}/sync-companies`, data).then(res => {
    batchProcessCompanies(store, [res.data])
    return res
  })
}
