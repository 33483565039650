import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

let state = {
  googlePlacesLookup: {
    selected: null,
    items: [],
    loading: true,
  },
  hotelSize: {
    selected: null,
    items: [],
    loading: true,
  },
  propertySize: {
    selected: null,
    items: [],
    loading: true,
  },
  country: {
    selected: null,
    items: [],
    loading: true,
  },
  region: {
    selected: null,
    items: [],
    loading: true,
  },
  category: {
    selected: null,
    items: [],
    loading: true,
  },
  pmsOrHms: {
    selected: null,
    items: [],
    loading: false,
  },
  result: {
    items: [],
    loading: false,
    error: false,
  }
}

export default { state, actions, getters, mutations }
