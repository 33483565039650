import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessScreenshots = (store, screenshots) => {
  let reduced = reduce(screenshots, (sum, screenshot) => {
    sum.screenshots.push(screenshot)
    return sum
  }, { screenshots: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_SCREENSHOTS, reduced.screenshots)
}

export const fetchScreenshotsByProductId = (store, id) => {
  return axios.get(`/api/products/${id}/screenshots`).then(res => {
    batchProcessScreenshots(store, res.data)
    return res
  })
}

export const createScreenshotsByProductId = (store, { id, data }) => {
  return axios.post(`/api/products/${id}/screenshots`, data).then(res => {
    batchProcessScreenshots(store, res.data)
    return res
  })
}

export const createDemoVideosByProductId = (store, { id, data }) => {
  return axios.post(`/api/products/${id}/demo-videos`, data).then(res => {
    batchProcessScreenshots(store, res.data)
    return res
  })
}

export const updateScreenshotsPriority = (store, { productId, ids }) => {
  return axios.put(`/api/products/${productId}/screenshots/priority`, { ids: ids }).then(res => {
    batchProcessScreenshots(store, res.data)
  })
}

export const destroyScreenshot = (store, screenshot) => {
  return axios.delete(`/api/products/${screenshot.product_id}/screenshots/${screenshot.id}`).then(() => {
    store.commit(mutations.DELETE_SCREENSHOT, { id: screenshot.id })
  })
}