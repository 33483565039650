import axios from 'axios'
import { reduce, isEmpty } from 'lodash'
import { batchProcessUsers } from '../users/actions'
import * as mutations from '../../mutation-types'

export const batchProcessDownloads = (store, downloads) => {
  let reduced = reduce(downloads, (sum, download) => {
    if (download.user) sum.users[download.user.id] = download.user
    delete download.user

    sum.downloads.push(download)
    return sum
  }, { users: {}, downloads: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_DOWNLOAD, reduced.downloads)
}

export const fetchDownloadsByCompanyId = (store, { id, options }) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/companies/${id}/downloads?${query}`).then(res => {
    batchProcessDownloads(store, res.data.data)
    return res
  })
}

export const deleteDownloadById = (store, { companyId, id }) => {
  return axios.delete(`/api/companies/${companyId}/downloads/${id}`)
}
