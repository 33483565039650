import axios from 'axios'
import * as mutations from '../../mutation-types'

export const fetchFitScoreHotel= (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  store.state.googlePlacesLookup.loading = true

  return axios.get(`/api/fit-score/hotelAutocomplete?${query}`).then(res => {
    store.commit(mutations.FIT_SCORE_UPDATE_GOOGLE_PLACES_LOOKUP_LIST, res.data.data)
  }).finally(() => {
    store.state.googlePlacesLookup.loading = false
  })
}

export const fetchFitScoreHotelSize= (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  store.state.hotelSize.loading = true

  return axios.get(`/api/fit-score/hotelSizeAutocomplete?${query}`).then(res => {
    store.commit(mutations.FIT_SCORE_UPDATE_HOTEL_SIZE_LIST, res.data.data)
  }).finally(() => {
    store.state.hotelSize.loading = false
  })
}

export const fetchFitScorePropertySize= (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  store.state.propertySize.loading = true

  return axios.get(`/api/fit-score/propertySizeAutocomplete?${query}`).then(res => {
    store.commit(mutations.FIT_SCORE_UPDATE_PROPERTY_SIZE_LIST, res.data.data)
  }).finally(() => {
    store.state.propertySize.loading = false
  })
}

export const fetchFitScoreCountry= (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  store.state.country.loading = true

  return axios.get(`/api/fit-score/countryAutocomplete?${query}`).then(res => {
    store.commit(mutations.FIT_SCORE_UPDATE_COUNTRY_LIST, res.data.data)
  }).finally(() => {
    store.state.country.loading = false
  })
}

export const fetchFitScoreRegion= (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  store.state.region.loading = true

  return axios.get(`/api/fit-score/regionAutocomplete?${query}`).then(res => {
    store.commit(mutations.FIT_SCORE_UPDATE_REGION_LIST, res.data.data)
  }).finally(() => {
    store.state.region.loading = false
  })
}

export const fetchFitPmsOrHms= (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  store.state.pmsOrHms.loading = true

  return axios.get(`/api/fit-score/pmsOrHmsAutocomplete?${query}`).then(res => {
    store.commit(mutations.FIT_SCORE_UPDATE_PMS_OR_HMS_LIST, res.data.data)
  }).finally(() => {
    store.state.pmsOrHms.loading = false
  })
}

export const fetchFitScoresCategories = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  store.state.category.loading = true

  return axios.get(`/api/fit-score/categoryAutocomplete?${query}`).then(res => {
    store.commit(mutations.FIT_SCORE_UPDATE_CATEGORY_LIST, res.data.data)
  }).finally(() => {
    store.state.category.loading = false
  })
}

export const calculateFitScore = (store, options) => {
  store.state.result.loading = true
  store.state.result.error = false
  options.form.customHotel = 109468

  return axios.post(`/api/fit-score/get-fit-score`, options.form).then(res => {
    store.commit(mutations.FIT_SCORE_UPDATE_RESULTS_LIST, res.data)
  }).catch(err => {
    console.log(err.response.data.message)
    store.state.result.error = err.response.data.message
  })
    .finally(() => {
    store.state.result.loading = false
  })
}

// No store

export const fetchRegionsProximity = (store, options) => {
  return axios.get(`/api/admin/market-proximities`)
}

export const saveRegionsProximity = (store, options) => {
  return axios.put(`/api/admin/market-proximities`, options)
}

export const fetchFitScoreAttributes = (store, options) => {
  return axios.get(`/api/admin/fit-score-attributes`)
}

export const saveFitScoreAttributes = (store, options) => {
  return axios.post(`/api/admin/fit-score-attributes`, options)
}

export const saveFitScoreSettings = (store, options) => {
  return axios.put(`/api/settings-git-score-settings`, options)
}
export const refreshFitScore = (store, hotelId) => {
  return axios.put(`/api/admin/refresh-fit-score`, { hotel_id: hotelId })
}

export const refreshFitScoreAllHotels = (store) => {
  return axios.put(`/api/admin/refresh-fit-score-all-hotels`)
}