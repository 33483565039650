import axios from 'axios'
import * as mutations from '../../mutation-types'

export const batchProcessEmailTemplates = (store, emailTemplates) => {
  store.commit(mutations.BATCH_ADD_OR_UPDATE_EMAIL_TEMPLATES, emailTemplates)
}

export const fetchCompanyEmailTemplateById = (store, {id, company_id}) => {
  return axios.get(`/api/companies/${company_id}/email-templates/${id}`).then(res => {
    batchProcessEmailTemplates(store, [res.data])
    return res
  })
}

export const fetchUserEmailTemplatesByCompanyId = (store, company) => {
  return axios.get(`/api/companies/${company.id}/email-templates`).then(res => {
    batchProcessEmailTemplates(store, res.data)
    return res
  })
}

export const updateUserEmailTemplatesByCompanyId = (store, { company, emailTemplate }) => {
  return axios.put(`/api/companies/${company.id}/email-templates/${emailTemplate.id}`, emailTemplate).then(res => {
    batchProcessEmailTemplates(store, [res.data])
    return res
  })
}