import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPostComponentPpcTopList = (store, postComponentPpcTopLists) => {
  let reduced = reduce(postComponentPpcTopLists, (sum, postComponentPpcTopList) => {
    sum.postComponentPpcTopLists.push(postComponentPpcTopList)
    return sum
  }, { postComponentPpcTopLists: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_POST_COMPONENT_PPC_TOP_LISTS, reduced.postComponentPpcTopLists)
}

export const destroyPostComponentPpcTopList = (store, { id }) => {
  return axios.delete(`/api/admin/posts/ppc-top-lists/${id}`).then(res => {
    store.commit(mutations.DELETE_POST_COMPONENT_PPC_TOP_LIST, { id })
    return res
  })
}

export const createPostComponentPpcTopList = (store, data) => {
  return axios.post(`/api/admin/posts/ppc-top-lists`, data).then(res => {
    batchProcessPostComponentPpcTopList(store, [res.data])
    return res
  })
}

export const updatePostComponentPpcTopListById = (store, data) => {
  return axios.put(`/api/admin/posts/ppc-top-lists/${data.id}`, data).then(res => {
    batchProcessPostComponentPpcTopList(store, [res.data])
    return res
  })
}
