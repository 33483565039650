import axios from 'axios'
import { reduce, isEmpty } from 'lodash'
import { batchProcessUsers } from '../users/actions'
import { batchProcessReviews } from '../reviews/actions'
import * as mutations from '../../mutation-types'

export const batchProcessReviewResponses = (store, reviewResponses) => {
  let reduced = reduce(reviewResponses, (sum, reviewResponse) => {
    if (reviewResponse.author) sum.users[reviewResponse.author.id] = reviewResponse.author
    delete reviewResponse.author

    if (reviewResponse.review) sum.reviews[reviewResponse.review.id] = reviewResponse.review
    delete reviewResponse.review

    sum.reviewResponses.push(reviewResponse)
    return sum
  }, { reviews: {}, users: {}, reviewResponses: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.reviews)) batchProcessReviews(store, Object.values(reduced.reviews))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_REVIEW_RESPONSES, reduced.reviewResponses)
}

export const createReviewResponseByReviewId = (store, { id, text }) => {
  return axios.post(`/api/reviews/${id}/response`, { text }).then(res => {
    batchProcessReviewResponses(store, [res.data])
    return res
  })
}