import axios from 'axios'
import { reduce, isEmpty, map } from 'lodash'
import { batchProcessUsers } from '../users/actions'
import * as mutations from '../../mutation-types'
import { batchProcessCompanies } from '../companies/actions'
import { batchProcessDepartments } from '../departments/actions'

export const batchProcessJobs = (store, jobs) => {
  let reduced = reduce(jobs, (sum, job) => {
    if (job.company) {
      sum.companies[job.company.id] = job.company
    }

    if (job.department) {
      sum.departments[job.department.id] = job.department
    }

    if (job.users) {
      job.users = map(job.users, user => {
        sum.users[user.id] = user
        return user.id
      })
    }

    sum.jobs.push(job)
    return sum
  }, { users: {}, companies: {}, jobs: [], departments: {} })

  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))
  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.departments)) batchProcessDepartments(store, Object.values(reduced.departments))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_JOBS, reduced.jobs)
}
export const fetchCompanyJobs = (store, company) => {
  return axios.get(`/api/companies/${company.id}/jobs`).then(res => {
    batchProcessJobs(store, res.data)
    return res
  })
}

export const fetchJobs = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/jobs?${query}`).then(res => {
    batchProcessJobs(store, res.data.data)
    return res
  })
}

export const fetchJobsForSearch = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/search-jobs?${query}`).then(res => {
    batchProcessJobs(store, res.data.data)
    return res
  })
}

export const destroyJobs = (store, { id }) => {
  return axios.delete(`/api/jobs/${id}`).then(res => {
    store.commit(mutations.DELETE_POST, { id })
    return res
  })
}

export const createJobs = (store, data) => {
  return axios.post(`/api/jobs`, data).then(res => {
    batchProcessJobs(store, [res.data])
    return res
  })
}

export const createCompanyJob = (store, { companyId, data }) => {
  return axios.post(`/api/companies/${companyId}/jobs`, data).then(res => {
    batchProcessJobs(store, [res.data])
    return res
  })
}

export const updateJobsById = (store, { id, data }) => {
  return axios.put(`/api/jobs/${id}`, data).then(res => {
    batchProcessJobs(store, [res.data])
    return res
  })
}

export const fetchJobsById = (store, id) => {
  return axios.get(`/api/jobs/${id}`).then(res => {
    batchProcessJobs(store, [res.data])
    return res
  })
}