import { find, filter } from 'lodash'

export const allCategories = state => state.items

export const rootCategories = state => filter(state.items, category => !category.parent_id)

export const subcategoriesByCategoryId = state => id => filter(state.items, category => category.parent_id === id)

export const categoryById = state => id => find(state.items, category => category.id === id)

export const subCategories = state => filter(state.items, category => category.parent_id)

export const categoriesByIds = state => ids => filter(state.items, category => {
  if (Array.isArray(ids)) {
    return ids.indexOf(category.id) > -1
  }
  return -1
})
