import * as types from '../../mutation-types'
import Vue from "vue";

export default {
  [types.ADD_SEARCH_PRODUCTS]: (state, products) => {
    Vue.set(state, 'products', products)
  },
  [types.ADD_SEARCH_ARTICLES]: (state, articles) => {
    Vue.set(state, 'articles', articles)
  },
  [types.ADD_SEARCH_CATEGORIES]: (state, categories) => {
    Vue.set(state, 'categories', categories)
  },
  [types.ADD_SEARCH_RESOURCES]: (state, resources) => {
    Vue.set(state, 'resources', resources)
  }
}
