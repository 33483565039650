import axios from 'axios'
import { uniq, map, reduce, isEmpty, each } from 'lodash'
import * as mutations from '../../mutation-types'
import { batchProcessProducts } from '../products/actions'
import { batchProcessCompanies } from '../companies/actions'
import { batchProcessCustomers } from '../customers/actions'
import { batchProcessUsers } from '../users/actions'
import { batchProcessReviewRequests } from '../review-requests/actions'

export const batchProcessCampaigns = (store, campaigns) => {
  let reduced = reduce(campaigns, (sum, campaign) => {
    if (campaign.products) {
      each(campaign.products || [], product => sum.products[product.id] = product);
    }
    delete campaign.products

    if (campaign.company) {
      sum.companies[campaign.company.id] = campaign.company;
    }
    delete campaign.company

    if (campaign.sender) {
      sum.users[campaign.sender.id] = campaign.sender;
    }
    delete campaign.sender

    if (campaign.review_requests) {
      each((campaign.review_requests || []), review_request => sum.review_requests[review_request.id] = review_request);
    }
    delete campaign.review_requests

    sum.campaigns.push(campaign)

    return sum
  }, { campaigns: [], products: {}, review_requests: {}, companies: {}, users: {}})

  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))
  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))
  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.review_requests)) batchProcessReviewRequests(store, Object.values(reduced.review_requests))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_CAMPAIGN, reduced.campaigns)
  return campaigns
}

export const createCampaignByCompanyId = (store, {company_id, params}) => {
  return axios.post(`/api/companies/${company_id}/campaign`, params).then(res => {
    batchProcessCustomers(store, res.data)
    return res
  })
}

export const fetchCampaigns = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/campaigns?${query}`).then(res => {
    batchProcessCampaigns(store, res.data.data)
    return res
  })
}

export const fetchCampaignsByCompanyId = (store, {id, options}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/companies/${id}/campaign?${query}`).then(res => {
    batchProcessCampaigns(store, res.data.data)
    return res
  })
}

export const fetchCampaignNamesByCompanyId = (store, {id, options}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/companies/${id}/campaign-names?${query}`).then(res => {
    return res?.data?.data
  })

}
