import Vue from 'vue'
import { findIndex, map, each } from 'lodash'

export const addOrUpdateItems = (state, items, key = 'id', node = 'items') => {
  each(items, (item) => addOrUpdateItem(state, item, key, node))
}

export const addOrUpdateItem = (state, item, key = 'id', node = 'items') => {
  if (itemExists(state[node], item, key)) {
    Vue.set(state, node, listWithUpdatedItem(state[node], item, key))
  } else {
    Vue.set(state, node, [...state[node], item])
  }
}

export const removeItem = (state, item, key = 'id', node = 'items') => {
  let index = findIndex(state[node], existing => existing[key] === item[key])
  if (index > -1) {
    state[node].splice(index, 1)
    Vue.set(state, node, state[node])
  }
}

/*
 * Updates all attributes in the existing object of the store with the new in_Object
 * attributes. The key attribute must be defined in the object.
*/
export const updateItemAttribute = (state, inObject, key = 'id', node = 'items' ) => {
  if (typeof inObject !== 'undefined' &&
      typeof inObject[key] !== 'undefined') {
    const currentItem = state[node].find((item)=> item[key] === inObject[key])
    if (typeof currentItem !== 'undefined') {
      let item = Object.assign({}, currentItem)
      for (let [objKey, objValue] of Object.entries(inObject)) {
        item[objKey] = objValue
      }
      addOrUpdateItem(state, item, key, node)
      return true // make the method easier to unit test
    }
  }
  return false // make the method easier to unit test
}

const listWithUpdatedItem = (items, item, key) => {
  return map(items, existing => {
    if (existing[key] === item[key]) {
      return Object.assign({}, existing, item)
    }
    return existing
  })
}

const itemExists = (items, item, key) => {
  return findIndex(items, existing => existing[key] === item[key]) > -1
}

export const addBusinessRules = (state, items, key = 'id', node = 'items') => {
  Vue.set(state, node, items)
}


export const updateTabs = (state, tabs) => {
  Object.keys(tabs).forEach(key => {
    Vue.set(state.tabs, key, tabs[key]);
  });
}