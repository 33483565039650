import axios from 'axios'
import { map, reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPropertySyzes = (store, propertySizes) => {
  let reduced = reduce(propertySizes, (sum, propertySize) => {
    sum.propertySizes.push(propertySize)
    return sum
  }, { propertySizes: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_PROPERTY_SIZES, reduced.propertySizes)
}

export const fetchPropertySizes = (store, options) => {
  return axios.get(`/api/admin/hotels/property-sizes`, { params: options }).then(res => {
    batchProcessPropertySyzes(store, res.data)
    return res
  })
}

export const fetchPropertySizeById = (store, id) => {
  return axios.get(`/api/admin/hotels/property-sizes/${id}`).then(res => {
    batchProcessPropertySyzes(store, [res.data])
    return res
  })
}

export const fetchAllPropertySizesRoomCountOptions = (store, options) => {
  return axios.get(`/api/admin/hotels/property-sizes-room-count-options`).then(res => {
    return res
  })
}

export const updatePropertySizeById = (store, { id, data }) => {
  return axios.put(`/api/admin/hotels/property-sizes/${id}`, data).then(res => {
    batchProcessPropertySyzes(store, [res.data])
    return res
  })
}

export const createPropertySize = (store, data) => {
  return axios.post(`/api/admin/hotels/property-sizes/`, data).then(res => {
    batchProcessPropertySyzes(store, [res.data])
    return res
  })
}

export const destroyPropertySize = (store, { id }) => {
  return axios.delete(`/api/admin/hotels/property-sizes/${id}`).then(res => {
    store.commit(mutations.DELETE_PROPERTY_SIZE, { id })
    return res
  })
}
