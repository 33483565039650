import axios from 'axios'
import { reduce, isEmpty } from 'lodash'
import { batchProcessUsers } from '../users/actions'
import * as mutations from '../../mutation-types'

export const batchProcessInternalNotes = (store, internalNotes) => {
  let reduced = reduce(internalNotes, (sum, internalNote) => {
    if (internalNote.user) sum.users[internalNote.user.id] = internalNote.user
    delete internalNote.user

    sum.internalNotes.push(internalNote)
    return sum
  }, { users: {}, internalNotes: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_INTERNAL_NOTES, reduced.internalNotes)
}

export const createInternalNoteByCustomerId = (store, { id, note }) => {
  return axios.post(`/api/customers/${id}/internal-notes`, { note }).then(res => {
    batchProcessInternalNotes(store, [res.data])
    return res
  })
}
