import axios from 'axios'
import { map, reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessHotelTypes = (store, hotelTypes) => {
  let reduced = reduce(hotelTypes, (sum, hotelType) => {
    sum.hotelTypes.push(hotelType)
    return sum
  }, { hotelTypes: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_HOTEL_TYPES, reduced.hotelTypes)
}

export const fetchHotelTypes = (store, options) => {
  return axios.get(`/api/admin/hotels/hotel-types`, { params: options }).then(res => {
    batchProcessHotelTypes(store, res.data)
    return res
  })
}

export const fetchHotelTypeById = (store, id) => {
  return axios.get(`/api/admin/hotels/hotel-types/${id}`).then(res => {
    batchProcessHotelTypes(store, [res.data])
    return res
  })
}

export const updateHotelTypeById = (store, { id, data }) => {
  return axios.put(`/api/admin/hotels/hotel-types/${id}`, data).then(res => {
    batchProcessHotelTypes(store, [res.data])
    return res
  })
}

export const createHotelType = (store, data) => {
  return axios.post(`/api/admin/hotels/hotel-types/`, data).then(res => {
    batchProcessHotelTypes(store, [res.data])
    return res
  })
}

export const destroyHotelType = (store, { id }) => {
  return axios.delete(`/api/admin/hotels/hotel-types/${id}`).then(res => {
    store.commit(mutations.DELETE_HOTEL_TYPE, { id })
    return res
  })
}
