import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPostComponentSections = (store, postComponentSections) => {
  let reduced = reduce(postComponentSections, (sum, postComponentSection) => {
    sum.postComponentSections.push(postComponentSection)
    return sum
  }, { postComponentSections: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_POST_COMPONENT_SECTIONS, reduced.postComponentSections)
}

export const destroyPostComponentSection = (store, { id }) => {
  return axios.delete(`/api/admin/posts/sections/${id}`).then(res => {
    store.commit(mutations.DELETE_POST_COMPONENT_SECTION, { id })
    return res
  })
}

export const createPostComponentSection = (store, data) => {
  return axios.post(`/api/admin/posts/sections`, data).then(res => {
    batchProcessPostComponentSections(store, [res.data])
    return res
  })
}

export const updatePostComponentSectionById = (store, data) => {
  return axios.put(`/api/admin/posts/sections/${data.id}`, data).then(res => {
    batchProcessPostComponentSections(store, [res.data])
    return res
  })
}
