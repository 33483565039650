import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

let state = {
    items: [],
    apiStatusCounts: {
        needsApiRun: 0,
        apiSuccess: 0,
        apiFailed: 0
    }
}

export default { state, actions, getters, mutations }