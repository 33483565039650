import { find, filter } from 'lodash'

export const allIntegrations = state => state.items
export const integrationById = state => id => find(state.items, review => review.id === id)
export const featuredIntegrations = state => filter(state.items, integration => integration.is_featured)

export const integrationsByProductId = state => id => {
  return filter(state.items, integration => find(integration.products, product => product.id === id))
}

export const verifiedIntegrationsByProductId = state => id => {
  return filter(integrationsByProductId(state)(id), integration => integration.status === 'verified')
}

export const unverifiedIntegrations = state => state.items.reduce((prev, integration) => prev + (integration.status === 'unverified' ? 1 : 0), 0)
