import axios from 'axios'
import { reduce, map, isEmpty } from 'lodash'
import { batchProcessUsers } from '../users/actions'
import { batchProcessCategories } from '../categories/actions'
import { batchProcessProducts } from '../products/actions'
import * as mutations from '../../mutation-types'

export const batchProcessReports = (store, reports) => {
  let reduced = reduce(reports, (sum, report) => {
    if (report.user) sum.users[report.user.id] = report.user
    delete report.user

    if (report.sponsor_product) sum.products[report.sponsor_product.id] = report.sponsor_product
    delete report.sponsor_product

    report.visible_categories = map(report.visible_categories, category => {
      sum.categories[category.id] = category
      return category.id
    })

    sum.reports.push(report)
    return sum
  }, { products: {}, categories: {}, users: {}, reports: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.categories)) batchProcessCategories(store, Object.values(reduced.categories))
  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_REPORTS, reduced.reports)
}

export const fetchReports = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/reports?${query}`).then(res => {
    batchProcessReports(store, res.data.data)
    return res
  })
}

export const fetchReportsAutocomplete = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/reports-autocomplete?${query}`).then(res => {
    batchProcessReports(store, res.data.data)
    return res
  })
}

export const fetchReportsByCompanyId = (store, { id, options }) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/companies/${id}/reports?${query}`).then(res => {
    batchProcessReports(store, res.data.data)
    return res
  })
}

export const fetchReportById = (store, id) => {
  return axios.get(`/api/reports/${id}`).then(res => {
    batchProcessReports(store, [res.data])
    return res
  })
}

export const createReport = (store, report) => {
  return axios.post(`/api/reports`, report).then(res => {
    batchProcessReports(store, [res.data])
    return res
  })
}

export const updateReportById = (store, { id, data }) => {
  return axios.put(`/api/reports/${id}`, data).then(res => {
    batchProcessReports(store, [res.data])
    return res
  })
}

export const destroyReport = (store, { id }) => {
  return axios.delete(`/api/reports/${id}`).then(res => {
    store.commit(mutations.DELETE_REPORT, { id })
    return res
  })
}