import axios from 'axios'
import { reduce, isEmpty, map } from 'lodash'
import { batchProcessCategories } from '../categories/actions'
import { batchProcessProducts } from '../products/actions'
import * as mutations from '../../mutation-types'

export const batchProcessBuyersGuides = (store, buyersGuides) => {
  let reduced = reduce(buyersGuides, (sum, buyersGuide) => {
    if (buyersGuide.category) {
      sum.categories.push(buyersGuide.category)
      buyersGuide.category_id = buyersGuide.category.id
    }
    delete buyersGuide.category

    if (buyersGuide.sponsor_product) {
      sum.products[buyersGuide.sponsor_product.id] = buyersGuide.sponsor_product
    }

    buyersGuide.visible_categories = map(buyersGuide.visible_categories, category => {
      sum.categories[category.id] = category
      return category.id
    })

    delete buyersGuide.sponsor_product

    sum.buyersGuides.push(buyersGuide)
    return sum
  }, { categories: [], buyersGuides: [], products: {} })

  if (!isEmpty(reduced.categories)) batchProcessCategories(store, Object.values(reduced.categories))
  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_BUYERS_GUIDES, reduced.buyersGuides)
}

export const fetchBuyersGuides = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/buyers-guides?${query}`).then(res => {
    batchProcessBuyersGuides(store, res.data.data)
    return res
  })
}

export const fetchBuyersGuideById = (store, id) => {
  return axios.get(`/api/buyers-guides/${id}`).then(res => {
    batchProcessBuyersGuides(store, [res.data])
    return res
  })
}

export const createBuyersGuide = (store, report) => {
  return axios.post(`/api/buyers-guides`, report).then(res => {
    batchProcessBuyersGuides(store, [res.data])
    return res
  })
}

export const updateBuyersGuideById = (store, { id, data }) => {
  return axios.put(`/api/buyers-guides/${id}`, data).then(res => {
    batchProcessBuyersGuides(store, [res.data])
    return res
  })
}

export const destroyBuyersGuide = (store, { id }) => {
  return axios.delete(`/api/buyers-guides/${id}`).then(res => {
    store.commit(mutations.DELETE_REPORT, { id })
    return res
  })
}
