import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPlans = (store, plans) => {
  let reduced = reduce(plans, (result, plan) => {
    plan.price_ids = []
    plan.prices.forEach(price => {
      plan.price_ids.push(price.id)
    })
    result.plans.push(plan)
    return result
  }, { plans: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_BUSINESS_PLANS, reduced.plans)
}
/* Note that this is an admin only function */
export const fetchPlans = (store, condition = 'all') => {
  return axios.get(`/api/membership-plans?condition=${condition}`).then(res => {
    batchProcessPlans(store, res.data)
    return res
  })
}