import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPostComponentTexts = (store, postComponentTexts) => {
  let reduced = reduce(postComponentTexts, (sum, postComponentText) => {
    sum.postComponentTexts.push(postComponentText)
    return sum
  }, { postComponentTexts: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_POST_COMPONENT_TEXTS, reduced.postComponentTexts)
}

export const destroyPostComponentText = (store, { id }) => {
  return axios.delete(`/api/admin/posts/texts/${id}`).then(res => {
    store.commit(mutations.DELETE_POST_COMPONENT_TEXT, { id })
    return res
  })
}

export const createPostComponentText = (store, data) => {
  return axios.post(`/api/admin/posts/texts`, data).then(res => {
    batchProcessPostComponentTexts(store, [res.data])
    return res
  })
}

export const updatePostComponentTextById = (store, data) => {
  return axios.put(`/api/admin/posts/texts/${data.id}`, data).then(res => {
    batchProcessPostComponentTexts(store, [res.data])
    return res
  })
}
