import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

let state = { items: [], timeline_resume: {
    data: {},
    medium_data: {
      time_to_api_email_verification : null,
      time_to_user_email_verification : null,
      time_email_total : null,
      time_admin_approval : null,
      time_to_vendor_inbox : null,
      time_to_first_vendor_purchase : null,
      time_to_last_vendor_purchase : null
    },
    detail_data: [],
  },
  pending_leads: 0,
  instant_connect_status: false,
  manual_crm_forwarding_status: false,
  automatic_crm_forwarding_status: false
}

export default { state, actions, getters, mutations }
