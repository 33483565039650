import axios from 'axios'
import { map, reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessHotelActivityLogs = (store, hotelActivityLogs) => {
  let reduced = reduce(hotelActivityLogs, (sum, hotelActivityLog) => {
    sum.hotelActivityLogs.push(hotelActivityLog)
    return sum
  }, { hotelActivityLogs: [] })
}

export const fetchHotelActivityLogByUser = (store, { id, options}) => {
  return axios.get(`/api/admin/hotel-activity-logs/${id}/by-user`, { params: options }).then(res => {
    batchProcessHotelActivityLogs(store, res.data)
    return res
  })
}

export const fetchHotelActivityLogByHotel = (store, { id, options}) => {
  return axios.get(`/api/admin/hotel-activity-logs/${id}/by-hotel`, { params: options }).then(res => {
    batchProcessHotelActivityLogs(store, res.data)
    return res
  })
}
