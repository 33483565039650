import axios from 'axios'
import { batchProcessProducts } from '../products/actions'
import { reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'
import {batchProcessCompanies} from "../companies/actions";

export const batchProcessCategories = (store, categories) => {
  let reduced = reduce(categories, (sum, category) => {
    if (category.sponsorship && category.sponsorship_product) {
      sum.products[category.sponsorship_product.id] = category.sponsorship_product
      delete category.sponsorship_product
    }

    if (category.parent) {
      sum.categories.push(category.parent)
    }

    sum.categories.push(category)
    return sum
  }, { products: {}, categories: [] })

  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_CATEGORIES, reduced.categories)
}

export const fetchCategories = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/categories?${query}`).then(res => {
    batchProcessCategories(store, res.data.data)
    return res
  })
}

export const fetchCategory = (store, id) => {
  return axios.get(`/api/categories/${id}`).then(res => {
    batchProcessCategories(store, [res.data])
    return res
  })
}

export const fetchAllSimpleCategories = (store, options) => {
  return fetchSimpleCategories(store, { per_page: 9999 })
}
export const fetchSimpleCategories = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/categories/simple?${query}`).then(res => {
    batchProcessCategories(store, res.data.data)
    return res
  })
}

export const updateCategoryById = (store, { id, data }) => {
  return axios.put(`/api/categories/${id}`, data).then(res => {
    batchProcessCategories(store, [res.data])
    return res
  })
}

export const deleteCategoryById = (store, id) => {
  return axios.delete(`/api/categories/${id}`).then(res => {
    store.commit(mutations.DELETE_CATEGORY, id)
    return res
  })
}

export const storeCategory = (store, data) => {
  return axios.post(`/api/categories`, data).then(res => {
    batchProcessCategories(store, [res.data])
    return res
  })
}

export const updateCache = (store, id) => {
  return axios.put(`/api/categories/${id}/forget-cache`).then(res => {
    return res
  })
}
