import axios from 'axios'
import { map, reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessHotelRoomFeatures = (store, hotelRoomFeatures) => {
  let reduced = reduce(hotelRoomFeatures, (sum, hotelRoomFeature) => {
    sum.hotelRoomFeatures.push(hotelRoomFeature)
    return sum
  }, { hotelRoomFeatures: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_HOTEL_ROOM_FEATURES, reduced.hotelRoomFeatures)
}

export const fetchHotelRoomFeatures = (store, options) => {
  return axios.get(`/api/admin/hotel-room-features`, { params: options }).then(res => {
    batchProcessHotelRoomFeatures(store, res.data.data)
    return res
  })
}

export const createHotelRoomFeatures = (store, data) => {
  return axios.post(`/api/admin/hotel-room-features`, data).then(res => {
    batchProcessHotelRoomFeatures(store, [res.data])
    return res
  })
}

