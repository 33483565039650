import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'
import axios from 'axios'

export const batchProcessManageRegistrations = (store, manageRegistrations) => {
  let reduced = reduce(manageRegistrations, (sum, manageRegistration) => {
    sum.manageRegistrations.push(manageRegistration)
    return sum
  }, { manageRegistrations: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_MANAGE_REGISTRATION, reduced.manageRegistrations)
}

export const fetchAwardsManageRegistrations = (store, {id, options}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')

  return axios.get(`/api/admin/awards-years/${id}/manage-registrations?${query}`).then(res => {
    batchProcessManageRegistrations(store, res.data.data)
    return res
  })
}

export const addEntryAwardBalance = (store, companyId) => {
  return axios.post(`/api/admin/manage-registrations/${companyId}/add-entry`).then(res => {
    return res
  })
}
export const registerProduct = (store, {productId, awardId}) => {
  return axios.post(`/api/admin/awards/${awardId}/register/${productId}`).then(res => {
    return res
  })
}

export const autoRegister = (store, awardId) => {
  return axios.post(`/api/admin/awards/${awardId}/auto-register`).then(res => {
    return res
  })
}
export const attachPostToAward = (store, {awardId, postId}) => {
  return axios.post(`/api/admin/awards/${awardId}/post/${postId}`).then(res => {
    return res
  })
}

export const removePostFromAward = (store, {awardId}) => {
  console.log(awardId)
  return axios.post(`/api/admin/awards/${awardId}/removePost`).then(res => {
    return res
  })
}

