import axios from 'axios'
import { map, reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessHotelRoomTypes = (store, hotelRoomTypes) => {
  let reduced = reduce(hotelRoomTypes, (sum, hotelRoomType) => {
    sum.hotelRoomTypes.push(hotelRoomType)
    return sum
  }, { hotelRoomTypes: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_HOTEL_ROOM_TYPES, reduced.hotelRoomTypes)
}

export const fetchHotelRoomTypes = (store, options) => {
  return axios.get(`/api/admin/hotel-room-types`, { params: options }).then(res => {
    batchProcessHotelRoomTypes(store, res.data.data)
    return res
  })
}

export const createHotelRoomTypes = (store, data) => {
  return axios.post(`/api/admin/hotel-room-types`, data).then(res => {
    batchProcessHotelRoomTypes(store, [res.data])
    return res
  })
}

