import axios from 'axios'
import { map, reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessHotelStyles = (store, hotelStyles) => {
  let reduced = reduce(hotelStyles, (sum, hotelStyle) => {
    sum.hotelStyles.push(hotelStyle)
    return sum
  }, { hotelStyles: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_HOTEL_STYLES, reduced.hotelStyles)
}

export const fetchHotelStyles = (store, options) => {
  return axios.get(`/api/admin/hotel-styles`, { params: options }).then(res => {
    batchProcessHotelStyles(store, res.data.data)
    return res
  })
}

export const createHotelStyles = (store, data) => {
  return axios.post(`/api/admin/hotel-styles`, data).then(res => {
    batchProcessHotelStyles(store, [res.data])
    return res
  })
}

