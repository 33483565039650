import axios from 'axios'
import { reduce, isEmpty, map } from 'lodash'
import * as mutations from '../../mutation-types'
import { batchProcessUsers } from '../users/actions'
import { batchProcessCompanies } from '../companies/actions'

export const batchProcessReferrals = (store, referrals) => {
  let reduced = reduce(referrals, (sum, referral) => {
    if (referral.companies) {
      referral.companies = map(referral.companies, company => {
        sum.companies[company.id] = company
        return company.id
      })
    }

    if (referral.users) {
      referral.users = map(referral.users, user => {
        sum.users[user.id] = user
        return user.id
      })
    }

    sum.referrals.push(referral)
    return sum
  }, { users: {}, companies: {}, referrals: [] })

  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))
  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_REFERRALS, reduced.referrals)
}

export const fetchCompanyReferrals = (store, company) => {
  return axios.get(`/api/companies/${company.id}/referrals`).then(res => {
    batchProcessReferrals(store, res.data)
    return res
  })
}

export const createReferrals = (store, data) => {
  return axios.post(`/api/referrals`, data).then(res => {
    batchProcessReferrals(store, [res.data])
    return res
  })
}