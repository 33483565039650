import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'
import axios from 'axios'

export const batchProcessResources = (store, resources) => {
  let reduced = reduce(resources, (sum, resource) => {
    sum.resources.push(resource)
    return sum
  }, { resources: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_RESOURCES, reduced.resources)
}

export const fetchResources = (store, { lang, category, type, page, search }) => {
  let path = '/api/resources'
  if (lang) {
    path = '/api' + lang + '/resources'
  }
  if (category) {
    path += '/' + category
  }
  if (type) {
    path += '?type=' + type
  }
  if (page && !type) {
    path += '?page=' + page
  } else if (page && type) {
    path += '&page=' + page
  } else {}
  if (search && (!type && !page)) {
    path += '?search=' + search
  } else if (search && (type || page)) {
    path += '&search=' + search
  } else {}
  return axios.get(path).then(res => {
    return res
  })
}
