import { reduce, isEmpty } from 'lodash'
import { batchProcessProducts } from '../products/actions'
import * as mutations from '../../mutation-types'
import axios from 'axios'

export const batchProcessProductModules = (store, productModules) => {
  let reduced = reduce(productModules, (sum, productModule) => {
    if (productModule.product) sum.products[productModule.product.id] = productModule.product
    delete productModule.product

    sum.productModules.push(productModule)
    return sum
  }, { products: {}, productModules: [] })

  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_PRODUCT_MODULE, reduced.productModules)
}

export const updateProductModuleById = (store, { id, data }) => {
  return axios.put(`/api/dashboard/product-modules/${id}`, data).then(res => {
    batchProcessProductModules(store, [res.data])
    return res
  })
}

export const createProductModule = (store, { productId, data }) => {
  return axios.post(`/api/dashboard/product-modules`, data).then(res => {
    batchProcessProductModules(store, [res.data])
    return res
  })
}

export const fetchProductModules = (store, { productId }) => {
  return axios.get(`/api/dashboard/product-modules?product_id=${productId}`).then(res => {
    return res.data
  })
}

export const destroyProductModule = (store, { id }) => {
  return axios.delete(`/api/dashboard/product-modules/${id}`).then(res => {
    store.commit(mutations.DELETE_PRODUCT_MODULE, { id })
    return res
  })
}
