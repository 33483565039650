import axios from 'axios'
import { reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

export const fetchContentDownloads = (store, options) => {
  const query = new URLSearchParams(options).toString()

  return axios.get(`/api/admin/content-downloads?${query}`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_CONTENT_DOWNLOAD, res.data.data)
    return res
  })
}

export const deleteContentDownload = (store, { id }) => {
  return axios.delete(`/api/admin/content-downloads/${id}`).then(res => {
    store.commit(mutations.DELETE_CONTENT_DOWNLOAD, { id })
    return res
  })
}

export const restoreContentDownload = (store, { id }) => {
  return axios.post(`/api/admin/content-downloads/${id}/restore`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_CONTENT_DOWNLOAD, { id })
    return res
  })
}
