import { filter, find } from 'lodash'

export const allRooms = state => state.items
export const roomsByProjectId = state => id => filter(state.items, room => room.project_id === id)
export const roomById = state => id => find(state.items, room => room.id === id)
export const roomsInboxCount = state => state.items.filter(room => room.filter === 'new').length
export const activeChatRoomId = state => state.activeChatRoomId
export const filteredRooms = state => {
  var filtered = {
    new: [],
    missed: [],
    declined: [],
    accepted: [],
    lost_and_found: []
  }
  state.items.forEach(
    (room) => filtered[room.filter].push(room.id)
  )
  return filtered
}
