import axios from "axios";

export const fetchDesignSystemInventory = () => {
  return axios.get("/api/admin/components-inventory").then(res => {
    return res.data
  })
};

export const fetchVueComponentProps = (store, { componentPath }) => {
  return axios.post('/api/admin/get-vue-component-props', { componentPath }).then(res => {
    return res.data.props
  })
}