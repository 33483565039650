import axios from 'axios'
import * as mutations from '../../mutation-types'
import { map, reduce, isEmpty, omit } from 'lodash'
import { batchProcessCompanies } from '../companies/actions'
import { batchProcessCategories } from '../categories/actions'
import { batchProcessFeatures } from '../features/actions'
import { batchProcessTestimonials } from '../testimonials/actions'
import { batchProcessTechAwards } from '../tech-awards/actions'
import { batchProcessQuestions } from '../questions/actions'
import { batchProcessScreenshots } from '../screenshots/actions'
import { batchProcessFeaturedBrands } from '../featured-brands/actions'
import { batchProcessReviews } from '../reviews/actions'
import { batchProcessPosts } from '../posts/actions'
import { batchProcessProductModules } from '../product-modules/actions'
import { batchProcessFeaturesGroup } from '../features-groups/actions'

export const batchProcessProducts = (store, products) => {
  let reduced = reduce(products, (sum, product) => {
    delete product.pivot
    product.combined_reviews_count = product.combined_reviews_count || 0
    if (product.company) sum.companies[product.company.id] = product.company
    delete product.company

    if (product.category) {
      sum.categories[product.category.id] = product.category
      product.parent_category_id = product.category.parent_id
    }
    delete product.category

    if (product.primary_product) batchProcessProducts(store, [product.primary_product])
    delete product.primary_product

    if (product.tech_awards) {
      product.techAwards = map(product.tech_awards, techAward => {
        sum.techAwards[techAward.id] = techAward
        return techAward.id
      })
    }
    if (product.questions) {
      product.questions = map(product.questions, question => {
        sum.questions[question.id] = question
        return question.id
      })
    }

    if (product.features) {
      product.features = map(product.features, feature => {
        sum.features[feature.id] = feature
        return feature.id
      })
    }

    if (product.feature_groups) {
      product.feature_groups = map(product.feature_groups, features_group => {
        sum.feature_groups[features_group.id] = features_group
        return features_group.id
      })
    }

    if (product.screenshots) {
      product.screenshots = map(product.screenshots, screenshot => {
        sum.screenshots[screenshot.id] = screenshot
        return screenshot.id
      })
    }

    if (product.reviews) {
      product.reviews = map(product.reviews, review => {
        sum.reviews[review.id] = review
        return review.id
      })
    }

    if (product.expert_reviews) {
      product.expert_reviews = map(product.expert_reviews, review => {
        sum.posts[review.id] = review
        return review.id
      })
    }

    if (product.product_modules) {
      product.product_modules = map(product.product_modules, productModule => {
        sum.product_modules[productModule.id] = productModule
        return productModule.id
      })
    }

    if (product.users_breakdown) {
      for (let group in product.users_breakdown) {
        for (let node in product.users_breakdown[group]) {
          product.users_breakdown[group][node] = +product.users_breakdown[group][node]
        }
      }
    }
    sum.products.push(product)
    return sum
  }, { reviews: {}, screenshots: {}, feature_groups: {}, questions: {}, posts: {}, techAwards: {}, companies: {}, categories: {}, features: {}, products: [], productModules: {} })

  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))
  if (!isEmpty(reduced.categories)) batchProcessCategories(store, Object.values(reduced.categories))
  if (!isEmpty(reduced.features)) batchProcessFeatures(store, Object.values(reduced.features))
  if (!isEmpty(reduced.testimonials)) batchProcessTestimonials(store, Object.values(reduced.testimonials))
  if (!isEmpty(reduced.techAwards)) batchProcessTechAwards(store, Object.values(reduced.techAwards))
  if (!isEmpty(reduced.questions)) batchProcessQuestions(store, Object.values(reduced.questions))
  if (!isEmpty(reduced.screenshots)) batchProcessScreenshots(store, Object.values(reduced.screenshots))
  if (!isEmpty(reduced.reviews)) batchProcessReviews(store, Object.values(reduced.reviews))
  if (!isEmpty(reduced.posts)) batchProcessPosts(store, Object.values(reduced.posts))
  if (!isEmpty(reduced.feature_groups)) batchProcessFeaturesGroup(store, Object.values(reduced.feature_groups))
  if (!isEmpty(reduced.productModules)) batchProcessProductModules(store, Object.values(reduced.productModules))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_PRODUCT, reduced.products)
}

export const fetchAdminProducts = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/products?${query}`).then(res => {
    batchProcessProducts(store, res.data.data)
    return res
  })
}
export const fetchProducts = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/products?${query}`).then(res => {
    batchProcessProducts(store, res.data.data)
    return res
  })
}

export const fetchProductById = (store, id) => {
  return axios.get(`/api/products/${id}`).then(res => {
    batchProcessProducts(store, [{...res.data}])
    return res
  })
}

export const setIsFetchingProductDetailsData = (store, isFetching) => {
  store.commit(mutations.SET_IS_FETCHING_PRODUCT_DETAILS_DATA, isFetching)
}

export const fetchProductDetailsById = (store, id) => {
  return axios.get(`/api/dashboard/product-details/${id}`).then(res => {
    let product = res.data.product
    product.feature_groups = res.data.feature_groups
    product.details = omit(res.data, ['product', 'category_leader', 'integrations_leader'])
    const nonNullProducts = [product, res.data.category_leader, res.data.integrations_leader].filter((element) => element !== null && element !== undefined )
    if (nonNullProducts.length > 0) {
      batchProcessProducts(store, nonNullProducts)
    }
    return res
  })
}

export const fetchProductsByCompanyId = (store, id) => {
  return axios.get(`/api/companies/${id}/products`).then(res => {
    batchProcessProducts(store, res.data)
    return res
  })
}

export const updateProductById = (store, { id, data }) => {
  return axios.put(`/api/products/${id}`, data).then(res => {
    batchProcessProducts(store, [{...res.data}])
    return res
  })
}

export const createProduct = (store, data) => {
  return axios.post(`/api/products`, data).then(res => {
    batchProcessProducts(store, [res.data])
    return res
  })
}

export const destroyProduct = (store, { id }) => {
  return axios.delete(`/api/products/${id}`).then(res => {
    store.commit(mutations.DELETE_REVIEWS_BY_PRODUCT_ID, id)
    store.commit(mutations.DELETE_PRODUCT, { id })
    return res
  })
}
