import axios from 'axios'
import { reduce, isEmpty } from 'lodash'
import { batchProcessUsers } from '../users/actions'
import { batchProcessCompanies } from '../companies/actions'
import * as mutations from '../../mutation-types'

export const batchProcessInvitations = (store, invitations) => {
  let reduced = reduce(invitations, (sum, invitation) => {
    if (invitation.user) sum.users[invitation.user.id] = invitation.user
    delete invitation.user

    if (invitation.company) sum.companies[invitation.company.id] = invitation.company
    delete invitation.company

    if (invitation.sender) sum.users[invitation.sender.id] = invitation.sender
    delete invitation.sender

    sum.invitations.push(invitation)
    return sum
  }, { companies: {}, users: {}, invitations: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_INVITATIONS, reduced.invitations)
}

export const fetchInvitationById = (store, id) => {
  return axios.get(`/api/invitations/${id}`).then(res => {
    batchProcessInvitations(store, [res.data])
    return res
  })
}
export const createInvitationByCompanyId = (store, { id, data }) => {
  return axios.post(`/api/companies/${id}/invitations`, data).then(res => {
    batchProcessInvitations(store, [res.data])
    return res
  })
}

export const createChatInvitationByCompanyId = (store, { id, data }) => {
  return axios.post(`/api/companies/${id}/chat-invitations`, data).then(res => {
    batchProcessInvitations(store, [res.data])
    return res
  })
}

export const createLeadInvitationByCompanyId = (store, { id, data }) => {
  return axios.post(`/api/companies/${id}/lead-invitations`, data).then(res => {
    batchProcessInvitations(store, [res.data])
    return res
  })
}

export const sendProjectInvitation = (store, { id, data }) => {
  return axios.post(`/api/projects/${id}/invitations`, data).then(res => {
    batchProcessInvitations(store, [res.data])
    return res
  })
}

export const sendInvitationReminderByInvitationId = (store, id) => {
  return axios.post(`/api/invitations/${id}/reminders`).then(res => {
    batchProcessInvitations(store, [res.data])
    return res
  })
}

export const cancelInvitationByInvitationId = (store, id) => {
  return axios.delete(`/api/invitations/${id}`).then(res => {
    store.commit(mutations.DELETE_INVITATION, { id })
    return res;
  })
}
