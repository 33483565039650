import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

let state = {
  items: [],
  activeChatRoomId: null

}

export default { state, actions, getters, mutations }