import axios from 'axios'
import { batchProcessUsers } from '../users/actions'
import { batchProcessProducts } from '../products/actions'
import { reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessIntegrationInvitations = (store, invitations) => {
  let reduced = reduce(invitations, (sum, invitation) => {
    if (invitation.user) sum.users[invitation.user.id] = invitation.user
    delete invitation.user

    if (invitation.sender) sum.users[invitation.sender.id] = invitation.sender
    delete invitation.sender

    if (invitation.source_product) sum.products[invitation.source_product.id] = invitation.source_product
    delete invitation.source_product

    if (invitation.target_product) sum.products[invitation.target_product.id] = invitation.target_product
    delete invitation.target_product

    sum.invitations.push(invitation)
    return sum
  }, { users: {}, products: {}, invitations: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_INTEGRATION_INVITATIONS, reduced.invitations)
}

export const fetchIntegrationInvitations = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/integration-invitations?${query}`).then(res => {
    batchProcessIntegrationInvitations(store, res.data.data)
    return res
  })
}

export const fetchIntegrationInvitationById = (store, id) => {
  return axios.get(`/api/integration-invitations/${id}`).then(res => {
    batchProcessIntegrationInvitations(store, [res.data])
    return res
  })
}

export const createIntegrationInvitation = (store, data) => {
  return axios.post(`/api/integration-invitations`, data).then(res => {
    batchProcessIntegrationInvitations(store, [res.data])
    return res
  })
}

export const destroyIntegrationInvitation = (store, { id }) => {
  return axios.delete(`/api/admin/integration-invitations/${id}`).then(res => {
    store.commit(mutations.DELETE_INTEGRATION_INVITATION, { id })
    return res
  })
}
