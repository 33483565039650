import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessOauthClients = (store, oauthClientData) => {
  let reduced = reduce(oauthClientData, (sum, client) => {
    sum.oauthClients.push(client)
    return sum
  }, { oauthClients: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_OAUTH_CLIENTS, reduced.oauthClients)
}

// ============================== ADMIN  ACTIONS========================================
export const destroyOauthClient = (store, { id }) => {
  return axios.delete(`/api/admin/passport/clients/${id}`).then(res => {

    store.commit(mutations.DELETE_OAUTH_CLIENT, { id })
    return res
  })
}

export const fetchOauthClients = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/passport/clients?${query}`).then(res => {
    batchProcessOauthClients(store, res.data.data)
    return res
  })
}

export const fetchOauthClientById = (store, id) => {
  return axios.get(`/api/admin/passport/clients/${id}`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_OAUTH_CLIENTS, [res.data])
    return res
  })
}

export const updateOauthClientById = (store, { id, data }) => {
  return axios.put(`/api/admin/passport/clients/${id}`, data).then(res => {
    batchProcessOauthClients(store, [res.data])
    return res
  })
}

export const createOauthClient = (store, data) => {
  return axios.post(`/api/admin/passport/clients`, data).then(res => {
    batchProcessOauthClients(store, [res.data])
    return res
  })
}
