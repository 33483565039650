import axios from 'axios'
import { reduce, isEmpty, map } from 'lodash'
import { batchProcessUsers } from '../users/actions'
import { batchProcessProducts } from '../products/actions'
import { batchProcessReviewResponses } from '../review-responses/actions'
import * as mutations from '../../mutation-types'

export const batchProcessReviews = (store, reviews) => {
  let reduced = reduce(reviews, (sum, review) => {
    delete review.pivot

    if (review.user) sum.users[review.user.id] = review.user
    delete review.user

    if (review.responses && review.responses.length > 0) {
      sum.reviewResponses[review.responses[0].id] = review.responses[0]
      sum.reviewResponses[review.responses[0].id].review_id = review.id
      delete review.responses
    }

    if (review.products) {
      review.products = map(review.products, product => {
        sum.products[product.id] = product
        return product.id
      })
    }

    sum.reviews.push(review)
    return sum
  }, { reviewResponses: {}, users: {}, products: {}, reviews: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))
  if (!isEmpty(reduced.reviewResponses)) batchProcessReviewResponses(store, Object.values(reduced.reviewResponses))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_REVIEWS, reduced.reviews)
}

export const destroyReview = (store, { id }) => {
  return axios.delete(`/api/reviews/${id}`).then(res => {
    store.commit(mutations.DELETE_REVIEW, { id })
    return res
  })
}

export const fetchReviews = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/reviews?${query}`).then(res => {
    batchProcessReviews(store, res.data.data)
    if (res.data.tabs_data) {
      store.commit(mutations.UPDATE_TABS_REVIEWS_INFO, res.data.tabs_data)
    }
    return res
  })
}

export const fetchReviewsByProductId = (store, id) => {
  return axios.get(`/api/products/${id}/reviews`).then(res => {
    batchProcessReviews(store, res.data)
    return res
  })
}

export const fetchReviewsByCompanyId = (store, id) => {
  return axios.get(`/api/companies/${id}/reviews`).then(res => {
    batchProcessReviews(store, res.data)
    return res
  })
}

export const fetchReviewById = (store, id) => {
  return axios.get(`/api/reviews/${id}`).then(res => {
    batchProcessReviews(store, [res.data])
    return res
  })
}

export const updateReviewById = (store, { id, data }) => {
  return axios.put(`/api/reviews/${id}`, data).then(res => {
    batchProcessReviews(store, [res.data])
    return res
  })
}

export const updateUnderInvestigationReviewById = (store, { id, under_fraud_investigation }) => {
  return axios.put(`/api/reviews/update-under-investigation/${id}`, { under_fraud_investigation }).then(res => {
    batchProcessReviews(store, [res.data])
    return res
  })
}

export const updateUserReviewById = (store, { id, data }) => {
  return axios.put(`/api/user-reviews/${id}`, data).then(res => {
    batchProcessReviews(store, [res.data])
    return res
  })
}

export const createReview = (store, data) => {
  return axios.post(`/api/reviews`, data).then(res => {
    batchProcessReviews(store, [res.data])
    return res
  })
}

export const fetchReviewByCompanyIdAndEmail = (store, data) => {
  return axios.get(`/api/companies/${data.companyId}/review?email=${data.email}`).then(res => {
    return res
  })
}

// review manager settings
export const getReviewsManagerSettings= () => {
  return axios.get(`/api/admin/reviews-manager-settings`).then(res => {
    return res.data
  })
}

export const saveReviewsManagerSettings = (store, data) => {
  return axios.post(`/api/admin/reviews-manager-settings`, data).then(res => {
    return res.data
  })
}
