import { reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'
import axios from 'axios'

export const batchProcessTestimonials = (store, testimonials) => {
  let reduced = reduce(testimonials, (sum, testimonial) => {
    if (testimonial.product) sum.products[testimonial.product.id] = testimonial.product
    delete testimonial.product

    sum.testimonials.push(testimonial)
    return sum
  }, { testimonials: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_TESTIMONIAL, reduced.testimonials)
}

export const updateTestimonialById = (store, { id, data }) => {
  return axios.put(`/api/testimonials/${id}`, data).then(res => {
    batchProcessTestimonials(store, [res.data])
    return res
  })
}

export const createProductTestimonial = (store, { productId, data }) => {
  return axios.post(`/api/products/${productId}/testimonials`, data).then(res => {
    batchProcessTestimonials(store, [res.data])
    return res
  })
}
export const createCompanyTestimonial = (store, { companyId, data }) => {
  return axios.post(`/api/company/${companyId}/testimonials`, data).then(res => {
    batchProcessTestimonials(store, [res.data])
    return res
  })
}
export const destroyTestimonial = (store, { id }) => {
  return axios.delete(`/api/testimonials/${id}`).then(res => {
    store.commit(mutations.DELETE_TESTIMONIAL, { id })
    return res
  })
}
