import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'
import {batchProcessCompanies} from "../companies/actions";

export const batchProcessFeaturedBrands = (store, featuredBrands) => {
  let reduced = reduce(featuredBrands, (sum, featuredBrand) => {
    sum.featuredBrands.push(featuredBrand)
    return sum
  }, { featuredBrands: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_FEATURED_BRANDS, reduced.featuredBrands)
}

export const createFeatureBrandByProductId = (store, { id, featuredBrand }) => {
  return axios.post(`/api/products/${id}/featured-brands`, { featured_brand: featuredBrand }).then(res => {
    batchProcessFeaturedBrands(store, res.data)
    return res
  })
}
export const createFeatureBrandByCompanyId = (store, { id, featuredBrand }) => {
  return axios.post(`/api/companies/${id}/featured-brands`, { featured_brand: featuredBrand }).then(res => {
    batchProcessFeaturedBrands(store, res.data)
    return res
  })
}

export const destroyFeaturedBrand = (store, featuredBrand) => {
  return axios.delete(`/api/companies/${featuredBrand.company_id}/featured-brands/${featuredBrand.id}`).then(() => {
    store.commit(mutations.DELETE_FEATURED_BRANDS, { id: featuredBrand.id })
  })
}
export const save3xFeatureBrand = (store, { id, data }) => {
  return axios.post(`/api/companies/${id}/3x-featured-brands`, data).then(res => {
    batchProcessCompanies(store, res)
    return res
  })
}
