import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessAuthGrants = (store, oauthAuthGrantData) => {
  let reduced = reduce(oauthAuthGrantData, (sum, client) => {
    sum.oauthAuthGrants.push(client)
    return sum
  }, { oauthAuthGrants: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_OAUTH_AUTH_GRANTS, reduced.oauthAuthGrants)
}

// ============================== ADMIN  ACTIONS========================================
export const destroyOauthAuthGrant = (store, { id }) => {
  return axios.delete(`/api/admin/passport/auth-codes/${id}`).then(res => {

    store.commit(mutations.DELETE_OAUTH_AUTH_GRANT, { id })
    return res
  })
}

export const fetchOauthAuthGrants = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/passport/auth-codes?${query}`).then(res => {
    batchProcessAuthGrants(store, res.data.data)
    return res
  })
}

export const fetchOauthAuthGrantById = (store, id) => {
  return axios.get(`/api/admin/passport/auth-codes/${id}`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_OAUTH_AUTH_GRANTS, [res.data])
    return res
  })
}
