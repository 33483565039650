import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPlanPrices = (store, prices) => {
  let reduced = reduce(prices, (result, price) => {
    price.benefit_ids = []
    price.benefits.forEach(benefit => {
      price.benefit_ids.push(benefit.id)
    })
    result.prices.push(price)
    return result
  }, { prices: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_PLAN_PRICES, reduced.prices)
}

export const fetchPlanPrices = async (store, condition = 'all') => {
  return axios.get(`/api/membership-plan-prices`).then(res => {
    batchProcessPlanPrices(store, res.data)
    return res
  })
}