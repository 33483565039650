import axios from 'axios'
import { uniq, map, reduce, isEmpty, each } from 'lodash'
import * as mutations from '../../mutation-types'
import { batchProcessProducts } from '../products/actions'
import { batchProcessUsers } from '../users/actions'
import { batchProcessPosts } from '../posts/actions'
import { batchProcessInvitations } from '../invitations/actions'
import { batchProcessCustomers } from '../customers/actions'
import { batchProcessHotels } from '../hotels/actions'
import { batchProcessTestimonials } from '../testimonials/actions'
import { batchProcessFeaturedBrands } from '../featured-brands/actions'
import {setManualCrmForwardingStatus} from "../leads/actions.js"
import {fetchCompanyAuthorizedAccountsDataById} from "../oauth-access-grants/actions.js"

export const batchProcessCompanies = (store, companies) => {
  let reduced = reduce(companies, (sum, company) => {
    if (company.products) sum.products = [...sum.products, ...company.products]
    delete company.products

    if (company.featured_posts) {
      company.featured_posts = map(company.featured_posts || [], post => {
        sum.posts[post.id] = post
        return post.id
      })
    }

    if (company.hotels) {
      company.hotels = uniq(map(company.hotels || [], hotel => {
        sum.hotels[hotel.id] = hotel
        return hotel.id
      }))
    }

    if (company.users) {
      company.users = uniq(map(company.users || [], user => {
        sum.users[user.id] = user
        return {
          user_id: user.id,
          is_admin: user.is_admin
        }
      }))
    }

    if (company.endorsers) {
      company.endorsers = map(company.endorsers || [], user => {
        sum.users[user.id] = user
        return user.id
      })
    }

    if (company.invitations) {
      company.invitations = map(company.invitations || [], invitation => {
        sum.invitations[invitation.id] = invitation
        return invitation.id
      })
    }

    if (company.customers) {
      each(company.customers, customer => {
        sum.customers[customer.id] = customer
      })

      delete company.customers
    }

    if (company.testimonials) {
      company.testimonials = map(company.testimonials, testimonial => {
        sum.testimonials[testimonial.id] = testimonial
        return testimonial.id
      })
    }

    if (company.all_featured_brands) {
      company.all_featured_brands = map(company.all_featured_brands, featuredBrand => {
        sum.featuredBrands[featuredBrand.id] = featuredBrand
        return featuredBrand.id
      })
    }

    sum.companies.push(company)
    return sum
  }, { customers: {}, invitations: {}, products: [], users: {}, hotels: {}, companies: [], posts: {}, testimonials: {}, featuredBrands: {} })

  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))
  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.posts)) batchProcessPosts(store, Object.values(reduced.posts))
  if (!isEmpty(reduced.invitations)) batchProcessInvitations(store, Object.values(reduced.invitations))
  if (!isEmpty(reduced.customers)) batchProcessCustomers(store, Object.values(reduced.customers))
  if (!isEmpty(reduced.hotels)) batchProcessHotels(store, Object.values(reduced.hotels))

  if (!isEmpty(reduced.testimonials)) batchProcessTestimonials(store, Object.values(reduced.testimonials))

  if (!isEmpty(reduced.featuredBrands)) batchProcessFeaturedBrands(store, Object.values(reduced.featuredBrands))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_COMPANIES, reduced.companies)
  return companies
}

export const fetchAllCompanies = store => {
  return fetchCompanies(store, { per_page: 9999 })
}

export const fetchCompanies = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/companies?${query}`).then(res => {
    batchProcessCompanies(store, res.data.data)
    return res
  })
}
export const fetchAllSimpleCompanies = (store, options) => {
  return fetchSimpleCompanies(store, { per_page: 9999 })
}
export const fetchSimpleCompanies = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/companies/simple?${query}`).then(res => {
    batchProcessCompanies(store, res.data.data)
    return res
  })
}
export const fetchSimpleCompaniesV2 = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => {
    if (typeof val !== 'object') {
      return `${key}=${val}`
    }
    if (Array.isArray(val)) {
      return val.map(item => `${key}[]=${item}`).join('&')
    }
  }).join('&')
  return axios.get(`/api/companies/simple?${query}`).then(res => {
    return res
  })
}
export const fetchAllCompaniesNames = store => {
  return fetchCompaniesNames(store, { per_page: 9999 })
}
export const fetchCompaniesNames = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/companies/company-list-names?${query}`).then(res => {
    batchProcessCompanies(store, res.data.data)
    return res
  })
}
export const fetchCompanyById = (store, id) => {
  return axios.get(`/api/companies/${id}`).then(res => {
    batchProcessCompanies(store, [res.data])
    setManualCrmForwardingStatus(store, res.data.is_enabled_auto_forward && res.data.crm_email_address !== null)
    // This will update the AutomaticCrmForwardingStatus
    fetchCompanyAuthorizedAccountsDataById(store, id)
    return res
  })
}

export const fetchCompanyByIdLight = (store, id) => {
  return axios.get(`/api/companies/${id}/safeData`).then(res => {
    batchProcessCompanies(store, [res.data])
    return res
  })
}

export const updateCompanyById = (store, { id, data }) => {
  return axios.put(`/api/companies/${id}`, data).then(res => {
    batchProcessCompanies(store, [res.data])
    setManualCrmForwardingStatus(store, res.data.is_enabled_auto_forward && res.data.crm_email_address !== null)
    // This will update the AutomaticCrmForwardingStatus
    fetchCompanyAuthorizedAccountsDataById(store, id)
    return res
  })
}

export const createCompany = (store, data) => {
  return axios.post(`/api/companies`, data).then(res => {
    batchProcessCompanies(store, [res.data])
    return res
  })
}

export const destroyCompany = (store, { id }) => {
  return axios.delete(`/api/companies/${id}`).then(res => {
    store.commit(mutations.DELETE_COMPANY, { id })
    return res
  })
}

export const endorseCompany = (store, id) => {
  return axios.post(`/api/user/endorsed-companies`, { id }).then(res => {
    batchProcessCompanies(store, [res.data])
    return res
  })
}

export const unendorseCompany = (store, id) => {
  return axios.delete(`/api/user/endorsed-companies/${id}`).then(res => {
    batchProcessCompanies(store, [res.data])
    return res
  })
}

export const addApikeyToCompany = (store, id) => {
  return axios.post(`/api/companies/apikey`, { id })
}
export const removeApikeyToCompany = (store, id) => {
  return axios.put(`/api/companies/remove-apikey`, { id })
}

export const fetchCards = (store, id) => {
  return axios.get(`/api/subscriptions/${id}`).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_CARDS, res.data)
    return res
  })
}

export const addPaymentMethod = (store, { id, payload }) => {
  return axios.post(`/api/subscriptions/payment-method/${id}`, payload).then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_CARDS, res.data)
    return res
  })
}

export const fetchCompanyProducts = (store, id) => {
  return axios.get(`/api/dashboard/get-products-by-company-id/${id}`).then(res => {
    if (res?.data?.products) {
      batchProcessProducts(store, res.data.products)
    }
  })
}

export const getPathBenefitsAndDiscounts = (store, id) => {
  return axios.get(`/api/companies/${id}/benefits-and-discounts`).then(res => {
    store.commit(mutations.SET_PATH_COMPANY, { companyId: id, path: res.data })
    return res
  })
}

export const getProductTilePpcCard = (store, { id, request }) => {
  return axios.post(`/api/product/${id}/product-tile-ppc-card`, request);
};

export const getProductTilePpcBottomBanner = (store, { id, request }) => {
  return axios.post(`/api/product/${id}/product-tile-ppc-bottom-banner`, request);
};

export const updateShowMetrics = (store, { id, data }) => {
  return axios.patch(`/api/companies/${id}/show-metrics`, data);
};
