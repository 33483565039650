import axios from 'axios'
import { map } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessNews = (event, news) => {
  event.commit(mutations.BATCH_ADD_OR_UPDATE_NEWS, news)
}

export const fetchCompanyNews = (store, { id, from = 0, size = 4 }) => {
  return axios.get(`/api/companies/${id}/news?from=${from}&size=${size}`).then(res => {
    let news = map(res.data.hits, event => {
      return {
        id: event._id,
        type: event._type,
        source: event._source,
        company_id: id
      }
    })
    batchProcessNews(store, news)
    return res
  })
}