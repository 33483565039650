import Axios from "axios"
import * as mutations from '../../mutation-types'

export const fetchLanguages = (store, options = {}) => {

  const query = new URLSearchParams(options).toString()

  return Axios.get(`/api/admin/languages?${query}`)
  .then(res => {
    store.commit(mutations.BATCH_ADD_OR_UPDATE_LANGUAGES, res.data)
    return Promise.resolve(res.data.data)
  })
  .catch(err => {
    return Promise.reject(err)
  })
}
