import Vue from 'vue'
import * as types from '../../mutation-types'
import filter from 'lodash/filter'
import { addOrUpdateItems, removeItem } from '../../helpers'

export default {
  [types.BATCH_ADD_OR_UPDATE_MIN_REVIEW_COUNT_BEFORE_PURCHASES]: (state, count) => { state.minReviewCountBeforePurchases = count },
  [types.BATCH_ADD_OR_UPDATE_REVIEWS]: (state, reviews) => addOrUpdateItems(state, reviews),
  [types.DELETE_REVIEW]: (state, review) => removeItem(state, review),
  [types.DELETE_REVIEWS_BY_PRODUCT_ID]: (state, id) => Vue.set(state, 'items', filter(state.items, review => review.product_id !== id)),
  [types.UPDATE_TABS_REVIEWS_INFO]: (state, tabs) => Vue.set(state, 'tabs', tabs)
}
