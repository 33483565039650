import { filter } from 'lodash'

export const allMessages = state => state.items
export const unreadMessages = state => state.items.filter(item => !item.read_at)

export const unreadVendorMessagesByRoomId = state => roomId => filter(
  state.items,
  message => message.chat_room_id === roomId && !message.read_at && (message.is_vendor || message.is_bot)
)
export const messageByRoomId = state => roomId => filter(state.items, message => message.chat_room_id === roomId)

export const unreadVendorMessagesCountByRoomId = (state, getters) => roomId => getters.unreadVendorMessagesByRoomId(roomId).length
export const unreadHoteilerMessagesCountByRoomId = (state, getters) => roomId => filter(
  state.items,
  message => message.chat_room_id === roomId && !message.read_at && !message.is_vendor
).length
