import { reduce, isEmpty } from 'lodash'
import { batchProcessProducts } from '../products/actions'
import * as mutations from '../../mutation-types'
import axios from 'axios'
import {batchProcessScreenshots} from "../screenshots/actions";

export const batchProcessproductBenefits = (store, productBenefits) => {
  let reduced = reduce(productBenefits, (sum, productBenefit) => {
    if (productBenefit.product) sum.products[productBenefit.product.id] = productBenefit.product
    delete productBenefit.product

    sum.productBenefits.push(productBenefit)
    return sum
  }, { products: {}, productBenefits: [] })

  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_PRODUCT_BENEFIT, reduced.productBenefits)
}

export const updateProductBenefitById = (store, { id, data }) => {
  return axios.put(`/api/dashboard/product-benefits/${id}`, data).then(res => {
    batchProcessproductBenefits(store, [res.data])
    return res
  })
}

export const createProductBenefit = (store, { productId, data }) => {
  return axios.post(`/api/dashboard/product-benefits`, data).then(res => {
    batchProcessproductBenefits(store, [res.data])
    return res
  })
}

export const fetchProductBenefits = (store, { productId }) => {
  return axios.get(`/api/dashboard/product-benefits?product_id=${productId}`).then(res => {
    return res.data
  })
}

export const updateProductBenefitsPriority = (store, { productId, ids }) => {
  return axios.put(`/api/products/${productId}/product-benefits/priority`, { ids: ids }).then(res => {
    batchProcessproductBenefits(store, res.data)
  })
}

export const destroyProductBenefit = (store, { id }) => {
  return axios.delete(`/api/dashboard/product-benefits/${id}`).then(res => {
    store.commit(mutations.DELETE_PRODUCT_BENEFIT, { id })
    return res
  })
}
