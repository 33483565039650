import axios from 'axios'
import { reduce, isEmpty, map } from 'lodash'
import { batchProcessUsers } from '../users/actions'
import { batchProcessProducts } from '../products/actions'
import { batchProcessCompanies } from '../companies/actions'
import * as mutations from '../../mutation-types'

export const batchProcessRecommendations = (store, recommendations) => {
  let reduced = reduce(recommendations, (sum, recommendation) => {
    delete recommendation.pivot

    if (recommendation.user) sum.users[recommendation.user.id] = recommendation.user
    delete recommendation.user

    if (recommendation.company) sum.companies[recommendation.company.id] = recommendation.company
    delete recommendation.company

    if (recommendation.products) {
      recommendation.products = map(recommendation.products, product => {
        sum.products[product.id] = product
        return product.id
      })
    }

    sum.recommendations.push(recommendation)
    return sum
  }, { users: {}, products: {}, companies: {}, recommendations: [] })

  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))
  if (!isEmpty(reduced.companies)) batchProcessCompanies(store, Object.values(reduced.companies))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_RECOMMENDATIONS, reduced.recommendations)
}

export const destroyRecommendation = (store, { id }) => {
  return axios.delete(`/api/recommendations/${id}`).then(res => {
    store.commit(mutations.DELETE_RECOMMENDATION, { id })
    return res
  })
}

export const fetchRecommendations = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/recommendations?${query}`).then(res => {
    batchProcessRecommendations(store, res.data.data)
    return res
  })
}

export const fetchRecommendationsByUserId = (store, id) => {
  return axios.get(`/api/users/${id}/recommendations`).then(res => {
    batchProcessRecommendations(store, res.data)
    return res
  })
}

export const fetchRecommendationsByProductId = (store, id) => {
  return axios.get(`/api/products/${id}/recommendations`).then(res => {
    batchProcessRecommendations(store, res.data)
    return res
  })
}

export const fetchRecommendationsByCompanyId = (store, id) => {
  return axios.get(`/api/companies/${id}/recommendations`).then(res => {
    batchProcessRecommendations(store, res.data)
    return res
  })
}

export const fetchRecommendationById = (store, id) => {
  return axios.get(`/api/recommendations/${id}`).then(res => {
    batchProcessRecommendations(store, [res.data])
    return res
  })
}

export const updateRecommendationById = (store, { id, data }) => {
  return axios.put(`/api/recommendations/${id}`, data).then(res => {
    batchProcessRecommendations(store, [res.data])
    return res
  })
}

export const createRecommendation = (store, data) => {
  return axios.post(`/api/recommendations`, data).then(res => {
    batchProcessRecommendations(store, [res.data])
    return res
  })
}

export const getAllRecommendationsDashboard = (store, {companyId, options}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/dashboard/partner-reccs/${companyId}?${query}`).then(res => {
    batchProcessRecommendations(store, res.data)
    return res
  });
}

export const requestUpdateRecommendationDashboard = (store, id) => {
  return axios.get(`/api/dashboard/partner-reccs/${id}/request-update`)
}
