import { reduce, isEmpty } from 'lodash'
import { batchProcessProducts } from '../products/actions'
import * as mutations from '../../mutation-types'
import axios from 'axios'

export const batchProcessStudies = (store, studies) => {
  let reduced = reduce(studies, (sum, study) => {
    sum.studies.push(study)
    return sum
  }, { studies: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_STUDY, reduced.studies)
}

export const updateStudyById = (store, { id, data }) => {
  return axios.put(`/api/studies/${id}`, data).then(res => {
    batchProcessStudies(store, [res.data])
    return res
  })
}

export const createProductStudy = (store, { productId, data }) => {
  return axios.post(`/api/products/${productId}/studies`, data).then(res => {
    batchProcessStudies(store, [res.data])
    return res
  })
}

export const destroyStudy = (store, { id }) => {
  return axios.delete(`/api/studies/${id}`).then(res => {
    store.commit(mutations.DELETE_STUDY, { id })
    return res
  })
}

/* NEW ACTIONS */

export const fetchCaseStudies = async (store, companyId) => {
  let res = await axios.get(`/api/company/case-studies/${companyId}`)
  store.commit(mutations.BATCH_ADD_OR_UPDATE_STUDY, res.data)
}

export const fetchCaseStudiesNoStore = (store, companyId) => {
  return axios.get(`/api/company/case-studies/${companyId}`)

}

export const fetchAllCaseStudies = async (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/company/case-studies?${query}`).then(res => {
    batchProcessStudies(store, res.data.data)
    return res
  })
}

export const destroyCaseStudy = (store, { id }) => {
  return axios.delete(`/api/admin/company/case-studies/${id}`).then(res => {
    store.commit(mutations.DELETE_STUDY, { id })
    return res
  })
}

export const createCaseStudy = (store, data) => {
  return axios.post(`/api/company/case-studies`, data)
}

export const fetchCaseStudyById = (store, id) => {
  return axios.get(`/api/company/case-studies/get-by-id/${id}`)
}

export const updateCaseStudy = (store, data) => {
  return axios.put(`/api/company/case-studies/${data.id}`, data)
}

export const updateCaseStudyAdmin = (store, data) => {
  return axios.put(`/api/admin/company/case-studies/${data.id}`, data)
}

export const updateCaseStudyCreatedAtAdmin = (store, {id, data}) => {
  return axios.patch(`/api/admin/company/case-studies/update-date/${id}`, data)
}

export const deleteCaseStudy = (store, data) => {
  store.commit(mutations.DELETE_STUDY, data)
  return axios.delete(`/api/company/case-studies/${data.id}`, data)
}

//validate case study on step three
export const validateCaseStudyStepThree = (store, data) => {
  return axios.post(`/case-studies/step-3`, data)
}

//Send verification link to customer
export const sendValidationRequestToCustomer = (store, { studyId, customerId }) => {
  return axios.get(`/api/company/case-studies/verify-customer/${studyId}?customer_id=${customerId}`)
}
