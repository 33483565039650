import axios from 'axios'

// No store
export const fetchTechStackAudits = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/tech-stack-audits?${query}`)
}

// No store
export const saveTechStackAudit = (store, request) => {
  return axios.post(`/api/admin/tech-stack-audits`, request)
}

// No store
export const updateTechStackAudit = (store, request) => {
  return axios.put(`/api/admin/tech-stack-audits/${request.id}`, request)
}

// show
export const fetchTechStackAuditsById = (store, {id}) => {
  return axios.get(`/api/admin/tech-stack-audits/${id}`)
}

//clone tech stack audit
export const cloneTechStackAudits = (store, {id}) => {
  return axios.post(`/api/admin/tech-stack-audits/${id}/clone`)
}

//delete
export const deleteTechStackAudit = (store, {id}) => {
  return axios.delete(`/api/admin/tech-stack-audits/${id}`)
}


// =============== GROUPS CRITERIA==================

// No store
export const fetchTechStackAuditCriteriaGroups = (store, teckStackAuditId) => {
  return axios.get(`/api/admin/tech-stack-audits/${teckStackAuditId}/criteria-group`)
}

export const saveTechStackAuditCriteriaGroup = (store, request) => {
  return axios.post(`/api/admin/tech-stack-audits/${request.tech_stack_audit_id}/criteria-group`, request)
}

// No store
export const updateTechStackAuditCriteriaGroup = (store, request) => {
  return axios.put(`/api/admin/tech-stack-audits/${request.tech_stack_audit_id}/criteria-group/${request.id}`, request)
}

// Delete
export const deleteTechStackAuditCriteriaGroup = (store, {id, tech_stack_audit_id}) => {
  return axios.delete(`/api/admin/tech-stack-audits/${tech_stack_audit_id}/criteria-group/${id}`)
}

export const bulkUpdateTechStackAuditCriteriaGroup = (store, data) => {
  return axios.post(`/api/admin/tech-stack-audits-criteria-group-bulk-update`, data)
}


// ===============  CRITERIA==================

// index
export const fetchTechStackAuditCriteria = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/tech-stack-audits-criteria?${query}`)
}
// store
export const saveTechStackAuditCriteria = (store, request) => {
  return axios.post(`/api/admin/tech-stack-audits-criteria`, request)
}

// update
export const updateTechStackAuditCriteria = (store, criteria) => {
  return axios.put(`/api/admin/tech-stack-audits-criteria/${criteria.id}`, criteria)
}
//show
export const fetchTechStackAuditCriteriaById = (store, {id}) => {
  return axios.get(`/api/admin/tech-stack-audits-criteria/${id}`)
}

//delete
export const deleteTechStackAuditCriteriaById = (store, {id}) => {
  return axios.delete(`/api/admin/tech-stack-audits-criteria/${id}`)
}

//exchange criteria
export const exchangeCriteria = (store, {id, data}) => {
  return axios.post(`/api/admin/tech-stack-audits-criteria-group/${id}/exchange-criteria`, data)
}

// all criteria
export const fetchAllTechStackAuditCriteria = (store, techStackAuditId) => {
  return axios.get(`/api/admin/tech-stack-audits-criteria/all/${techStackAuditId}`);
}


// =============== Settings ==================

// store settings
export const saveTechStackAuditSetting = (store, {id, data}) => {
  return axios.post(`/api/admin/tech-stack-audits/${id}/settings`, data)
}

export const getTechStackAuditDigitalTransformation = () => {
  return axios.get(`/api/admin/tech-stack-audits/settings-digital-transformation`).then(res => {
    return res.data
  })
}

export const saveTechStackAuditDigitalTransformation = (store, data) => {
  return axios.post(`/api/admin/tech-stack-audits/settings-digital-transformation`, data).then(res => {
    return res.data
  })
}

// global settings
export const getTechStackAuditGlobalSettings= () => {
  return axios.get(`/api/admin/tech-stack-audits/global-settings`).then(res => {
    return res.data
  })
}

export const saveTechStackAuditGlobalSettings = (store, data) => {
  return axios.post(`/api/admin/tech-stack-audits/global-settings`, data).then(res => {
    return res.data
  })
}

// =============== Partners==================

// index partners
export const fetchTechStackAuditPartners = (store, options = {}) => {
  const queryParams = new URLSearchParams(options).toString()

  return axios.get(`/api/admin/tech-stack-audits/${options.id}/partners?${queryParams}`)
}

// store partners
export const createTechStackAuditPartners = (store, {id, data}) => {
  return axios.post(`/api/admin/tech-stack-audits/${id}/partners`, data)
}

// update partners
export const updateTechStackAuditPartners = (store, {id, data}) => {
  return axios.put(`/api/admin/tech-stack-audits/${id}/partners/${data.id}`, data)
}

// delete partners
export const deleteTechStackAuditPartners = (store, {id, tech_stack_audit_id}) => {
  return axios.delete(`/api/admin/tech-stack-audits/${tech_stack_audit_id}/partners/${id}`)
}

// show partners
export const fetchTechStackAuditPartnerById = (store, {id, partnerId}) => {
  return axios.get(`/api/admin/tech-stack-audits/${id}/partners/${partnerId}`)
}
//exchange partners
export const exchangePartners = (store, {id, data}) => {
  return axios.post(`/api/admin/tech-stack-audits/${id}/exchange-positions`, data)
}

// store partners badge
export const createTechStackAuditPartnerBadge = (store, {id, data}) => {
  return axios.post(`/api/admin/tech-stack-audits/${id}/partners-badge`, data)
}

// update partners badge
export const updateTechStackAuditPartnerBadge = (store, {id, data}) => {
  return axios.put(`/api/admin/tech-stack-audits/${id}/partners-badge/${data.id}`, data)
}

// show partners badge
export const fetchTechStackAuditPartnerBadgeById = (store, {id}) => {
  return axios.get(`/api/admin/tech-stack-audits/${id}/partners-badge`)
}

export const saveTechStackAuditSettings = (store, options) => {
  return axios.post(`/api/admin/tech-stack-audits/settings`, options).then(res => {
    return res.data
  })
}

export const updateTechStackAuditSettings = (store, options) => {

  return axios.put(`/api/admin/tech-stack-audits/settings-fit-score-threshold`, options).then(res => {
    return res.data
  })
}

export const getSettingFitScoreThreshold = () => {
  return axios.get(`/api/admin/tech-stack-audits/settings-fit-score-threshold`).then(res => {
    return res.data
  })
}

export const getImpactMeterToCriteriaWeight = () => {
  return axios.get(`/api/admin/tech-stack-audits/settings-impact-meter-to-criteria-weight`).then(res => {
    return res.data
  })

}

export const updateImpactMeterToCriteriaWeight = (store, options) => {
  return axios.put(`/api/admin/tech-stack-audits/settings-impact-meter-to-criteria-weight`, options).then(res => {
    return res.data
  })
}

export const fetchTechStackAuditSettings = () => {
  return axios.get(`/api/admin/tech-stack-audits/settings`).then(res => {
    return res.data
  })
}

export const deleteTechStackAuditSettings = (store, id) => {
  return axios.delete(`/api/admin/tech-stack-audits/settings/${id}`).then(res => {
    return res.data
  })
}

// =============== Featured Recommendations ==================

export const fetchFeaturedRecommendations = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/tsa-featured-recomendations?${query}`)
}

export const saveFeaturedRecommendation = (store, options) => {
  return axios.post(`/api/admin/tsa-featured-recomendations`, options)
}

export const updateFeaturedRecommendation = (store, {id, data}) => {
  console.log('updateFeaturedRecommendation', id, data)
  return axios.put(`/api/admin/tsa-featured-recomendations/${id}`, data)
}

// =============== Users ==================


export const fetchTechStackAuditUsers = (store, options = {}) => {
  const queryParams = new URLSearchParams(options).toString()

  return axios.get(`/api/admin/tech-stack-audits/${options.id}/users-approved?${queryParams}`)
}

export const saveTechStackAuditUser = (store, request) => {
  return axios.post(`/api/admin/tech-stack-audits/${request.tech_stack_audit_id}/users-approved`, request).then(res => {
    return res.data
  })
}

export const deleteTechStackAuditUser = (store, {id, tech_stack_audit_id}) => {
  return axios.delete(`/api/admin/tech-stack-audits/${tech_stack_audit_id}/users-approved/${id}`).then(res => {
    return res.data
  })
}