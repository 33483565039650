import axios from 'axios'
import * as mutations from '../../mutation-types'
import { map, reduce, isEmpty, omit } from 'lodash'

export const batchProcessAccounts = (store, accounts) => {
  let reduced = reduce(accounts, (sum, account) => {
    sum.accounts.push(account)
    return sum
  }, { accounts: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_LEDGER_ACCOUNTS, reduced.accounts)
}

export const fetchLedgerAccounts = (store, options = {}) => {
  return axios.get('/api/admin/ledger/accounts').then((res) => {
    if (!options.hasOwnProperty('no_store') || !options.store ) {
      batchProcessAccounts(store, res.data)
    }
    return res.data
  })
}

export const fetchLedgerAccountsDashboard = (store, company_id) => {
  return axios.get(`/api/dashboard/ledger/accounts?company_id=${company_id}`).then((res) => {
    batchProcessAccounts(store, res.data)
    return res.data
  })
}

export const batchProcessProducts = (store, accounts) => {
  let reduced = reduce(accounts, (sum, account) => {
    sum.accounts.push(account)
    return sum
  }, { accounts: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_LEDGER_PRODUCTS, reduced.accounts)
}

export const fetchLedgerProducts = (store, options = {}) => {
  return axios.get('/api/admin/ledger/products').then((res) => {
    if (!options.hasOwnProperty('no_store') || !options.store ) {
      batchProcessProducts(store, res.data)
    }
    return res.data
  })
}

export const fetchLedgerProductsDashboard = (store) => {
  return axios.get('/api/dashboard/ledger/products').then((res) => {
    batchProcessProducts(store, res.data)
    return res.data
  })
}