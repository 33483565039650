import axios from 'axios'
import { each, reduce, isEmpty } from 'lodash'
import { batchProcessReviewRequests } from '../review-requests/actions'
import { batchProcessNpsRequests } from '../nps-requests/actions'
import { batchProcessReviews } from '../reviews/actions'
import { batchProcessInternalNotes } from '../internal-notes/actions'
import { batchProcessNpsResponses } from '../nps-responses/actions'
import { batchProcessUsers } from '../users/actions'
import { batchProcessHotels } from '../hotels/actions'
import * as mutations from '../../mutation-types'

export const batchProcessCustomers = (store, customers) => {
  let reduced = reduce(customers, (sum, customer) => {
    if (customer.review_requests) {
      customer.review_request_added = customer.review_requests
      each(customer.review_requests, reviewRequest => {
        if (reviewRequest.review) {
          sum.reviews[reviewRequest.review.id] = reviewRequest.review
          delete reviewRequest.review
        }
        sum.review_requests[reviewRequest.id] = reviewRequest
      })
      delete customer.review_requests
    }

    if (customer.nps_requests) {
      each(customer.nps_requests, npsRequest => (sum.nps_requests[npsRequest.id] = npsRequest))
      delete customer.nps_requests
    }

    if (customer.nps_responses) {
      each(customer.nps_responses, npsResponse => (sum.nps_responses[npsResponse.id] = npsResponse))
      delete customer.nps_responses
    }

    if (customer.internal_notes) {
      each(customer.internal_notes, note => (sum.internal_notes[note.id] = note))
      delete customer.internal_notes
    }

    if (customer.user) {
      sum.users[customer.user.id] = customer.user
      delete customer.user
    }

    if (customer.hotel) {
      sum.hotels[customer.hotel.id] = customer.hotel
      delete customer.hotel
    }
    customer.loadingEvents = customer.loadingEvents || false

    sum.customers.push(customer)
    return sum
  }, {
    hotels: {},
    users: {},
    nps_requests: {},
    review_requests: {},
    customers: [],
    reviews: [],
    internal_notes: [],
    nps_responses: {}
  })

  if (!isEmpty(reduced.review_requests)) batchProcessReviewRequests(store, Object.values(reduced.review_requests))
  if (!isEmpty(reduced.nps_requests)) batchProcessNpsRequests(store, Object.values(reduced.nps_requests))
  if (!isEmpty(reduced.nps_responses)) batchProcessNpsResponses(store, Object.values(reduced.nps_responses))
  if (!isEmpty(reduced.reviews)) batchProcessReviews(store, Object.values(reduced.reviews))
  if (!isEmpty(reduced.internal_notes)) batchProcessInternalNotes(store, Object.values(reduced.internal_notes))
  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.hotels)) batchProcessHotels(store, Object.values(reduced.hotels))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_CUSTOMERS, reduced.customers)
  store.commit(mutations.LOADING_CUSTOMERS, false)
}

export const batchTotalsTabs = (store, totalsTapsReviewManager) => {
  store.commit(mutations.ADD_TOTALS_CUSTOMERS, totalsTapsReviewManager)
}

export const batchAddCustomersByCompanyId = (store, { id, customers }) => {
  return axios.post(`/api/companies/${id}/customers`, { customers }).then(res => {
    batchProcessCustomers(store, res.data)
    return res
  })
}

export const fetchCustomerById = (store, id) => {
  return axios.get(`/api/customers/${id}`).then(res => {
    batchProcessCustomers(store, [res.data])
    return res
  })
}

export const fetchCustomers = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/customers?${query}`).then(res => {
    batchProcessCustomers(store, res.data.data)
    return res
  })
}

export const fetchCustomersByCompanyId = (store, { id, url, filters }) => {
  store.commit(mutations.LOADING_CUSTOMERS, true)
  let _filters = filters || {}
  return axios.get(`/api/companies/${id}/customers?${url}`, { params: _filters }).then(res => {
    batchProcessCustomers(store, res.data.data)
    return res
  })
}

export const fetchCustomersByCompanyIdSlow = (store, { id, url, filters }) => {
  let _filters = filters || {}
  return axios.get(`/api/admin/companies/${id}/customers`, { params: _filters }).then(res => {
    batchProcessCustomers(store, res.data)
    return res
  })
}

export const fetchTotalAmountTabs = (store, { id, data }) => {
  return axios.get(`/api/companies/${id}/customers/get-total-customers-tabs`, { params: data }).then(res => {
    batchTotalsTabs(store, res.data)
    return res
  })
}

export const fetchCustomersTagsByCompany = (store, id) => {
  return axios.get(`/api/companies/${id}/customer-tags`).then(res => {
    return res
  })
}

export const updateCustomersByIds = (store, { companyId, customers }) => {
  return axios.put(`/api/companies/${companyId}/customers/update`, { customers: customers }).then(res => {
    batchProcessCustomers(store, res.data)
    return res
  })
}

export const updateCustomerById = (store, { id, data }) => {
  return axios.put(`/api/customers/${id}`, data).then(res => {
    batchProcessCustomers(store, [res.data])
    return res
  })
}

export const queueCustomerById = (store, id) => {
  return axios.post(`/api/queued-customers`, {ids: [id]}).then(res => {
    batchProcessCustomers(store, res.data)
    return res
  })
}
export const unsentCustomersByIds = (store, data) => {
  return axios.put(`/api/move-to-campaign-setup/${data.company_id}`, { ids: data.ids }).then(res => {
    return res
  })
}

export const queueCustomersByIds = (store, ids) => {
  return axios.post(`/api/queued-customers`, {ids}).then(res => {
    batchProcessCustomers(store, res.data)
    return res
  })
}

export const unqueueCustomersByIds = (store, ids) => {
  return axios.post(`/api/unqueued-customers`, {ids}).then(res => {
    batchProcessCustomers(store, res.data)
    return res
  })
}

export const unQueueCustomerById = (store, id) => {
  return axios.delete(`/api/queued-customers/${id}`).then(res => {
    batchProcessCustomers(store, [res.data])
    return res
  })
}

export const restoreCustomerById = (store, id) => {
  return axios.delete(`/api/closed-customers/${id}`).then(res => {
    batchProcessCustomers(store, [res.data])
    return res
  })
}

export const updateCompanyCustomer = (store, customer) => {
  alert('updateCompanyCustomer used. FIX THIS!')
  return axios.put(`/api/companies/${customer.company_id}/customers/${customer.id}`, customer).then(res => {
    batchProcessCustomers(store, res.data)
    return res
  })
}

export const sendEmailToCustomerById = (store, { id, email }) => {
  return axios.post(`/api/customers/${id}/email`, email)
}

export const removeCustomersByIds = (store, data) => {
  return axios.post('/api/remove-customers', data).then(res => {
    store.commit(mutations.DELETE_CUSTOMERS, data.ids)
    return res
  })
}

export const freshStartCustomers = (store, company_id) => {
  return axios.post(`/api/fresh-start-customers/${company_id}`).then(res => {
    return res
  })
}

export const setIntervalTabsCustomers = (store, interval) => {
  store.commit(mutations.INTERVAL_TABS_CUSTOMERS, interval)
}

export const sendBulkUpdateRequestByCompanyId = (store, { companyId, filters }) => {
  let _filters = filters || {}
  return axios.get(`/api/companies/${companyId}/customers-bulk-update-request`, { params: _filters }).then(res => {
    return res
  })
}
