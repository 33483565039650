import axios from 'axios'
import * as mutations from '../../mutation-types'

export const fetchBookingHotels = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  store.state.hotels.loading = true

  return axios.get(`/api/booking/hotels-autocomplete?${query}`).then(res => {
    let hotels = []
    if (res.data) {
      hotels = res.data
    }
    store.commit(mutations.BOOKING_UPDATE_HOTELS_LIST, hotels)
  }).finally(() => {
    store.state.hotels.loading = false
  })
}

export const fetchBookingHotelInfo = (store, location_id) => {
  return axios.get(`/api/booking/get-hotel-information?location_id=${location_id}`)
}