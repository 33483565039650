import * as types from '../../mutation-types'
import { filter, find } from 'lodash'
import { addOrUpdateItems, addOrUpdateItem, removeItem } from '../../helpers'

export default {
  [types.BATCH_ADD_OR_UPDATE_COMPANIES]: (state, companies) => addOrUpdateItems(state, companies),
  [types.DELETE_USER_FROM_COMPANY]: (state, { companyId, userId }) => {
    let item = find(state.items, item => item.id === companyId)
    item.users = filter(item.users, pivot => pivot.userId !== userId)
    addOrUpdateItem(state, item)
  },
  [types.DELETE_COMPANY]: (state, company) => removeItem(state, company),
  [types.SET_PATH_COMPANY]: (state, {companyId, path}) => {
    let item = find(state.items, item => item.id === companyId)
    item.path = path
    addOrUpdateItem(state, item)
  },
  [types.BATCH_ADD_OR_UPDATE_CARDS]: (state, cards) =>  state.cards = cards,
}
