import { reduce, isEmpty } from 'lodash'
import { batchProcessProducts } from '../products/actions'
import * as mutations from '../../mutation-types'
import axios from 'axios'

export const batchProcessQuestions = (store, questions) => {
  let reduced = reduce(questions, (sum, question) => {
    if (question.product) sum.products[question.product.id] = question.product
    delete question.product

    sum.questions.push(question)
    return sum
  }, { products: {}, questions: [] })

  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_QUESTION, reduced.questions)
}

export const updateQuestionById = (store, { id, data }) => {
  return axios.put(`/api/questions/${id}`, data).then(res => {
    batchProcessQuestions(store, [res.data])
    return res
  })
}

export const createProductQuestion = (store, { productId, data }) => {
  return axios.post(`/api/products/${productId}/questions`, data).then(res => {
    batchProcessQuestions(store, [res.data])
    return res
  })
}

export const destroyQuestion = (store, { id }) => {
  return axios.delete(`/api/questions/${id}`).then(res => {
    store.commit(mutations.DELETE_QUESTION, { id })
    return res
  })
}
