import axios from 'axios'
import { reduce, isEmpty } from 'lodash'
import { batchProcessCustomers } from '../customers/actions'
import * as mutations from '../../mutation-types'

export const batchProcessNpsRequests = (store, npsRequests) => {
  let reduced = reduce(npsRequests, (sum, npsRequest) => {
    if (npsRequest.customer) {
      sum.customers[npsRequest.customer.id] = npsRequest.customer
    }
    delete npsRequest.customer

    sum.npsRequests.push(npsRequest)
    return sum
  }, { customers: {}, npsRequests: [] })

  if (!isEmpty(reduced.customers)) batchProcessCustomers(store, Object.values(reduced.customers))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_NPS_REQUESTS, reduced.npsRequests)
}


export const deleteNpsRequest = (store, request) => {
  return axios.delete(`/api/customers/${request.customer_id}/nps-requests/${request.id}`).then(res => {
    store.commit(mutations.DELETE_NPS_REQUEST, request)
    return res
  })
}
