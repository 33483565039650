import axios from 'axios'

// No store
export const fetchLeadsProductByCompany = (store, params) => {
  return axios.get(`/api/dashboard/lead-product/get-by-company?${params}`).then(res => {
    return res
  })
}

// No store
export const getLeadProduct = (store, id) => {
  return axios.get(`/api/dashboard/lead-product/get-lead-product?lead_product_id=${id}`).then(res => {
    return res
  })
}

// No store
export const getSavingByCompany = (store, id) => {
  return axios.get(`/api/dashboard/lead-product/get-saving-by-company?company_id=${id}`).then(res => {
    return res
  })
}

// No store
export const getMetricsByCompany = (store, id) => {
  return axios.get(`/api/dashboard/lead-product/get-metrics-by-company?company_id=${id}`).then(res => {
    return res
  })
}

// No store
export const getLeadSettingsByCompany = (store, id) => {
  return axios.get(`/api/dashboard/company/company-lead-settings?company_id=${id}`).then(res => {
    return res
  })
}

// No store
export const saveLeadSettings = (store, request) => {
  return axios.post(`/api/dashboard/company/company-lead-settings?company_id=${request.id}`, request.data).then(res => {
    return res
  })
}

// No store
export const updateLeadSettings = (store, request) => {
  return axios.put(`/api/dashboard/company/company-lead-settings?company_id=${request.id}`, request.data).then(res => {
    return res
  })
}

// No store
export const purchaseLeadProduct = (store, data) => {
  return axios.put('/api/dashboard/lead-product/purchase', data).then(res => {
    return res
  })
}

// No store
export const declineLeadProduct = (store, data) => {
  return axios.put('/api/dashboard/lead-product/decline', data).then(res => {
    return res
  })
}

// No store
export const purchasePms = (store, data) => {
  return axios.put('/api/dashboard/lead-product/purchase-pms', data).then(res => {
    return res
  })
}