import { find, filter } from 'lodash'

export const companyById = state => id => find(state.items, item => item.id === id)

export const companyBySlug = state => slug => find(state.items, item => item.slug === slug)

export const companiesByIds = state => ids => filter(state.items, item => ids.indexOf(item.id) > -1)

export const allCompanies = state => state.items

export const getUniqueCards = state => {
  let hashes = {}
      state.cards.forEach((card) => {
        const id = card.brand+card.exp_month+card.exp_year+card.last4
        if (!hashes.hasOwnProperty(id) || hashes[id].hasOwnProperty('default') && hashes[id].default !== true ) {
          hashes[id] = card
        }
      })
      return Object.values(hashes)
}

export const hasCreditCard = state => {
  return state.cards.length > 0
}

export const ppcCreditCard = state => {
  const currentCompany = state.items.length > 0 ? state.items[0] : null
  if (!currentCompany) {
    return null
  }
  return state.cards.find(card => card.id === currentCompany.ppc_card_id)
}

export const hasPpcCreditCard = state => {

  return !!ppcCreditCard(state)
}

export const allCards = state => state.cards
