import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessCountries = (store, countriesdata) => {
  let reduced = reduce(countriesdata, (sum, countries) => {
    sum.countries.push(countries)
    return sum
  }, { countries: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_COUNTRIES, reduced.countries)
}

export const fetchCountries = (store) => {
  axios.get(`/api/countries`).then(res => {
    batchProcessCountries(store, res.data)
    return res
  })
}