import { reduce, isEmpty } from 'lodash'
import { batchProcessProducts } from '../products/actions'
import * as mutations from '../../mutation-types'
import axios from 'axios'

export const batchProcessTechAwards = (store, studies) => {
  let reduced = reduce(studies, (sum, techAward) => {
    if (techAward.product) sum.products[techAward.product.id] = techAward.product
    delete techAward.product

    sum.techAwards.push(techAward)
    return sum
  }, { products: {}, techAwards: [] })

  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_TECH_AWARD, reduced.techAwards)
}

export const updateTechAwardById = (store, { id, productId, data }) => {
  return axios.put(`/api/products/${productId}/tech-awards/${id}`, data).then(res => {
    batchProcessTechAwards(store, [res.data])
    return res
  })
}

export const createProductTechAward = (store, { productId, data }) => {
  return axios.post(`/api/products/${productId}/tech-awards`, data).then(res => {
    batchProcessTechAwards(store, [res.data])
    return res
  })
}

export const destroyTechAward = (store, { id, productId }) => {
  return axios.delete(`/api/products/${productId}/tech-awards/${id}`).then(res => {
    store.commit(mutations.DELETE_TECH_AWARD, { id })
    return res
  })
}
