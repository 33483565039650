import axios from 'axios'
import { reduce, isEmpty, map } from 'lodash'
import { batchProcessCategories } from '../categories/actions'
import * as mutations from '../../mutation-types'

export const batchProcessFeaturesGroup = (store, featuresGroup) => {
  let reduced = reduce(featuresGroup, (sum, featuresGroup) => {
    if (featuresGroup.categories) {
      featuresGroup.categories = map(featuresGroup.categories, category => {
        sum.categories[category.id] = category
        return category.id
      })
    }

    if (featuresGroup.features) {
      featuresGroup.featuresCount = featuresGroup.features.length
    }

    sum.featuresGroup.push(featuresGroup)
    return sum
  }, { categories: {}, featuresGroup: [] })

  if (!isEmpty(reduced.categories)) batchProcessCategories(store, Object.values(reduced.categories))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_FEATURES_GROUP, reduced.featuresGroup)
}

export const batchProcessFeaturesGroupFeatures = (store, featuresGroupFeatures) => {
  store.commit(mutations.GET_FEATURE_GROUP_FEATURES, featuresGroupFeatures)
}

export const fetchFeaturesGroup = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/features-group?${query}`).then(res => {
    batchProcessFeaturesGroup(store, res.data.data)
    return res
  })
}

export const destroyFeatureGroup = (store, { id }) => {
  return axios.delete(`/api/features-group/${id}`).then(res => {
    store.commit(mutations.DELETE_POST, { id })
    return res
  })
}

export const massDestroyFeatureGroup = (store, ids) => {
  return axios.delete(`/api/features-group/massDestroy`, { data: { ids } }).then(res => {
    store.commit(mutations.DELETE_POST, ids)
    return res
  })
}

export const createFeatureGroup = (store, data) => {
  return axios.post(`/api/features-group`, data).then(res => {
    batchProcessFeaturesGroup(store, [res.data])
    return res
  })
}

export const updateFeatureGroupById = (store, { id, data }) => {
  return axios.put(`/api/features-group/${id}`, data).then(res => {
    batchProcessFeaturesGroup(store, [res.data])
    return res
  })
}

export const fetchFeatureGroupById = (store, id) => {
  return axios.get(`/api/features-group/${id}`).then(res => {
    batchProcessFeaturesGroup(store, [res.data])
    return res
  })
}

export const fetchFeatureGroupFeaturesById = (store, { id, options }) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/features-group/${id}/features?${query}`).then(res => {
    batchProcessFeaturesGroupFeatures(store, res.data)
    return res
  })
}

export const fetchFeaturesSearch = (store, { id, term }) => {
  let termEncoded = `search=${encodeURI(term)}`
  return axios.get(`/api/features-group/${id}/search?${termEncoded}`).then(res => {
    store.commit(mutations.GET_FEATURES_SEARCH, res)
    return res
  })
}

export const updateFeatureGroupFeaturesById = (store, { id, data }) => {
  return axios.put(`/api/features-group/${id}/syncFeatures?`, data).then(res => {
    store.commit(mutations.SYNC_FEATURES_GROUP_FEATURES)
    return res
  })
}

export const updatePositionFeatureGroupFeaturesById = (store, { id, data }) => {
  return axios.put(`/api/features-group/${id}/updatePositionFeatures?`, data).then(res => {
    return res
  })
}
