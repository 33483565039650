import axios from 'axios'
import { reduce, isEmpty } from 'lodash'
import { batchProcessPosts } from '../posts/actions'
import { batchProcessUsers } from '../users/actions'
import * as mutations from '../../mutation-types'

export const processPostClaim = (store, claim) => {
  store.commit(mutations.ADD_OR_UPDATE_POST_CLAIM, claim)
}

export const batchProcessPostClaims = (store, claims) => {
  let reduced = reduce(claims, (sum, claim) => {
    if (claim.post) sum.posts[claim.post.id] = claim.post
    delete claim.post

    if (claim.user) sum.users[claim.user.id] = claim.user
    delete claim.user

    sum.claims.push(claim)
    return sum
  }, { posts: {}, users: {}, claims: [] })

  if (!isEmpty(reduced.posts)) batchProcessPosts(store, Object.values(reduced.posts))
  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))

  store.commit(mutations.BATCH_ADD_OR_UPDATE_POST_CLAIMS, reduced.claims)
}

export const acceptPostClaim = (store, id) => {
  return axios.post(`/api/accepted-post-claims`, { id }).then(res => {
    processPostClaim(store, res.data)
    return res
  })
}

export const rejectPostClaim = (store, id) => {
  return axios.delete(`/api/pending-post-claims/${id}`).then(res => {
    processPostClaim(store, res.data)
    return res
  })
}

export const fetchPostClaims = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/post-claims?${query}`).then(res => {
    batchProcessPostClaims(store, res.data.data)
    return res
  })
}