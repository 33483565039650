import axios from 'axios'
import { reduce, isEmpty } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPostComponentCollections = (store, postComponentCollections) => {
  let reduced = reduce(postComponentCollections, (sum, postComponentCollection) => {

    sum.postComponentCollections.push(postComponentCollection)
    return sum
  }, { postComponentCollections: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_POST_COMPONENT_COLLECTIONS, reduced.postComponentCollections)
}

export const destroyPostComponentCollection = (store, { id }) => {
  return axios.delete(`/api/admin/posts/collections/${id}`).then(res => {
    store.commit(mutations.DELETE_POST_COMPONENT_COLLECTION, { id })
    return res
  })
}

export const createPostComponentCollection = (store, data) => {
  return axios.post(`/api/admin/posts/collections`, data).then(res => {
    batchProcessPostComponentCollections(store, [res.data])
    return res
  })
}

export const updatePostComponentCollectionById = (store, data) => {
  return axios.put(`/api/admin/posts/collections/${data.id}`, data).then(res => {
    batchProcessPostComponentCollections(store, [res.data])
    return res
  })
}

export const fetchPostComponentCollectionListByTitle = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/posts/collection-list-by-title?${query}`).then(res => {
    batchProcessPostComponentCollections(store, res.data.data)
    return res
  })
}

export const fetchPostComponentCollectionWithCollectiblesById = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/posts/collection-with-collectibles?${query}`).then(res => {
    return res
  })
}
