import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPostComponentAuthors = (store, postComponentAuthors) => {
  let reduced = reduce(postComponentAuthors, (sum, postComponentAuthor) => {
    sum.postComponentAuthors.push(postComponentAuthor)
    return sum
  }, { postComponentAuthors: [] })

  store.commit(mutations.BATCH_ADD_OR_UPDATE_POST_COMPONENT_AUTHORS, reduced.postComponentAuthors)
}

export const destroyPostComponentAuthor = (store, { id }) => {
  return axios.delete(`/api/admin/posts/authors/${id}`).then(res => {
    store.commit(mutations.DELETE_POST_COMPONENT_AUTHOR, { id })
    return res
  })
}

export const createPostComponentAuthor = (store, data) => {
  return axios.post(`/api/admin/posts/authors`, data).then(res => {
    batchProcessPostComponentAuthors(store, [res.data])
    return res
  })
}

export const updatePostComponentAuthorById = (store, data) => {
  return axios.put(`/api/admin/posts/authors/${data.id}`, data).then(res => {
    batchProcessPostComponentAuthors(store, [res.data])
    return res
  })
}
