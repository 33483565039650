import axios from 'axios'
import { reduce } from 'lodash'
import * as mutations from '../../mutation-types'

export const batchProcessPlanPaths = (store, paths) => {
  let reduced = reduce(paths, (result, path) => {
    path.plans_in_path = []
    path.plan_extensions.forEach((plan_extension) => {
      if (plan_extension.plan) {
        path.plans_in_path.push({
          order: plan_extension.order,
          plan_id: plan_extension.plan.id,
          badge: plan_extension.badge,
          upsell_text: plan_extension.upsell_text,
        })
      }
    })

    result.paths.push(path)
    return result
  }, { paths: [] })
  store.commit(mutations.BATCH_ADD_OR_UPDATE_PLAN_PATHS, reduced.paths)
}

export const fetchPlanPaths = async (store, condition = 'all') => {
  return axios.get(`/api/membership-plan-paths`).then(res => {
    batchProcessPlanPaths(store, res.data)
    return res
  })
}
