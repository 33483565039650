import axios from 'axios'
import * as mutations from '../../mutation-types'

export const batchProcessDepartments = (store, departments) => {
  store.commit(mutations.BATCH_ADD_OR_UPDATE_DEPARTMENTS, departments)
}

export const fetchDepartments = (store, options = {}) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/departments?${query}`).then(res => {
    batchProcessDepartments(store, res.data.data)
    return res
  })
}