import axios from 'axios'
import { reduce, isEmpty, map } from 'lodash'
import * as mutations from '../../mutation-types'
import { batchProcessUsers } from '../users/actions'
import { batchProcessLeads, fetchLeadByIdv2 } from '../leads/actions'
import { batchProcessProducts } from '../products/actions'

/* helper private functions -- should we move this? 
 * we are exporting this functions to test it, but no point
 * in using it outside from here?
 */
export const chatRoomFilter = function (room) {
  /* 
    THIS FILTERS ARE CREATED IN THE INDEX.JS STATE DEFINITION
    CHANGE THAT BEFORE ADDING - REMOVING - OR CHANGING TYPES HERE!
    i.e. new, accepted, declined, missed, lost_and_found
  */
  if ((room.status === 'pending' || 
       room.status === 'pending_pay') && 
       room.lead_status !== 'expired' &&
       room.lead_status !== 'missed') {
    return 'new'
  } else if (room.status === 'accepted' ||
             room.status === 'contested' ||
             room.status === 'closed_won' ||
             room.status === 'closed_lost') {
    return 'accepted'            
  } else if (room.status === 'declined') {
    return 'declined'    
  } else if (room.status === 'missed' || 
             room.lead_status === 'expired' || 
             room.lead_status === 'missed') {
    return 'missed'
  } else {
    return 'lost_and_found'
  }
}
export const addDays = function (date, days) {
  let result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}
// END HELPER FUNCTIONS 
export const batchProcessChatRooms = (store, chatRooms) => {
  let reduced = reduce(chatRooms, (sum, chatRoom) => {
    if (chatRoom.user_id) {
      sum.users[chatRoom.user_id] = {
        id: chatRoom.user_id,
        first_name: chatRoom.user_first_name,
        last_name: chatRoom.user_last_name,
        full_name: chatRoom.user_first_name + ' ' + chatRoom.user_last_name,
        email: chatRoom.user_email,
        company: chatRoom.user_company,
        title: chatRoom.user_title,
        avatar: chatRoom.user_avatar,
        hotel_id: chatRoom.user_hotel_id,
        linkedin_url: chatRoom.user_linkedin_url,
        hotel: {
          id: chatRoom.hotel_id,
          city: chatRoom.hotel_city,
          country: chatRoom.hotel_country,
          portfolio_size: chatRoom.hotel_portfolio_size,
          property_size: chatRoom.hotel_property_size,
          type: chatRoom.hotel_type,
          region: chatRoom.hotel_region,
          website: chatRoom.hotel_website 
        }
      }
      delete chatRoom.user
    } 

    if (chatRoom.product) {
      sum.products[chatRoom.product_id] = {
        id: chatRoom.product_id,
        closed_at: chatRoom.closed_at
      }
      delete chatRoom.product
    }

    if (chatRoom.users) {
      chatRoom.users = map(chatRoom.users, user => {
        sum.users[user.id] = user
        return user.id
      })
    }

    if (chatRoom.lead_id) {
      sum.leads[chatRoom.lead_id] = {
        id: chatRoom.lead_id,
        status: chatRoom.lead_status,
        notes: chatRoom.lead_notes,
        amount: chatRoom.lead_amount,
        user_id: chatRoom.lead_user_id,
        product_id: chatRoom.lead_product_id,
        category_id: chatRoom.lead_category_id,
        source: chatRoom.lead_source,
        contact_info: chatRoom.lead_contact_info,
        buyers_guide: chatRoom.lead_buyers_guide,
        review_question: chatRoom.lead_review_question,
        created_at: chatRoom.lead_created_at,
        updated_at: chatRoom.lead_updated_at,
        contested_message: chatRoom.lead_contested_message,
        updated_status_at: chatRoom.lead_updated_status_at,
        reject_reason: chatRoom.lead_reject_reason,
        status_contested: chatRoom.lead_status_contested,
        is_archive: chatRoom.lead_is_archive,
        quiz_id: chatRoom.lead_quiz_id,
        purchased_at: chatRoom.lead_purchased_at,
        is_notified: chatRoom.lead_is_notified,
        referrer: chatRoom.lead_referer,
        vendor_strike_one_sent: chatRoom.lead_vendor_strike_one_sent,
        category: {
          name: chatRoom.lead_category_name,
          slug: chatRoom.lead_category_slug,          
          parent: chatRoom.lead_category_parent_id
        }
      }

      if (chatRoom.lead_status !== 'unpurchased' || !chatRoom.lead_updated_status_at) {
        chatRoom.expiry_hours = undefined
      } else {
        let daysToLive 
        let updatedStatusAt = new Date(chatRoom.lead_updated_status_at)
        let statusDayOfWeek = updatedStatusAt.getDay()
        if (statusDayOfWeek === 0) {
          daysToLive = 4
        } else if (statusDayOfWeek === 1 || statusDayOfWeek ===2 ) {
          daysToLive = 3
        } else {
          daysToLive = 5
        }
        let dateExpiry = addDays(updatedStatusAt, daysToLive)
        let now = new Date()
        let diffhours = Math.round((dateExpiry - now) / 36e5)
        chatRoom.expiry_hours = diffhours > 0 ? diffhours : 0
      }
      // delete chatRoom.lead
    }
    let filter = chatRoomFilter(chatRoom)
    chatRoom.filter = filter
    sum.chatRooms.push(chatRoom)
    return sum
  }, { chatRooms: [], users: {}, leads: {}, products: {} })
  if (!isEmpty(reduced.products)) batchProcessProducts(store, Object.values(reduced.products))
  if (!isEmpty(reduced.users)) batchProcessUsers(store, Object.values(reduced.users))
  if (!isEmpty(reduced.leads)) batchProcessLeads(store, Object.values(reduced.leads))
  store.commit(mutations.BATCH_ADD_OR_UPDATE_CHAT_ROOMS, reduced.chatRooms)
}

export const batchProcessChatRoom = (store, chatRoom) => {
  if (!chatRoom.user) {
    fetchRoomsByCompanyId(store, chatRoom.product.company_id)
    // We shouldn't be loading all the chatRooms but is a quick and dirty solution for now.
    // fetchProjectRoomByRoomId(store, {
    //   projectId: chatRoom.project_id,
    //   roomId: chatRoom.id
    // })
  } else {
    batchProcessChatRooms(store, [chatRoom])
  }
  fetchLeadByIdv2(store, chatRoom.lead_id)
}
/* THIS FUNCTION SHOULDN'T BE CALLED ANYWHERE ELSE -- BROKEN FUNCTION */
export const fetchProjectRoomByRoomId = (store, { projectId, roomId }) => {
  return axios.get(`/api/projects/${projectId}/rooms/${roomId}`).then(res => {
    try {
      let chatRoom = translateChatRoom(res.data)
      batchProcessChatRooms(store, [chatRoom])
    } catch (e) {
      fetchRoomsByCompanyId(store, res.data.company_id)
    }
    return res
  })
}

export const fetchRoomsByCompanyId = (store, companyId) => {
  return axios.get(`/api/companies/${companyId}/roomsSQL`).then(res => {
    batchProcessChatRooms(store, res.data)
    return res
  })
}

export const updateRoomStatusById = (store, { companyId, roomId, data }) => {
  return axios.post(`/api/companies/${companyId}/rooms/${roomId}/status`, data).then(res => {
    let tempRoom = res.data
    tempRoom.lead_status = tempRoom.lead.status
    tempRoom.filter = chatRoomFilter(tempRoom)
    store.commit(mutations.UPDATE_CHAT_ROOM_ATTRIBUTES, { id: res.data.id, filter: tempRoom.filter, status: res.data.status, lead_status: res.data.lead.status })
    store.commit(mutations.UPDATE_LEAD_ATTRIBUTES, { id: res.data.lead.id, status: res.data.lead.status })
    return res
  })
}

export const updateRoomById = (store, { companyId, roomId, data }) => {
  return axios.put(`/api/companies/${companyId}/rooms/${roomId}`, data).then(res => {
    batchProcessChatRoom(store, res.data)
    return res
  })
}

export const setActiveChatRoom = (store, roomId) => {
  store.commit(mutations.BATCH_SET_ACTIVE_CHAT_ROOM, roomId)
}

export const setPendingStatus = (store, chatRoomId) => {
  store.commit(mutations.BATCH_ADD_OR_UPDATE_CHAT_ROOMS, [{
    id: chatRoomId,
    status: 'pending_pay',
    lead_status: null
  }])
}
export const translateChatRoom = (chatRoom) => {
  let lastname 
  try {
    lastname = chatRoom.lead.user.full_name.replace(chatRoom.lead.user.first_name + ' ')
  } catch (e) {
    lastname = ''
  }
  return Object.assign({
    user_id: chatRoom.lead.user.id,
    user_first_name: chatRoom.lead.user.first_name,
    user_last_name: lastname,
    full_name: chatRoom.lead.user.full_name,
    user_email: chatRoom.lead.user.email,
    user_company: chatRoom.lead.user.company,
    user_title: chatRoom.lead.user.title,
    user_avatar: chatRoom.lead.user.avatar,
    user_hotel_id: chatRoom.lead.user.hotel_id,
    user_linkedin_url: chatRoom.lead.user.linkedin_url,
    hotel_id: chatRoom.lead.user.hotel_id,
    hotel_city: chatRoom.lead.user.hotel_city,
    hotel_country: chatRoom.lead.user.hotel_country,
    hotel_portfolio_size: chatRoom.lead.user.portfolio_size,
    hotel_property_size: chatRoom.lead.user.property_size,
    hotel_type: chatRoom.lead.user.property_type,
    hotel_website: chatRoom.lead.user.company_website,
    product_id: chatRoom.product.id,
    closed_at: chatRoom.product.closed_at,
    lead_id: chatRoom.lead.id,
    lead_status: chatRoom.lead.status,
    lead_notes: chatRoom.lead.notes,
    lead_amount: chatRoom.lead.amount, 
    lead_user_id: chatRoom.lead.user_id, 
    lead_product_id: chatRoom.lead.product_id, 
    lead_category_id: chatRoom.lead.category_id,
    lead_source: chatRoom.lead.source,
    lead_contact_info: chatRoom.lead.contact_info,
    lead_buyers_guide: chatRoom.lead.buyers_guide, 
    lead_review_question: chatRoom.lead.review_question,
    lead_created_at: chatRoom.lead.created_at,
    lead_updated_at: chatRoom.lead.updated_at, 
    lead_contested_message: chatRoom.lead.contested_message,
    lead_updated_status_at: chatRoom.lead.updated_status_at, 
    lead_reject_reason: chatRoom.lead.reject_reason,
    lead_status_contested: chatRoom.lead.status_contested,
    lead_is_archive: chatRoom.lead.is_archive,
    lead_quiz_id: chatRoom.lead.quiz_id,
    lead_purchased_at: chatRoom.lead.purchased_at,
    lead_is_notified: chatRoom.lead.is_notified,
    lead_referer: chatRoom.lead.referrer,
    lead_vendor_strike_one_sent: chatRoom.lead.vendor_strike_one_sent,
    lead_category_name: chatRoom.lead.category.name,
    lead_category_slug: chatRoom.lead.category.slug,          
    lead_category_parent_id: chatRoom.lead.category.parent_id
  }, chatRoom)
}
