import axios from 'axios'
// No store
export const fetchLeadSegments = (store, options) => {
  let query = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&')
  return axios.get(`/api/admin/lead-segment-prices?${query}`).then(res => {
    return res
  })
}

export const updateSegmentBasePrice = (store, { segment_id, base_price }) => {
  return axios.put(`/api/admin/lead-segment-prices/${segment_id}`, { base_price }).then(res => {
    return res
  })
}

export const updateMultipleLeadPrice = (store, options) => {
  return axios.post(`/api/admin/lead-segments-update`, options).then(res => {
    return res
  })
}

export const fetchSettingsDefaultSegmentPrices = (store, options) => {
  return axios.get(`/api/settings-default-lead-prices`).then(res => {
    return res
  })
}

export const updateSettingsDefaultSegmentPrices = (store, options) => {
  return axios.post(`/api/settings-default-lead-prices`, options).then(res => {
    return res
  })
}

export const fetchAllBuyersCountSegments = (store, options) => {
  return axios.get(`/api/admin/all-buyers-count`).then(res => {
    return res
  })
}